import React, { useMemo, useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { callFetch } from "helpers/callFetch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { GoogleMap, useJsApiLoader, DirectionsRenderer, useLoadScript, Marker, InfoWindow, LoadScript, DirectionsService } from '@react-google-maps/api';


import ACImg from "./icon/AC.png"
import AC_2Img from "./icon/AC_2.png"
import DCImg from "./icon/DC.png"
import DC_2Img from "./icon/DC_2.png"

function UsersByProject(props) {
    const { t } = useTranslation();

    const [project, setProject] = useState(null);
    //const [markers, setActiveMarkers] = useState(null);
    const [center, setCenter] = useState('');
    const [nearestProjects, setNearestProjects] = useState([]);
    const [miltarbeiter, setMiltarbeiter] = useState([]);
    const [dcTechnicians, setDcTechnicians] = useState([]);
    const [acTechnicians, setAcTechnicians] = useState([]);
    const [nearestUsersProjectRespnse, setNearestUsersProjectRespnse] = useState([]);
    //Direction
    const [response, setResponse] = useState(null);
    const [zoom, setZoom] = useState(12);
    const [directionsCallbackCount, setDirectionsCallbackCount] = useState(0);
    const [employeeLocationRefresh, setEmployeeLocationRefresh] = useState(0);
    const [refreshNearestMitarbeiter, setRefreshNearestMitarbeiter] = useState(0);

    const libraries = ['geometry', 'drawing']
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries
    })

    const options = {
        disableDefaultUI: true,
        zoomControl: true,
        gestureHandling: 'cooperative',
    }

    const setNearestMiltarbeiter = (res) => {
        if (props?.setNearestMiltarbeiter) {
            if (props?.dachmontag_elektromontage == 'Dachmontage') {
                if (res.data.nearestproject?.dachmontag?.employee?.id) {
                    props?.setNearestMiltarbeiter(res.data.nearestproject?.dachmontag?.employee?.id);
                }
            } else if (props?.dachmontag_elektromontage == 'Elektromontage') {
                if (res.data.nearestproject?.elektromontage?.employee?.id) {
                    props?.setNearestMiltarbeiter(res.data.nearestproject?.elektromontage?.employee?.id);
                }
            }
        }
    }

    useEffect(() => {
        if (props?.projectId) {
            if (props?.date && props?.time) {
                var fromData = {
                    date: props?.date,
                    time: props?.time,
                    plan_form: (props?.planForm ? props?.planForm : 'Project'),
                }
            } else {
                var fromData = {};
            }

            console.log(props);

            callFetch("nearest-users/project/" + props.projectId + '/' + props?.dachmontag_elektromontage, "POST", fromData).then((res) => {
                setNearestUsersProjectRespnse(res);
                setDcTechnicians([]);
                setAcTechnicians([]);
                setProject(res.data.project);
                setNearestProjects(res.data.nearestProjects);

                if (props?.miltarbeiter) {
                    setEmployeeLocationRefresh(employeeLocationRefresh + 1);
                } else {
                    setNearestMiltarbeiter(res);
                }

                setCenter({
                    lat: Number(res.data.project.lat),
                    lng: Number(res.data.project.lng),
                })

                setRefreshNearestMitarbeiter(props?.refreshNearestMitarbeiter);
            });
        } else {
            //console.log('get all technitian location')
            setZoom(6);
            setCenter({
                lat: 51.1657,
                lng: 10.4515,
            })

            callFetch("users-last-location", "GET", []).then((res) => {
                setProject(null);
                setNearestProjects([]);
                setDcTechnicians(res?.data?.DcusersLastLocation);
                setAcTechnicians(res?.data?.AcusersLastLocation);

            });
        }
    }, [props?.projectId, props?.dachmontag_elektromontage, props?.date, props?.time]);

    useEffect(() => {
        setMiltarbeiter(props?.miltarbeiter);
        nearestProjects.map((nearestProject, key) => {
            if (props?.dachmontag_elektromontage == 'Dachmontage') {
                if (props?.miltarbeiter?.id == nearestProject.dachmontag.employee.id || props?.miltarbeiter == nearestProject.dachmontag.employee.id) {
                    setMiltarbeiter(nearestProject);
                }
            } else if (props?.dachmontag_elektromontage == 'Elektromontage') {
                if (props?.miltarbeiter?.id == nearestProject.elektromontage.employee.id || props?.miltarbeiter == nearestProject.elektromontage.employee.id) {
                    setMiltarbeiter(nearestProject);
                }
            }
        })
        setDirectionsCallbackCount(0);
    }, [props?.miltarbeiter, employeeLocationRefresh]);

    useEffect(() => {
        if (refreshNearestMitarbeiter <= 0) return;
        if (!props?.date) return;
        if (!props?.time) return;
        if (!props?.projectId) return;

        props?.setNearestMiltarbeiter('');
        setNearestMiltarbeiter(nearestUsersProjectRespnse, true);

    }, [refreshNearestMitarbeiter]);


    const directionsCallback = (response) => {
        console.log('directionsCallback');
        if (response !== null && directionsCallbackCount < 1) {
            if (response.status === 'OK') {
                setDirectionsCallbackCount(directionsCallbackCount + 1);
                setResponse(response);
                
                
                if(!props?.projectId) return;
                var directionDistance = {};
                directionDistance.distance = response?.routes[0]?.legs[0]?.distance;
                directionDistance.duration = response?.routes[0]?.legs[0]?.duration;

                var hours_minute = (response?.routes[0]?.legs[0]?.duration?.value/3600);
                var hours = Math.floor(hours_minute);
                var minutes = Math.floor(((hours_minute-hours)*60));
                
                directionDistance.duration.hours_minute = hours+':'+minutes;

                directionDistance.project_id = props?.projectId;
                props?.setDirectionDistance(directionDistance);

            } else {
                setDirectionsCallbackCount(0);
                console.log('response: ', response)
            }
        }
    }

    if (loadError) return "Error loading maps"
    if (!isLoaded) return "Loading Maps"

    return (
        <>


            <div className="row">
                {props?.detalsDisplay != false ? (
                    <>
                        <div className="col-lg-4">
                            <div className="nearestUsersCard">
                                <SoftBox p={1}>
                                    <img src="https://placehold.co/150x150" className="rounded-circle mb-2" style={{ width: '45px', border: '1px solid #0048B1', padding: '3px' }} />

                                    <SoftTypography className="mb-1" variant="h6">{t('Project Nr')}: {project?.id}</SoftTypography>
                                    <SoftTypography variant="caption"><strong>{t('Customer')}</strong>:</SoftTypography>
                                    <table>
                                        <tr>
                                            <td width="70"><SoftTypography variant="caption">{t('Name')}:</SoftTypography></td>
                                            <td><SoftTypography variant="caption">{project?.vorname} {project?.name}</SoftTypography></td>
                                        </tr>
                                        <tr>
                                            <td width="70"><SoftTypography variant="caption">{t('Adresse')}:</SoftTypography></td>
                                            <td><SoftTypography variant="caption"><a style={{ textDecoration: 'underline', color: '#005498', cursor: "pointer" }} href="#">{project?.street}, {project?.nr}</a></SoftTypography></td>
                                        </tr>
                                        <tr>
                                            <td width="70"><SoftTypography variant="caption">{t('PLZ, Ort')}:</SoftTypography></td>
                                            <td><SoftTypography variant="caption">{project?.plz}, {project?.ort}</SoftTypography></td>
                                        </tr>
                                        <tr>
                                            <td width="70"><SoftTypography variant="caption">{t('Telefon')}:</SoftTypography></td>
                                            <td><SoftTypography variant="caption"><a style={{ textDecoration: 'underline', color: '#005498', cursor: "pointer" }} href="#">{project?.telefonnummer} </a></SoftTypography></td>
                                        </tr>
                                    </table>
                                </SoftBox>

                                <hr />

                                <SoftBox p={1}>
                                    <img src="https://placehold.co/150x150" className="rounded-circle mb-2" style={{ width: '45px', border: '1px solid #0048B1', padding: '3px' }} />

                                    <SoftTypography variant="caption" className="d-block"><strong>{t('Eingeplanter Techniker')}</strong>:</SoftTypography>
                                    <table>
                                        <tr>
                                            <td width="70"><SoftTypography variant="caption">{t('Name')}:</SoftTypography></td>
                                            <td>
                                                <SoftTypography variant="caption">
                                                    {miltarbeiter?.name ?
                                                        miltarbeiter?.name
                                                        : props?.dachmontag_elektromontage == 'Dachmontage' ?
                                                            miltarbeiter?.dachmontag?.employee?.name
                                                            : props?.dachmontag_elektromontage == 'Dachmontage' ?
                                                                miltarbeiter?.elektromontage?.employee?.name
                                                                : '--'}
                                                </SoftTypography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="70"><SoftTypography variant="caption">{t('Adresse')}:</SoftTypography></td>
                                            <td>
                                                <SoftTypography variant="caption">
                                                    <a style={{ textDecoration: 'underline', color: '#005498', cursor: "pointer" }} href="#">
                                                        {miltarbeiter?.street ? miltarbeiter?.street : '--'}, {miltarbeiter?.nr ? miltarbeiter?.nr : '--'}
                                                    </a>
                                                </SoftTypography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="70"><SoftTypography variant="caption">{t('PLZ, Ort')}:</SoftTypography></td>
                                            <td>
                                                <SoftTypography variant="caption">
                                                    {miltarbeiter?.plz ? miltarbeiter?.plz : '--'}, {miltarbeiter?.ort ? miltarbeiter?.ort : '--'}
                                                </SoftTypography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="70"><SoftTypography variant="caption">{t('Telefon')}:</SoftTypography></td>
                                            <td>
                                                <SoftTypography variant="caption">
                                                    <a style={{ textDecoration: 'underline', color: '#005498', cursor: "pointer" }} href="#">
                                                        {miltarbeiter?.telefonnummer ? miltarbeiter.telefonnummer : '--'}
                                                    </a>
                                                </SoftTypography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="70"><SoftTypography variant="caption">{t('Project Nr')}:</SoftTypography></td>
                                            <td>
                                                <SoftTypography variant="caption">
                                                    <a style={{ textDecoration: 'underline', color: '#005498', cursor: "pointer" }} href="#">
                                                        {miltarbeiter?.project_id ? miltarbeiter.project_id : '--'}
                                                    </a>
                                                </SoftTypography>
                                            </td>
                                        </tr>
                                    </table>
                                </SoftBox>
                            </div>
                        </div>
                    </>
                ) : <></>}


                <div className={props?.detalsDisplay != false ? "col-lg-8" : "col-lg-12"}>
                    {center ? (
                        <>
                            <GoogleMap
                                id='direction-nearestUser'
                                mapContainerStyle={{ height: '470px', width: '100%', borderRadius: '7px' }}
                                mapContainerClassName={'googleMapContainer'}
                                zoom={zoom}
                                center={center}
                                options={options}
                                onClick={(e) => {
                                    //onMapClick(e);
                                    //setActiveMarkers(null);
                                }}
                            //onMouseOver={() => setActiveMarkers(null)}
                            >
                                {project?.id ? (
                                    <Marker
                                        position={center}
                                        //label={'<span style="background: red;">'+'Project: '+project?.id+'</span>'}
                                        label={'Project: ' + project?.id}
                                    >
                                    </Marker>
                                ) : ''}

                                {nearestProjects ? nearestProjects.map((nearestProject, key) => (
                                    <>
                                        <Marker
                                            position={{
                                                lat: Number(nearestProject.lat),
                                                lng: Number(nearestProject.lng),
                                            }}
                                            icon={
                                                props?.dachmontag_elektromontage == 'Dachmontage' ?
                                                    (nearestProject?.dachmontag?.employee?.id == miltarbeiter?.dachmontag?.employee?.id ?
                                                        DCImg : DC_2Img)
                                                    : props?.dachmontag_elektromontage == 'Elektromontage' ?
                                                        (nearestProject?.elektromontage?.employee?.id == miltarbeiter?.elektromontage?.employee?.id ?
                                                            ACImg : AC_2Img)
                                                        : ''
                                            }
                                            /*
                                            label={
                                                props?.dachmontag_elektromontage == 'Dachmontage' ? 'Mitebeter'+nearestProject?.dachmontag?.employee?.id
                                                : props?.dachmontag_elektromontage == 'Elektromontage' ? 'Mitebeter'+nearestProject?.elektromontage?.employee?.id
                                                : ''
                                            }
                                            */
                                            onClick={(e) => {
                                                if (props?.dachmontag_elektromontage == 'Dachmontage') {
                                                    if (nearestProject.dachmontag?.employee?.id) {
                                                        props?.setNearestMiltarbeiter(nearestProject?.dachmontag?.employee?.id);
                                                    }
                                                } else if (props?.dachmontag_elektromontage == 'Elektromontage') {
                                                    if (nearestProject?.elektromontage?.employee?.id) {
                                                        props?.setNearestMiltarbeiter(nearestProject?.elektromontage?.employee?.id);
                                                    }
                                                }
                                            }}
                                        >
                                        </Marker>
                                    </>
                                )) : ''}

                                {dcTechnicians ? dcTechnicians.map((technician, key) => (
                                    <>
                                        {technician?.dachmontag ? (
                                            <Marker
                                                position={{
                                                    lat: Number(technician.lat),
                                                    lng: Number(technician.lng),
                                                }}
                                                icon={DC_2Img}
                                            >
                                            </Marker>
                                        ) : ''}
                                    </>
                                )) : ''}

                                {acTechnicians ? acTechnicians.map((technician, key) => (
                                    <>
                                        {technician?.elektromontage ? (
                                            <Marker
                                                position={{
                                                    lat: Number(technician.lat),
                                                    lng: Number(technician.lng),
                                                }}
                                                icon={AC_2Img}
                                            >
                                            </Marker>
                                        ) : ''}
                                    </>
                                )) : ''}

                                {directionsCallbackCount < 1 && miltarbeiter?.street && project?.street ? (
                                    <DirectionsService
                                        // required
                                        options={{
                                            destination: (miltarbeiter?.street + ' ' + miltarbeiter?.nr + ', ' + miltarbeiter?.ort + ', Germany'),
                                            origin: (project?.street + ' ' + project?.nr + ', ' + project?.ort + ', Germany'),
                                            travelMode: 'DRIVING', //DRIVING, WALKING, BICYCLING, TRANSIT
                                        }}
                                        // required
                                        callback={(result) => {
                                            directionsCallback(result);
                                        }}
                                        // optional
                                        onLoad={directionsService => {
                                            //console.log('DirectionsService onLoad directionsService: ', directionsService)
                                        }}
                                        // optional
                                        onUnmount={directionsService => {
                                            //console.log('DirectionsService onUnmount directionsService: ', directionsService)
                                        }}
                                    />
                                ) : ''}

                                {
                                    response !== null && (
                                        <DirectionsRenderer
                                            // required
                                            options={{
                                                directions: response,
                                                //draggable: true,
                                            }}
                                            // optional
                                            onLoad={directionsRenderer => {
                                                //console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
                                            }}
                                            // optional
                                            onUnmount={directionsRenderer => {
                                                //console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
                                            }}
                                        />
                                    )
                                }

                            </GoogleMap>
                        </>
                    ) : 'Loading...'}
                </div>
            </div >
        </>
    );
}

export default UsersByProject;
