import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import callFetch from "helpers/callFetch";
import Setting from "examples/Icons/Setting";
import { number } from "prop-types";
import SoftTypography from "components/SoftTypography";
import Divider from "@mui/material/Divider";
import SoftBox from "components/SoftBox";
import SidenavCard from "components/SidenavCard";

function Sidebar() {
    const { t } = useTranslation();
    const [lastMessage, setLastMessage] = useState([]);
    const [authId, setAuthId] = useState('');
    const [nowDrop, setNowDrop] = useState(window.location.href.split('/')[3]);
    let navClassName = "nav-link";
    let activeClassName = "nav-link active";
    let dropdownClass = "collapse";
    let dropdownClassShow = "collapse show";

    useEffect(() => {
        callFetch("chatuser-sidebar", "GET", []).then((res) => {
            //   console.log(res.lastMessage); 
            //   console.log(res.authId); 
            setLastMessage(res.lastMessage);
            setAuthId(res.authId);
        });
    }, [])

    useEffect(() => {
        const g_sidenav_pinned = document.getElementsByClassName("g-sidenav-pinned");
        if (g_sidenav_pinned.length) {
            document.getElementById("iconSidenav").click();
        }
    }, [window.location.href]);

    return (
        <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3  bg-white" id="sidenav-main">
            <div className="sidenav-header">
                <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav" />
                <a className="navbar-brand m-0" href="/">
                    <img src="/assets/img/logo.png" className="navbar-brand-img h-100" style={{ maxHeight: '50px', marginTop: '16px' }} alt="main_logo" />
                </a>
            </div>
            <hr className="horizontal dark mt-0" />
            <div className="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
                <ul className="navbar-nav">

                    {JSON.parse(Cookies.get('user')).roleid == 7 || JSON.parse(Cookies.get('user')).roleid == 8 ? (
                        <>

                        </>
                    ) : <>
                        <li className="nav-item">
                            <NavLink to="/dashboard" onClick={(e) => setNowDrop('dashboard')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                <i className="fa-solid fa-dashboard icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="sidenav-normal">{t('Dashboard')}</span>
                            </NavLink>
                        </li>
                    </>}

                    <li className="nav-item">
                        {lastMessage && (
                            <NavLink id="chat-link" to={lastMessage.sender_id == authId ? '/chat/message/' + lastMessage.receiver_id : '/chat/message/' + lastMessage.sender_id} onClick={(e) => setNowDrop('')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                <i className="fa-solid fa-envelope icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></i>
                                <span className="nav-link-text ms-1">{t('Message')}</span>
                            </NavLink>
                        )}
                        {!lastMessage && (
                            <NavLink id="chat-link" to='/chat' onClick={(e) => setNowDrop('')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                <i className="fa-solid fa-envelope icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></i>
                                <span className="nav-link-text ms-1">{t('Message')}</span>
                            </NavLink>
                        )}
                    </li>


                    {Cookies.get('permissions').indexOf("Team Wise Filter Calendar") !== -1 ? (
                        <li className="nav-item">
                            <NavLink to='/calendar' onClick={(e) => setNowDrop('')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                <i className="fa-solid fa-calendar icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></i>
                                <span className="nav-link-text ms-1">{t('Calendar')}</span>
                            </NavLink>
                        </li>
                    ) : <></>}

                    {Cookies.get('permissions').indexOf("project-read") !== -1 ||
                        Cookies.get('permissions').indexOf("project-create") !== -1 ||
                        Cookies.get('permissions').indexOf("quotation-read") !== -1 ||
                        Cookies.get('permissions').indexOf("order-read") !== -1 ||
                        Cookies.get('permissions').indexOf("customer-read") !== -1 ||
                        Cookies.get('permissions').indexOf("product-read") !== -1 ||
                        Cookies.get('permissions').indexOf("module-read") !== -1 ||
                        Cookies.get('permissions').indexOf("speicher-read") !== -1 ||
                        Cookies.get('permissions').indexOf("callcenter-read") !== -1 ||
                        Cookies.get('permissions').indexOf("Reseller") !== -1 ||
                        Cookies.get('permissions').indexOf("reclamation-read") !== -1 ||
                        Cookies.get('permissions').indexOf("ticket-read") !== -1 ||
                        Cookies.get('permissions').indexOf("Field Service Overview") !== -1
                        ? (
                            <>
                                <Divider />
                                <SoftTypography
                                    display="block"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="uppercase"
                                    opacity={0.6}
                                    pl={4}
                                    mt={0}
                                    mb={1}
                                    ml={1}
                                >
                                    {t('Crm')}
                                </SoftTypography>
                            </>
                        ) : <></>}

                    {Cookies.get('permissions').indexOf("project-read") !== -1 || Cookies.get('permissions').indexOf("project-create") !== -1 || Cookies.get('permissions').indexOf("reclamation-read") !== -1 ? (
                        <>
                            <li className="nav-item">
                                <a data-bs-toggle="collapse" href="#project-management" className={nowDrop === 'project-management' ? activeClassName : navClassName} aria-controls="project-management" role="button" aria-expanded={nowDrop === 'project-management'}>
                                    <i className="fa-solid fa-briefcase icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                    <span className="nav-link-text ms-1">{t('Project Management')}</span>
                                </a>
                                <div className={nowDrop === 'project-management' ? dropdownClassShow : dropdownClass} id="project-management">
                                    <ul className="nav ms-4 ps-3">
                                        {Cookies.get('permissions').indexOf("project-create") !== -1 ? (
                                            <li className="nav-item">
                                                <NavLink to="/project-management/project/create" onClick={(e) => setNowDrop('project-management')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                    <span className="sidenav-normal">{t('New Project')}</span>
                                                </NavLink>
                                            </li>
                                        ) : <></>}
                                        {Cookies.get('permissions').indexOf("project-read") !== -1 ? (
                                            <li className="nav-item">
                                                <NavLink to="/project-management/projects" onClick={(e) => setNowDrop('project-management')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                    <span className="sidenav-normal">{t('Projects')}</span>
                                                </NavLink>
                                            </li>
                                        ) : <></>}

                                        {Cookies.get('permissions').indexOf("reclamation-read") !== -1 ? (
                                            <>
                                                <li className="nav-item">
                                                    <NavLink to="reclamation/index" onClick={(e) => setNowDrop('project-management')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                        <span className="sidenav-normal">{t('Reklamationen')}</span>
                                                    </NavLink>
                                                </li>
                                            </>
                                        ) : <></>}

                                        {Cookies.get('permissions').indexOf("ticket-read") !== -1 ? (
                                            <>
                                                <li className="nav-item">
                                                    <NavLink to="ticket" onClick={(e) => setNowDrop('project-management')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                        <span className="sidenav-normal">{t('Ticket')}</span>
                                                    </NavLink>
                                                </li>
                                            </>
                                        ) : <></>}
                                    </ul>
                                </div>
                            </li>
                        </>
                    ) : <></>}

                    {Cookies.get('permissions').indexOf("quotation-read") !== -1 ||
                        Cookies.get('permissions').indexOf("Reseller") !== -1 ||
                        Cookies.get('permissions').indexOf("order-read") !== -1 ? (
                        <li className={"nav-item"}>
                            <a data-bs-toggle="collapse" href="#sales-distribution" className={nowDrop === 'sales-distribution' ? activeClassName : navClassName} aria-controls="sales-distribution" role="button" aria-expanded={nowDrop === 'sales-distribution'}>
                                <i className="fa-solid fa-bag-shopping icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                {/* <svg class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path></svg> */}
                                <span className="nav-link-text ms-1">{t('Sales & Distribution')}</span>
                            </a>
                            <div className={nowDrop === 'sales-distribution' ? dropdownClassShow : dropdownClass} id="sales-distribution">
                                <ul className="nav ms-4 ps-3">
                                    {Cookies.get('permissions').indexOf("quotation-read") !== -1 || Cookies.get('permissions').indexOf("order-read") !== -1 ? (
                                        <>
                                            <li className="nav-item">
                                                <a data-bs-toggle="collapse" href="#Distribution" className={nowDrop === 'fieldserviceprojects_Elektromontage' ? activeClassName : navClassName} aria-controls="sales-distribution" role="button" aria-expanded={nowDrop === 'sales-distribution'}>
                                                    <span className="nav-link-text">{t('Distribution')}</span>
                                                </a>
                                                <div className={nowDrop === 'Distribution' ? dropdownClassShow : dropdownClass} id="Distribution">
                                                    <ul className="nav">
                                                        {Cookies.get('permissions').indexOf("quotation-read") !== -1 ? (
                                                            <li className="nav-item">
                                                                <NavLink to="/sales-distribution/quations/view" onClick={(e) => setNowDrop('sales-distribution')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                    <span className="sidenav-normal">{t('Angebote')}</span>
                                                                </NavLink>
                                                            </li>
                                                        ) : <></>}

                                                        {Cookies.get('permissions').indexOf("order-read") !== -1 ? (
                                                            <>
                                                                <li className="nav-item">
                                                                    <NavLink to="/sales-distribution/orders" onClick={(e) => setNowDrop('sales-distribution')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                        <span className="sidenav-normal">{t('Auftrag')}</span>
                                                                    </NavLink>
                                                                </li>
                                                            </>
                                                        ) : <></>}
                                                    </ul>
                                                </div>
                                            </li>
                                        </>
                                    ) : <></>}

                                    {Cookies.get('permissions').indexOf("Reseller") !== -1 ? (
                                        <li className="nav-item">
                                            <a data-bs-toggle="collapse" href="#reseller" className={nowDrop === 'fieldserviceprojects_Elektromontage' ? activeClassName : navClassName} aria-controls="sales-distribution" role="button" aria-expanded={nowDrop === 'distribution'}>
                                                <span className="nav-link-text">{t('Reseller')}</span>
                                            </a>
                                            <div className={nowDrop === 'reseller' ? dropdownClassShow : dropdownClass} id="reseller">
                                                <ul className="nav">
                                                    <li className="nav-item">
                                                        <NavLink to="/reseller/project" onClick={(e) => setNowDrop('sales-distribution')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                            <span className="sidenav-normal">{t('Resell Projekte')}</span>
                                                        </NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink to="/reseller/storno" onClick={(e) => setNowDrop('sales-distribution')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                            <span className="sidenav-normal">{t('Storno')}</span>
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    ) : <></>}

                                    {Cookies.get('permissions').indexOf("customer-read") !== -1 ? (
                                        <>
                                            <li className="nav-item">
                                                <NavLink to="/sales-distribution/customers" onClick={(e) => setNowDrop('sales-distribution')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                    <span className="sidenav-normal">{t('Customer')}</span>
                                                </NavLink>
                                            </li>
                                        </>
                                    ) : <></>}
                                </ul>
                            </div>
                        </li>
                    ) : <></>}

                    {Cookies.get('permissions').indexOf("product-read") !== -1 ? (
                        <li className="nav-item">
                            <a data-bs-toggle="collapse" href="#supply-chain" className={nowDrop === 'supply-chain' ? activeClassName : navClassName} aria-controls="supply-chain" role="button" aria-expanded={nowDrop === 'supply-chain'}>
                                <i className="fa-solid fa-truck icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('Products')}</span>
                            </a>
                            <div className={nowDrop === 'supply-chain' ? dropdownClassShow : dropdownClass} id="supply-chain">
                                <ul className="nav ms-4 ps-3">
                                    {Cookies.get('permissions').indexOf("product-read") !== -1 ? (
                                        <>
                                            {/*<li className="nav-item">
                                            <NavLink to="/supply-chain/product" onClick={(e) => setNowDrop('supply-chain')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Products')}</span>
                                            </NavLink>
                                        </li>*/}

                                            <li class="nav-item ">
                                                <a class="nav-link collapsed" data-bs-toggle="collapse" aria-expanded="false" href="#productsExample">
                                                    <span class="sidenav-mini-icon"> P </span>
                                                    <span class="sidenav-normal"> {t('Products')} <b class="caret"></b></span>
                                                </a>
                                                <div class="collapse" id="productsExample">
                                                    <ul class="nav nav-sm flex-column">
                                                        {Cookies.get('permissions').indexOf("product-create") !== -1 ? (
                                                            <>
                                                                <li className="nav-item">
                                                                    <NavLink to="/supply-chain/products/create" onClick={(e) => setNowDrop('supply-chain')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                        <span className="sidenav-normal">{t('New Product')}</span>
                                                                    </NavLink>
                                                                </li>
                                                            </>
                                                        ) : <></>}

                                                        {Cookies.get('permissions').indexOf("module-read") !== -1 ? (
                                                            <>
                                                                <li className="nav-item">
                                                                    <NavLink to="/supply-chain/products/module" onClick={(e) => setNowDrop('supply-chain')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                        <span className="sidenav-normal">{t('Module')}</span>
                                                                    </NavLink>
                                                                </li>
                                                            </>
                                                        ) : <></>}

                                                        {Cookies.get('permissions').indexOf("speicher-read") !== -1 ? (
                                                            <>
                                                                <li className="nav-item">
                                                                    <NavLink to="/supply-chain/products/speicher" onClick={(e) => setNowDrop('supply-chain')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                        <span className="sidenav-normal">{t('Speicher')}</span>
                                                                    </NavLink>
                                                                </li>
                                                            </>
                                                        ) : <></>}

                                                        {Cookies.get('permissions').indexOf("speicher-read") !== -1 ? (
                                                            <>
                                                                <li className="nav-item">
                                                                    <NavLink to="/supply-chain/products/wechselrichter" onClick={(e) => setNowDrop('supply-chain')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                        <span className="sidenav-normal">{t('Wechselrichter')}</span>
                                                                    </NavLink>
                                                                </li>
                                                            </>
                                                        ) : <></>}
                                                    </ul>
                                                </div>
                                            </li>
                                        </>
                                    ) : <></>}
                                </ul>
                            </div>
                        </li>
                    ) : <></>}

                    {Cookies.get('permissions').indexOf("callcenter-read") !== -1 ? (
                        <li className={"nav-item"}>
                            <a data-bs-toggle="collapse" href="#call-center" className={nowDrop === 'call-center' ? activeClassName : navClassName} aria-controls="call-center" role="button" aria-expanded={nowDrop === 'call-center'}>
                                <i className="fa-sharp fa-solid fa-phone icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('Call Center')}</span>
                            </a>
                            <div className={nowDrop === 'call-center' ? dropdownClassShow : dropdownClass} id="call-center">
                                <ul className="nav ms-4 ps-3">
                                    {Cookies.get('permissions').indexOf("callcenter-create") !== -1 ? (
                                        <li className="nav-item">
                                            <NavLink to="/call-center/create" onClick={(e) => setNowDrop('call-center')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('New Call Center')}</span>
                                            </NavLink>
                                        </li>
                                    ) : <></>}

                                    {Cookies.get('permissions').indexOf("callcenter-read") !== -1 ? (
                                        <li className="nav-item">
                                            <NavLink to="/call-center/index/view" onClick={(e) => setNowDrop('call-center')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Call Center')}</span>
                                            </NavLink>
                                        </li>
                                    ) : <></>}
                                </ul>
                            </div>
                        </li>
                    ) : <></>}

                    {Cookies.get('permissions').indexOf("Field Service Overview") !== -1 ? (
                        <li className={"nav-item"}>
                            <a data-bs-toggle="collapse" href="#field-service" className={nowDrop === 'field-service' ? activeClassName : navClassName} aria-controls="field-service" role="button" aria-expanded={nowDrop === 'field-service'}>
                                {/* <i className="fa-solid fa-screwdriver-wrench icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i> */}

                                {/*<Setting color={nowDrop === 'field-service' ? "white":"dark"} size="0.9375rem"  />*/}
                                <i className="fa-sharp fa-solid fa-screwdriver-wrench icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('AC / DC Planung')}</span>
                            </a>
                            <div className={nowDrop === 'field-service' || nowDrop === 'fieldserviceprojects_dachmontage' || nowDrop === 'fieldserviceprojects_Elektromontage' ? dropdownClassShow : dropdownClass} id="field-service">
                                <ul className="nav ms-4 ps-3">

                                    <>
                                        {Cookies.get('permissions').indexOf("Field Service Overview") !== -1 ? (
                                            <>
                                                <li className="nav-item">
                                                    <NavLink to="/field-service/overview" onClick={(e) => setNowDrop('field-service')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                        <span className="sidenav-normal">{t('Overview')}</span>
                                                    </NavLink>
                                                </li>

                                                <li className="nav-item">
                                                    <NavLink to="/field-service/reports" onClick={(e) => setNowDrop('field-service')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                        <span className="sidenav-normal">{t('Report')}</span>
                                                    </NavLink>
                                                </li>

                                            </>
                                        ) : <></>}

                                        {Cookies.get('permissions').indexOf("Only DC Projects") !== -1 ? (
                                            <>
                                                <li className="nav-item">
                                                    <a data-bs-toggle="collapse" href="#fieldserviceprojects_dachmontage" className={nowDrop === 'fieldserviceprojects_dachmontage' ? activeClassName : navClassName} aria-controls="field-service" role="button" aria-expanded={nowDrop === 'field-service'}>
                                                        <span className="nav-link-text">{t('Dachmontage')}</span>
                                                    </a>
                                                    <div className={nowDrop === 'fieldserviceprojects_dachmontage' ? dropdownClassShow : dropdownClass} id="fieldserviceprojects_dachmontage">
                                                        <ul className="nav">
                                                            <li className="nav-item">
                                                                <NavLink to="/field-service/dachmontage/open" onClick={(e) => setNowDrop('fieldserviceprojects_dachmontage')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                    <span className="sidenav-normal">{t('Open Projects')}</span>
                                                                </NavLink>
                                                            </li>

                                                            <li className="nav-item">
                                                                <NavLink to="/field-service/dachmontage/done" onClick={(e) => setNowDrop('fieldserviceprojects_dachmontage')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                    <span className="sidenav-normal">{t('Planed Projects')}</span>
                                                                </NavLink>
                                                            </li>

                                                            <li className="nav-item">
                                                                <NavLink to="/field-service/dachmontage/revision" onClick={(e) => setNowDrop('fieldserviceprojects_dachmontage')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                    <span className="sidenav-normal">{t('Revision Request')}</span>
                                                                </NavLink>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </>
                                        ) : <></>}

                                        {Cookies.get('permissions').indexOf("Only AC Projects") !== -1 ? (

                                            <li className="nav-item">
                                                <a data-bs-toggle="collapse" href="#fieldserviceprojects_Elektromontage" className={nowDrop === 'fieldserviceprojects_Elektromontage' ? activeClassName : navClassName} aria-controls="field-service" role="button" aria-expanded={nowDrop === 'field-service'}>
                                                    <span className="nav-link-text">{t('Elektromontage')}</span>
                                                </a>
                                                <div className={nowDrop === 'fieldserviceprojects_Elektromontage' ? dropdownClassShow : dropdownClass} id="fieldserviceprojects_Elektromontage">
                                                    <ul className="nav">
                                                        <li className="nav-item">
                                                            <NavLink to="/field-service/elktromontage/open" onClick={(e) => setNowDrop('fieldserviceprojects_Elektromontage')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                <span className="sidenav-normal">{t('Open Projects')}</span>
                                                            </NavLink>
                                                        </li>

                                                        <li className="nav-item">
                                                            <NavLink to="/field-service/elktromontage/done" onClick={(e) => setNowDrop('fieldserviceprojects_Elektromontage')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                <span className="sidenav-normal">{t('Planed Projects')}</span>
                                                            </NavLink>
                                                        </li>

                                                        <li className="nav-item">
                                                            <NavLink to="/field-service/elktromontage/revision" onClick={(e) => setNowDrop('fieldserviceprojects_Elektromontage')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                <span className="sidenav-normal">{t('Revision Request')}</span>
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        ) : <></>}
                                    </>

                                </ul>
                            </div>
                        </li>
                    ) : <></>}

                    {
                        Cookies.get('permissions').indexOf("Controlling Overview") !== -1 ||
                            Cookies.get('permissions').indexOf("VKB Evaluation") !== -1 ||
                            Cookies.get('permissions').indexOf("Production Evaluation") !== -1 ||
                            Cookies.get('permissions').indexOf("invoice-read") !== -1 ||
                            Cookies.get('permissions').indexOf("Purchase") !== -1 ||
                            Cookies.get('permissions').indexOf("payroll-read") !== -1 ? (
                            <>
                                <Divider />
                                <SoftTypography
                                    display="block"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="uppercase"
                                    opacity={0.6}
                                    pl={4}
                                    mt={0}
                                    mb={1}
                                    ml={1}
                                >
                                    {t('ERP')}
                                </SoftTypography>
                            </>
                        ) : <></>}

                    {Cookies.get('permissions').indexOf("invoice-read") !== -1 || Cookies.get('permissions').indexOf("payroll-read") !== -1 ? (
                        <li className="nav-item">
                            <a data-bs-toggle="collapse" href="#Finance-management" className={nowDrop === 'Finance-management' ? activeClassName : navClassName} aria-controls="Finance-management" role="button" aria-expanded={nowDrop === 'Finance-management'}>
                                <i className="fa-solid fa-coins icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('Finance')}</span>
                            </a>
                            <div className={nowDrop === 'Finance-management' ? dropdownClassShow : dropdownClass} id="Finance-management">
                                <ul className="nav ms-4 ps-3">
                                    {Cookies.get('permissions').indexOf("invoice-read") !== -1 ? (
                                        <li className="nav-item">
                                            <a data-bs-toggle="collapse" href="#invoice" className={nowDrop === 'invoice' ? activeClassName : navClassName} aria-controls="call-center" role="button" aria-expanded={nowDrop === 'call-center'}>
                                                <span className="nav-link-text">{t('Invoices')}</span>
                                            </a>
                                            <div className={nowDrop === 'invoice' ? dropdownClassShow : dropdownClass} id="invoice">
                                                <ul className="nav">
                                                    {Cookies.get('permissions').indexOf("invoice-read") !== -1 ? (
                                                        <li className="nav-item">
                                                            <NavLink to="/finance/invoices" onClick={(e) => setNowDrop('invoices')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                <span className="sidenav-normal">{t('Invoices')}</span>
                                                            </NavLink>
                                                        </li>
                                                    ) : <></>}

                                                    {Cookies.get('permissions').indexOf("invoice-read") !== -1 ? (
                                                        <li className="nav-item">
                                                            <NavLink to="finance/payments" onClick={(e) => setNowDrop('payments')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                <span className="sidenav-normal">{t('Payments')}</span>
                                                            </NavLink>
                                                        </li>
                                                    ) : <></>}

                                                </ul>
                                            </div>

                                        </li>
                                    ) : <></>}

                                    {Cookies.get('permissions').indexOf("payroll-read") !== -1 ? (
                                        <li className="nav-item">
                                            <NavLink to="/finance/payrolls" onClick={(e) => setNowDrop('invoices')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Payrolls')}</span>
                                            </NavLink>
                                        </li>
                                    ) : <></>}
                                    {Cookies.get('permissions').indexOf("payroll-read") !== -1 ? (
                                        <li className="nav-item">
                                            <NavLink to="/finance/artikels" onClick={(e) => setNowDrop('invoices')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Artikel')}</span>
                                            </NavLink>
                                        </li>
                                    ) : <></>}
                                </ul>
                            </div>
                        </li>
                    ) : <></>}


                    {Cookies.get('permissions').indexOf("Purchase") !== -1 ? (
                        <li className="nav-item">
                            <a data-bs-toggle="collapse" href="#purchase-management" className={nowDrop === 'purchase-management' ? activeClassName : navClassName} aria-controls="purchase-management" role="button" aria-expanded={nowDrop === 'purchase-management'}>
                                <i className="fa-solid fa-coins icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('Purchase Management')}</span>
                            </a>
                            <div className={nowDrop === 'purchase-management' ? dropdownClassShow : dropdownClass} id="purchase-management">
                                <ul className="nav ms-4 ps-3">
                                    <li className="nav-item">
                                        <NavLink to="/purchase-management/purchase" onClick={(e) => setNowDrop('invoices')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                            <span className="sidenav-normal">{t('Purchase')}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    ) : <></>}


                    {
                        Cookies.get('permissions').indexOf("Controlling Overview") !== -1 ||
                            Cookies.get('permissions').indexOf("VKB Evaluation") !== -1 ||
                            Cookies.get('permissions').indexOf("Production Evaluation") !== -1 ? (
                            <>
                                <li className={"nav-item d-none"}>
                                    <a data-bs-toggle="collapse" href="#controlling" className={nowDrop === "controlling" ? activeClassName : navClassName} aria-controls="controlling" role="button" aria-expanded={nowDrop === "controlling"}>
                                        <i className="fa-solid fa-users icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                        <span className="nav-link-text ms-1">{t("Controlling")}</span>
                                    </a>
                                    <div className={nowDrop === 'controlling' ? dropdownClassShow : dropdownClass} id="controlling">
                                        <ul className="nav ms-4 ps-3">
                                            {Cookies.get('permissions').indexOf("Controlling Overview") !== -1 ? (
                                                <li className="nav-item">
                                                    <NavLink to="/analyses-overview" onClick={(e) => setNowDrop('controlling')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                        <span className="sidenav-normal">{t('Overview')}</span>
                                                    </NavLink>
                                                </li>
                                            ) : <></>}
                                            <li className="nav-item">
                                                <a data-bs-toggle="collapse" href="#analyses" className={nowDrop === "analyses" ? activeClassName : navClassName} aria-controls="analyses" role="button" aria-expanded={nowDrop === "analyses"} >
                                                    <span className="nav-link-text">{t("Analyses")}</span>
                                                </a>
                                                <div className={nowDrop === 'analyses' ? dropdownClassShow : dropdownClass} id="analyses">
                                                    <ul className="nav">
                                                        {Cookies.get('permissions').indexOf("VKB Evaluation") !== -1 ? (
                                                            <li className="nav-item">
                                                                <NavLink to="/analyses-vkb-evaluation" onClick={(e) => setNowDrop("controlling")} className={({ isActive }) => isActive ? activeClassName : navClassName} >
                                                                    <span className="sidenav-normal">{t("VKB Evaluation")}</span>
                                                                </NavLink>
                                                            </li>
                                                        ) : <></>}

                                                        {Cookies.get('permissions').indexOf("Production Evaluation") !== -1 ? (
                                                            <li className="nav-item">
                                                                <NavLink to="/analyses-production-evaluation" onClick={(e) => setNowDrop("controlling")} className={({ isActive }) => isActive ? activeClassName : navClassName} >
                                                                    <span className="sidenav-normal">{t("Production evaluation")}</span>
                                                                </NavLink>
                                                            </li>
                                                        ) : <></>}
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </>
                        ) : <></>}


                    {Cookies.get('permissions').indexOf("employee-read") !== -1 || Cookies.get('permissions').indexOf("team-read") !== -1 ? (
                        <>
                            <Divider />
                            <SoftTypography
                                display="block"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="uppercase"
                                opacity={0.6}
                                pl={4}
                                mt={0}
                                mb={1}
                                ml={1}
                            >
                                {t('Personalwesen')}
                            </SoftTypography>
                        </>
                    ) : <></>}

                    {Cookies.get('permissions').indexOf("employee-read") !== -1 || Cookies.get('permissions').indexOf("team-read") !== -1 ? (
                        <>
                            <li className={"nav-item"}>
                                <a data-bs-toggle="collapse" href="#hr" className={nowDrop === 'hr' ? activeClassName : navClassName} aria-controls="hr" role="button" aria-expanded={nowDrop === 'hr'}>
                                    <i className="fa-solid fa-users icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                    <span className="nav-link-text ms-1">{t('Staff Management')}</span>
                                </a>
                                <div className={nowDrop === 'hr' ? dropdownClassShow : dropdownClass} id="hr">
                                    <ul className="nav ms-4 ps-3">
                                        {Cookies.get('permissions').indexOf("employee-read") !== -1 ? (
                                            <li className="nav-item">
                                                <NavLink to="/hr/employees" onClick={(e) => setNowDrop('hr')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                    <span className="sidenav-normal">{t('Employees')}</span>
                                                </NavLink>
                                            </li>
                                        ) : <></>}

                                        {Cookies.get('permissions').indexOf("team-read") !== -1 ? (
                                            <li className="nav-item">
                                                <a data-bs-toggle="collapse" href="#invoice" className={nowDrop === 'invoice' ? activeClassName : navClassName} aria-controls="call-center" role="button" aria-expanded={nowDrop === 'call-center'}>
                                                    <span className="nav-link-text">{t('Teams')}</span>
                                                </a>
                                                <div className={nowDrop === 'invoice' ? dropdownClassShow : dropdownClass} id="invoice">
                                                    <ul className="nav">
                                                        <li className="nav-item">
                                                            <NavLink to="/teams/sales" onClick={(e) => setNowDrop('invoices')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                <span className="sidenav-normal">{t('Sales Team')}</span>
                                                            </NavLink>
                                                        </li>
                                                        <li className="nav-item">
                                                            <NavLink to="/teams/ac" onClick={(e) => setNowDrop('payments')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                <span className="sidenav-normal">{t('AC Team')}</span>
                                                            </NavLink>
                                                        </li>

                                                        <li className="nav-item">
                                                            <NavLink to="/teams/dc" onClick={(e) => setNowDrop('payments')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                <span className="sidenav-normal">{t('DC Team')}</span>
                                                            </NavLink>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </li>
                                        ) : <></>}
                                    </ul>
                                </div>
                            </li>
                        </>
                    ) : <></>}


                    {Cookies.get('permissions').indexOf("role-read") !== -1 ? (
                        <>
                            <Divider />
                            <SoftTypography
                                display="block"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="uppercase"
                                opacity={0.6}
                                pl={4}
                                mt={0}
                                mb={1}
                                ml={1}
                            >
                                {t('Einstellungen')}
                            </SoftTypography>
                            <li className="nav-item">
                                <a data-bs-toggle="collapse" href="#user-settings" className={nowDrop === 'user-settings' ? activeClassName : navClassName} aria-controls="user-settings" role="button" aria-expanded={nowDrop === 'user-settings'}>
                                    <i className="fa-solid fa-gear icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                    <span className="nav-link-text ms-1">{t('Settings')}</span>
                                </a>
                                <div className={nowDrop === 'user-settings' ? dropdownClassShow : dropdownClass} id="user-settings">
                                    <ul className="nav ms-4 ps-3">
                                        {Cookies.get('permissions').indexOf("role-read") !== -1 ? (
                                            <>
                                                <li className="nav-item">
                                                    <NavLink to="/settings/roles" onClick={(e) => setNowDrop('user-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                        <span className="sidenav-normal">{t('Settings')}</span>
                                                    </NavLink>
                                                </li>

                                                <li className="nav-item">
                                                    <a data-bs-toggle="collapse" href="#email-settings" className={nowDrop === 'email-settings' ? activeClassName : navClassName} aria-controls="email-settings" role="button" aria-expanded={nowDrop === 'email-settings'}>
                                                        <span className="nav-link-text">{t('Email Setup')}</span>
                                                    </a>
                                                    <div className={nowDrop === 'email-settings' ? dropdownClassShow : dropdownClass} id="email-settings">
                                                        <ul className="nav ms-3 ps-1                                                                  ">
                                                            {Cookies.get('permissions').indexOf("role-read") !== -1 ? (
                                                                <>
                                                                    <li className="nav-item">
                                                                        <NavLink to="/settings/smtp/1/edit" onClick={(e) => setNowDrop('smptp-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                            <span className="sidenav-normal">{t('SMTP')}</span>
                                                                        </NavLink>
                                                                    </li>

                                                                    <li className="nav-item">
                                                                        <a data-bs-toggle="collapse" href="#emails-settings" className={nowDrop === 'email-settings' ? activeClassName : navClassName} aria-controls="emails-settings" role="button" aria-expanded={nowDrop === 'emails-settings'}>
                                                                            <span className="nav-link-text ms-1">{t('Emails')}</span>
                                                                        </a>
                                                                        <div className={nowDrop === 'emails-settings' ? dropdownClassShow : dropdownClass} id="emails-settings">
                                                                            <ul className="nav ms-1 ps-1">
                                                                                {Cookies.get('permissions').indexOf("role-read") !== -1 ? (
                                                                                    <>
                                                                                        <li className="nav-item"><NavLink to="/settings/emails/angebot/1/edit" onClick={(e) => setNowDrop('angebot-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- ' + t('Angebot')}</span></NavLink></li>
                                                                                        <li className="nav-item"><NavLink to="/settings/emails/order/1/edit" onClick={(e) => setNowDrop('order-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- ' + t('Order')}</span></NavLink></li>
                                                                                        <li className="nav-item"><NavLink to="/settings/emails/ac-planning/1/edit" onClick={(e) => setNowDrop('acPlanung-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- ' + t('AC Planung')}</span></NavLink></li>
                                                                                        <li className="nav-item"><NavLink to="/settings/emails/dc-planning/1/edit" onClick={(e) => setNowDrop('dcPlanung-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- ' + t('DC Planung')}</span></NavLink></li>
                                                                                        <li className="nav-item"><NavLink to="/settings/emails/70-percent-invoice/1/edit" onClick={(e) => setNowDrop('70_percent_invoice-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- ' + t('70% ') + t('Invoice')}</span></NavLink></li>
                                                                                        <li className="nav-item"><NavLink to="/settings/emails/30-percent-invoice/1/edit" onClick={(e) => setNowDrop('30_percent_invoice-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- ' + t('30% ' + t('Invoice'))}</span></NavLink></li>
                                                                                        <li className="nav-item"><NavLink to="/settings/emails/70-percent-payment/1/edit" onClick={(e) => setNowDrop('70_percent_payment-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- ' + t('70% ' + t('Payment'))}</span></NavLink></li>
                                                                                        <li className="nav-item"><NavLink to="/settings/emails/30-percent-payment/1/edit" onClick={(e) => setNowDrop('30_percent_payment-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- ' + t('30% ' + t('Payment'))}</span></NavLink></li>
                                                                                    </>
                                                                                ) : <></>}
                                                                            </ul>
                                                                        </div>
                                                                    </li>
                                                                </>
                                                            ) : <></>}
                                                        </ul>
                                                    </div>
                                                </li>
                                            </>
                                        ) : <></>}
                                    </ul>
                                </div>
                            </li>
                        </>
                    ) : <></>}

                    <SoftBox pt={2} my={2} mx={2}>
                        <SidenavCard />
                    </SoftBox>

                    <li className="nav-item d-none">
                        <NavLink to="/application-settings" onClick={(e) => setNowDrop('')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa fa-globe icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></i>
                            <span className="nav-link-text ms-1">Application Settings</span>
                        </NavLink>
                    </li>


                </ul>
            </div>
        </aside>
    );
}

export default Sidebar;
