import React from "react";
import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import IndexTable from "./IndexTable";
import callFetch from "../../../helpers/callFetch";
import Cookies from 'js-cookie';
// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftDropzone from "components/SoftDropzone";
import { ToastContainer, toast } from 'react-toastify';
import { getValue } from "@mui/system";

function Index() {
    const { t } = useTranslation();
    const [items, setItems] = useState([{ id: 0, artikel_title: '', quantity: 0 }]);
    const [refresh, setRefresh] = useState(0);
    const [data, setData] = useState([]);
    const [saving, setSaving] = useState(false);
    const [reclamation, setReclamation] = useState('Projektbezogene');
    const [showproduct, setShowProduct] = useState('now');
    const [projects, setProjects] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [artikels, setArtikels] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const notify = () => toast("Success");
    const [attachments, setAttachment] = useState([]);
    const [newDropzone, setNewDropzone] = useState(true);
    const [editForm, setEditForm] = useState(false);
    const [zuweisenAnPerson, setZuweisenAnPerson] = useState(false);


    const handleProject = (event) => {
        setData([])
        if (event.target.value) {
            callFetch("project/timelines/" + event.target.value, "GET", []).then((res) => {
                setData(res.data);
            });
        }
    }

    const handleCheckboxChange = (event) => {
        if (event.target.checked === false) {
            setReclamation('Projektbezogene');
        } else {
            setReclamation('Allgemeine');
        }
    };

    const handleCheckboxChangeProduct = (event) => {
        if (event.target.checked === false) {
            setShowProduct('no');
        } else {
            setShowProduct('yes');
        }
    };

    const handleCheckboxChangeProjekt = (event) => {
        if (event.target.checked === false) {
            setReclamation('Allgemeine');
        } else {
            setReclamation('Projektbezogene');
        }
    };

    function deleteProduct() {
        var titems = [];
        items.map((t) => {
            if (!t)
                return;
            titems.push(t);
        });
        setItems(titems);
        setRefresh(refresh + 1);
    }

    const {
        register,
        handleSubmit,
        reset,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const handleCreateReclamation = (id) => {
        var createReclamationModal = document.getElementById('createTicketModalOpen');
        createReclamationModal.click();
    }

    const handeleAttachment = (newData) => {
        let data = attachments;
        data[attachments.length] = newData;
        setAttachment(data);
        setValue('attachments', JSON.stringify(data));
    }


    const onSubmit = (formData) => {
        formData.zuweisen_an_person = (formData.zuweisen_an_person ? 1 : 0);

        setSaving(true);
        callFetch("ticket", "POST", formData, setError).then((res) => {
            setSaving(false);
            setRefresh(refresh + 1);
            setAttachment([]);
            setValue('attachments', '');
            if (!res.ok) return;
            document.getElementById('createTicketModalClose').click();
            clearForm();
            notify();
        });
    };

    useEffect(() => {
        setValue('employees', JSON.stringify([]));

        callFetch("get-artikels-projects-employees", "GET", []).then((res) => {
            setProjects(res.projects);
            setEmployees(res.employees);
            setArtikels(res.artikels);
        });
    }, []);


    const clearForm = () => {
        reset({
            id: 0,
            title: '',
            description: '',
            attachments: JSON.stringify([]),
        });

        setNewDropzone(true);
        setEditForm(false);
        setAttachment([]);
    }

    const dropZoneInitialize = (name = 'dachansicht') => {

        return (
            <SoftDropzone
                key={'dfdf'}
                options={{
                    dictDefaultMessage: t('Drop files here to upload'),
                    //addRemoveLinks: true,
                    acceptedFiles: ".jpeg,.jpg,.png,.pdf",
                    action: process.env.REACT_APP_API_URL + 'attachment',
                    headers: {
                        "Accept": "application/json",
                        "Authorization": "Bearer " + Cookies.get('token')
                    },
                    processing: function () {
                        document.body.classList.add('loading');
                    },
                    success: (file, response) => {
                        document.body.classList.remove('loading');
                        if (response.message == 'success') {
                            handeleAttachment(response.data);
                        }
                    },
                    maxfilesexceeded: function (file) {
                        this.removeAllFiles();
                        this.addFile(file);
                    },
                    error: function (file, response) {
                        document.body.classList.remove('loading');
                    }
                }}
            />
        )
    }

    return (
        <>
            <div className="d-sm-flex justify-content-between">
                <div className="relative-h">
                    {Cookies.get('permissions').indexOf("ticket-create") !== -1 ? (
                        <a data-bs-toggle="modal" id="createTicketModalOpen" data-bs-target="#exampleModal" href="#" className="btn btn-icon btn-primary">
                            {t('Ticket anlegen')}
                        </a>
                    ) : <></>}

                    {Cookies.get('permissions').indexOf("ticket-create") !== -1 ? (
                        <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'employee-export'} className="btn btn-outline-dark pull-right">
                            {t('Export CSV')}
                        </a>
                    ) : <></>}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('All Ticket')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <IndexTable refresh={refresh} />
                        </div>
                    </div>
                </div>
            </div>


            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <button type="button" data-bs-dismiss="modal" aria-label="Close" id="createTicketModalClose" className="close btn btn-icon-only btn-rounded btn-outline-dark mb-0 me-3 btn-sm d-flex align-items-center justify-content-center" style={{ fontSize: '12px', position: 'absolute', right: '0px' }}><i class="fa-sharp fa-solid fa-times icon icon-shape icon-sm  text-center d-flex align-items-center justify-content-center"></i></button>

                                <SoftBox p={2} lineHeight={1}>
                                    <SoftTypography variant="h6" fontWeight="medium">
                                        Create Ticket
                                    </SoftTypography>

                                    <div className="row">
                                        <div className="col-sm-3 mt-3">
                                            <div className="form-check form-switch">
                                                <input  {...register("type")} value="Allgemeine" className="form-check-input" onChange={handleCheckboxChange} checked={reclamation === 'Allgemeine' ? 'checked' : ''} type="checkbox" />
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">Allgemein</SoftTypography>
                                            </div>
                                        </div>

                                        <div className="col-sm-3 mt-3">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" {...register("type")} value="Projektbezogene" onChange={handleCheckboxChangeProjekt} checked={reclamation === 'Projektbezogene' ? 'checked' : ''} type="checkbox" />
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">Projektbezogen</SoftTypography>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="form-group ">
                                                <select
                                                    className="form-control"
                                                    {...register("project_id", {
                                                        required: true,
                                                    })}
                                                    onChange={handleProject}>
                                                    <option value="">{t('Select Projekt')}</option>
                                                    {projects && projects.map(project => (
                                                        <option value={project.id}>{project.id}</option>
                                                    ))}
                                                </select>
                                                <div className="invalid-feedback">{errors.project_id && errors.project_id.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="form-group">
                                                <label>Title</label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Enter title"
                                                    {...register("title", { required: true, })}
                                                />
                                                <div className="invalid-feedback">{errors.title && errors.title.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="form-group">
                                                <label>Status</label>
                                                <select
                                                    className="form-control"
                                                    {...register("project_status", {
                                                        required: true,
                                                    })}
                                                    required>
                                                    <option value="">{t('Project Status')}</option>
                                                    {
                                                        data.project && <option value="Appointment">Appointment</option>
                                                    }
                                                    {data.quations && data.quations.map((quation) => (
                                                        <option value="Quotation">Quotation</option>
                                                    ))}

                                                    {data.orders && data.orders.map((order) => (
                                                        <option value="Order">Order</option>
                                                    ))}

                                                    {data.invoices && data.invoices.map((invoice) => (
                                                        <option value="Invoice">Invoice</option>
                                                    ))}

                                                    {data.assign_orders && data.assign_orders.map((assign_order) => (
                                                        <option value={t(assign_order.dachmontag_elektromontage)}>{t(assign_order.dachmontag_elektromontage)}</option>
                                                    ))}
                                                </select>
                                                <div className="invalid-feedback">{errors.project_status && errors.project_status.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="form-group">
                                                <label>Abteilung</label>
                                                <select
                                                    className="form-control"
                                                    {...register("department")}
                                                >
                                                    <option value={''}>{t('Select')}</option>
                                                    <option value={'Dachmontage'}>Dachmontage</option>
                                                    <option value={'Elektromontage'}>Elektromontage</option>
                                                    <option value={'Einkauf'}>Einkauf</option>
                                                    <option value={'Buchhaltung'}>Buchhaltung</option>
                                                    <option value={'Vertrieb'}>Vertrieb</option>
                                                    <option value={'Fertigmeldung'}>Fertigmeldung</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.department && errors.department.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="form-group">
                                                <label>Prioritat</label>
                                                <select
                                                    className="form-control"
                                                    {...register("priority", {
                                                        required: true,
                                                    })}
                                                    required>
                                                    <option value="">{t('Select')}</option>
                                                    <option value="Urgend">{t('Urgend')}</option>
                                                    <option value="Normal">{t('Normal')}</option>
                                                    <option value="Not Important">{t('Not Important')}</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.priority && errors.priority.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="form-group">
                                                <label>Start Date</label>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    {...register("start_date", { required: true, })}
                                                    defaultValue={startDate}
                                                />
                                                <div className="invalid-feedback">{errors.start_date && errors.start_date.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="form-group">
                                                <label>End Date</label>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    {...register("end_date", { required: true, })}
                                                    defaultValue={endDate}
                                                />
                                                <div className="invalid-feedback">{errors.end_date && errors.end_date.message}</div>
                                            </div>
                                        </div>

                                        {reclamation === 'Allgemeine' ? (
                                            <div className="col-sm-12 mt-3">
                                                <div className="form-group">
                                                    <label>Partner-/ Lieferanten Nr.</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("partner", {
                                                            required: true,
                                                        })}
                                                        required>
                                                        <option value="">{t('Select')}</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                    </select>
                                                    <div className="invalid-feedback">{errors.partner && errors.partner.message}</div>
                                                </div>
                                            </div>
                                        ) : ''}

                                        <div className="col-sm-12 mt-3">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    {...register("zuweisen_an_person", {
                                                        onChange: (e) => {
                                                            setZuweisenAnPerson(e.target.checked);
                                                        }
                                                    })}
                                                    type="checkbox"
                                                    id="zuweisen_an_person"
                                                />
                                                <label class="custom-control-label m-0 mt-2" htmlFor="zuweisen_an_person">Zuweisen an Person</label>
                                            </div>
                                        </div>

                                        {zuweisenAnPerson ? (
                                            <div className="col-sm-12 mt-3">
                                                <div className="form-group">
                                                    <SoftSelect
                                                        options={employees}
                                                        getOptionLabel={(employees) => employees?.user?.name}
                                                        getOptionValue={(employees) => employees?.user?.id}
                                                        isMulti
                                                        onChange={(e) => {
                                                            var employees = [];
                                                            for (let [employee_key, employee] of Object.entries(e)) {
                                                                employees.push(employee?.user?.id)
                                                            }
                                                            setValue('employees', JSON.stringify(e));
                                                            // setValue('employees', employees.toString());
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ) : ''}


                                        <div className="col-sm-12 mt-3">
                                            <div className="form-group">
                                                <label>{t('Description')}</label>
                                                <textarea  {...register("description", { required: true, })} className="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <SoftBox>
                                        <SoftBox
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                                <SoftTypography mb={2} component="label" variant="caption" fontWeight="bold">
                                                    Attachments
                                                </SoftTypography>

                                                <div className="row mb-2">
                                                    <div className="col-lg-12 mt-lg-0">
                                                        <div className="emb-4">
                                                            {editForm == false ? (
                                                                <>
                                                                    <SoftBox lineHeight={1}>
                                                                        <SoftBox>
                                                                            <SoftBox
                                                                                display="flex"
                                                                                flexDirection="column"
                                                                                justifyContent="flex-end"
                                                                                height="100%"
                                                                            >
                                                                                {dropZoneInitialize()}
                                                                            </SoftBox>
                                                                        </SoftBox>
                                                                    </SoftBox>
                                                                </>
                                                            ) : <></>}
                                                        </div>
                                                        <br />
                                                    </div>
                                                </div>
                                            </SoftBox>

                                        </SoftBox>
                                    </SoftBox>
                                    <SoftBox display="flex" justifyContent="flex-end" mt={3}>
                                        <SoftBox p={2} display="flex" justifyContent="flex-end">
                                            <button
                                                type="button"
                                                className="btn btn-danger mx-2"
                                                onClick={() => {
                                                    clearForm();
                                                    document.getElementById('createTicketModalClose').click();
                                                }}
                                            >
                                                {t('Cancel')}
                                            </button>

                                            {!saving && (
                                                <button type="submit" className="btn btn-primary">
                                                    {t('Create Ticket')}
                                                </button>
                                            )}
                                            {saving && (
                                                <button type="button" className="btn btn-disabled" disabled>
                                                    {t('Creating ...')}
                                                </button>
                                            )}
                                        </SoftBox>
                                    </SoftBox>
                                </SoftBox>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Index;
