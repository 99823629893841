import { useMemo, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftEditor from "components/SoftEditor";

import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import SignaturePad from 'react-signature-pad-wrapper';
import DropZoneTabs from 'components/DropZoneTabs/Index';
import SoftSnackbar from "components/SoftSnackbar";

const DCabnahmeEdit = ({ edit_id, project_id }) => {
    const { t } = useTranslation();
    const [message, setMessage] = useState('Success');
    const [color, setColor] = useState('success');
    const params = useParams()
    const user = JSON.parse(Cookies.get('user'));
    const signaturePadRef = useRef(null);
    const submitBtn = useRef();
    const [saving, setSaving] = useState(false);
    const [modules, setModules] = useState([]);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [editDelay, setEditDelay] = useState(0)
    const [canEdit, setCanEdit] = useState(false)
    const [refresh2, setRefresh2] = useState(0);
    const [strgItemRefresh, setStrgItemRefresh] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [description, setDescription] = useState("");
    const [attachments, setAttachment] = useState([]);
    const [data, setData] = useState([]);
    const [link, setLink] = useState("")
    const [id, setID] = useState(0)
    const [tabFiles, setTabFiles] = useState({});
    const [strgItem, setStrgItem] = useState({});

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color={color}
            icon="check"
            title={t(message)}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgSuccess
        />
    );

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("module/all", "GET", []).then((res) => {
            setModules(res.data);
        });
    }, [0])

    useEffect(() => {
        if (Cookies.get('permissions').indexOf("dc_bnahmeprotokoll-update") !== -1) {
            setCanEdit(true);
        } else {
            setCanEdit(false);
        }

    }, [0])

    useEffect(() => {
        if (project_id !== undefined) {
            callFetch("dc_abnahmeprotokoll/" + project_id + "/edit", "GET", []).then((res) => {
                setStrgItem(JSON.parse(res?.data?.dcabname?.strg));
                setDescription(res?.data?.dcabname.description)
                signaturePadRef.current.fromDataURL(res?.data?.dcabname?.drawing);
                setValue('modultyp', res?.data?.dcabname?.modultyp)
                setValue('modulanzahl', res?.data?.dcabname.modulanzahl)
                setLink(res?.data?.pdf)
                for (let [key, value] of Object.entries(res?.data?.dcabname)) {
                    if (key == 'filesdata') {
                        setTabFiles(JSON.parse(value));
                    } else if (key == 'strg') {
                        var strg = JSON.parse(value);
                        for (let [strg_key, strg_value] of Object.entries(strg)) {
                            setValue('strg[' + strg_key + ']', strg_value);
                        }                        
                    } else if(key == 'volt'){
                        var volt = JSON.parse(value);
                        for (let [volt_key, volt_value] of Object.entries(volt)) {
                            setValue('volt[' + volt_key + ']', volt_value);
                        }
                    } else {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    }
                }

                setStrgItemRefresh(strgItemRefresh + 1)
            })
        }
    }, [project_id, refresh, editDelay])

    useEffect(() => {

        if (editDelay > 0) {
            setRefresh(1)
        }
    }, [editDelay])

    const setCallBackData = (data) => {
        setTabFiles(data);
    }

    const onSubmit = (formData) => {
        setSaving(true);
        var loadingElement = document.querySelector('.res-options .btn-primary-save');
        loadingElement.classList.add("btn-secondary");
        loadingElement.innerHTML = t('wird geladen...');

        formData.description = description;
        formData.sign = signaturePadRef.current.toDataURL('image/png');
        formData.filesdata = JSON.stringify(tabFiles);
        formData.strg = JSON.stringify(getValues('strg'));
        formData.volt = JSON.stringify(getValues('volt'));

        if (signaturePadRef.current.isEmpty()) {
            setColor('error')
            setMessage('Fill Out Skizze/Modulbelegung');
            setTimeout(() => {
                setSaving(false);

                loadingElement.classList.remove("btn-secondary");
                loadingElement.innerHTML = t('Save');

                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                document.getElementById("main-content").scrollTop = 0;
                openSuccessSB();
            }, 2000);
            return;
        }
        callFetch("dc_abnahmeprotokoll/" + edit_id, "POST", formData, setError).then((res) => {
            setData(res);
            setLink(res?.data?.pdf)
            setID(res?.data?.id)
            Cookies.set('dc_pdf', res?.data?.id)

            setColor('success')
            setMessage('Success');
            setTimeout(() => {
                setSaving(false);

                loadingElement.classList.remove("btn-secondary");
                loadingElement.innerHTML = t('Save');

                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                document.getElementById("main-content").scrollTop = 0;

                document.getElementById('ComisioningModalOpen').click();

                openSuccessSB();

                if (!res.ok) return;
                setSubmitSuccess(true);
                setEditDelay(1)

            }, 2000);
        });
    };
    return (
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <input type="hidden" defaultValue="PUT" {...register("_method")} />
                <SoftBox>
                    <a className="opacity-0" target={"_blank"} id="dc_abnahmeprotokoll" href={link ? link : "#"} ></a>
                    <input disabled={canEdit ? false : true} type="hidden" id="dcid" value={id} />
                    <Card sx={{ overflow: "visible" }}>
                        <SoftBox p={3}>
                            <SoftTypography variant="h5">{t('DC Abnahmeprotokoll')}</SoftTypography>
                        </SoftBox>
                        <SoftBox p={2} lineHeight={1}>
                            <Grid mb={2} container spacing={2}>
                                {useMemo(() => (
                                    <>
                                        {
                                            Object.keys(strgItem).map(function (keyName, index) {
                                                return (
                                                    <>
                                                        <Grid item xs={6} sm={6} md={2} lg={2}>
                                                            <label>Strg {(index + 1)}</label>
                                                            <input
                                                                disabled={canEdit ? false : true}
                                                                type="text" className="form-control"
                                                                placeholder={"Strg " + (index + 1)}
                                                                {...register('strg[' + keyName + ']', {
                                                                    required: true
                                                                })}
                                                                required
                                                            />
                                                        </Grid>

                                                        <Grid item xs={6} sm={6} md={2} lg={2}>
                                                            <label>Volt {(index + 1)}</label>
                                                            <input
                                                                disabled={canEdit ? false : true}
                                                                type="text" className="form-control"
                                                                placeholder={"Volt " + (index + 1)}
                                                                {...register('volt[' + keyName + ']', {
                                                                    required: true
                                                                })}
                                                                required
                                                            />
                                                        </Grid>
                                                    </>
                                                )
                                            })
                                        }
                                    </>
                                ), [strgItemRefresh])}
                                <Grid item xs={6} sm={6} md={2} lg={2}>
                                    <a
                                        className="btn btn-secondary mb-0"
                                        style={{ marginTop: '22px' }}
                                        onClick={() => {
                                            var strgItems = strgItem;
                                            strgItems['strg_' + ((Object.keys(strgItem).length + 1))] = '';
                                            setStrgItem(strgItems);
                                            setStrgItemRefresh(strgItemRefresh + 1)
                                        }}
                                    >{'+ ' + t('Add')}</a>
                                </Grid>
                            </Grid>

                            <Grid my={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Modultyp</label>
                                    <select disabled={canEdit ? false : true} type="text" className=" form-control" {...register('modultyp', { required: true })} required>
                                        <option>--</option>
                                        {
                                            modules && modules.map((modules, index) => {
                                                return <option key={index} value={modules.id}>{modules.name}</option>
                                            })
                                        }

                                    </select>
                                    <div className="invalid-feedback">{errors.modultyp && errors.modultyp.message}</div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Modulanzahl</label>
                                    <input disabled={canEdit ? false : true} type="number" className=" form-control" placeholder="Modulanzahl" {...register('modulanzahl', { required: true })} required />
                                    <div className="invalid-feedback">{errors.modulanzahl && errors.modulanzahl.message}</div>
                                </Grid>
                            </Grid>


                            <Grid my={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Stecker System</label>
                                    <input disabled={canEdit ? false : true} type="text" className=" form-control" placeholder="Stecker System" {...register('stecker_system', { required: true })} required />
                                    <div className="invalid-feedback">{errors.stecker_system && errors.stecker_system.message}</div>

                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Montagesystem</label>
                                    <input disabled={canEdit ? false : true} type="text" className=" form-control" placeholder="Montagesystem" {...register('montagesystem', { required: true })} required />
                                    <div className="invalid-feedback">{errors.montagesystem && errors.montagesystem.message}</div>

                                </Grid>
                            </Grid>


                            <Grid my={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Erdungsanschluss</label>
                                    {/* <input disabled={canEdit ? false : true} type="text" className=" form-control" placeholder="Erdungsanschluss" {...register('erdungsanschluss',{required: true})} required /> */}
                                    <div className="d-flex align-items-center justify-content-between mt-2" >
                                        <div className=" d-flex align-items-center">
                                            <label className="mt-2" >Pot.schine</label>
                                            <div className="form-check d-inline-block">
                                                <input disabled={canEdit ? false : true}
                                                    type="checkbox"
                                                    {...register("pot_schine")}
                                                    className="form-check-input me-md-12 ms-1"
                                                    value={1}
                                                />
                                            </div>
                                        </div>
                                        <div className=" d-flex align-items-center">
                                            <label className="mt-2" >Erdspieß</label>
                                            <div className="form-check d-inline-block">
                                                <input disabled={canEdit ? false : true}
                                                    type="checkbox"
                                                    {...register("erdspieß")}
                                                    required
                                                    className="form-check-input me-md-12 ms-1"
                                                    value={1}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="invalid-feedback">{errors.erdungsanschluss && errors.erdungsanschluss.message}</div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden an der PV Anlage</label>
                                    <select disabled={canEdit ? false : true} className=" form-control" {...register('pv_system', { required: true })} required >
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.pv_system && errors.pv_system.message}</div>

                                </Grid>
                            </Grid>



                            <Grid my={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden am Dach</label>
                                    <select disabled={canEdit ? false : true} className=" form-control" {...register('the_roof', { required: true })} required>
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.the_roof && errors.the_roof.message}</div>

                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden am Gebäude</label>
                                    <select disabled={canEdit ? false : true} className=" form-control" {...register('to_build', { required: true })} required >
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>

                                </Grid>
                            </Grid>

                            <Grid my={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Spannungsprotokoll erstellt</label>
                                    <select disabled={canEdit ? false : true} className=" form-control" {...register('log_created', { required: true })} >
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.log_created && errors.log_created.message}</div>

                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden an Verkablung, Stecker</label>
                                    <select disabled={canEdit ? false : true} className=" form-control" {...register('wiring_plugs', { required: true })} required >
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.wiring_plugs && errors.wiring_plugs.message}</div>

                                </Grid>
                            </Grid>

                            <Grid my={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden an Leitungsführung</label>

                                    <select disabled={canEdit ? false : true} className=" form-control" {...register('to_the_wiring', { required: true })} require>
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.to_the_wiring && errors.to_the_wiring.message}</div>

                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden am Montagesystem</label>
                                    <select disabled={canEdit ? false : true} className=" form-control" {...register('mounting_system', { required: true })} required >
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                </Grid>
                            </Grid>

                            <Grid my={2} container spacing={2} >
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden am Wechselrichter</label>
                                    <select disabled={canEdit ? false : true} className=" form-control" {...register('the_inverter', { required: true })} required>
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Baustelle sauber hinterlassen</label>
                                    <select disabled={canEdit ? false : true} className=" form-control" {...register('site_clean', { required: true })} required>
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                </Grid>
                            </Grid>


                            <Grid my={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} display="flex" alignItems="center" >
                                    <div className="form-check d-inline-block">
                                        <input disabled={canEdit ? false : true}
                                            type="checkbox"
                                            {...register("leistungsoptim_check")}
                                            required
                                            className="form-check-input"
                                            value={1}
                                        />
                                    </div>
                                    <label className="mt-2" >Leistungsoptim</label>

                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    <label className="mt-2">Stk</label>
                                    <input
                                        disabled={canEdit ? false : true}
                                        className=" form-control ms-2"
                                        placeholder="Stk" {...register("stk", {
                                            required: false
                                        })}
                                    />
                                </Grid>
                   
                            </Grid>


                            <Grid container mt={1} spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <div className="d-flex align-items-center justify-content-between mt-4" >
                                        <label>Verkablung / Leitungsführung:</label>
                                        <div className=" d-flex align-items-center">
                                            <label className="mt-2" >In Ordnung</label>
                                            <div className="form-check d-inline-block">
                                                <input disabled={canEdit ? false : true}
                                                    type="checkbox"
                                                    {...register("in_ordnung")}
                                                    className="form-check-input me-md-12 ms-1"
                                                    value={1}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <SoftBox
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="flex-end"
                                        height="100%"
                                    >
                                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">{t('Beanstandet')}</SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            placeholder={t('Beanstandet')}
                                            {...register("beanstandet", {
                                                required: false,
                                            })}
                                        />

                                    </SoftBox>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} my={1}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <div className="d-flex align-items-center justify-content-between mt-4" >
                                        <label>Asführung entspricht dem Angebot:</label>
                                        <div className=" d-flex align-items-center">
                                            <label className="mt-2" >In Ordnung</label>
                                            <div className="form-check d-inline-block">
                                                <input disabled={canEdit ? false : true}
                                                    type="checkbox"
                                                    {...register("in_ordnung2")}
                                                    className="form-check-input me-md-12 ms-1"
                                                    value={1}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <SoftBox
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="flex-end"
                                        height="100%"
                                    >
                                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">{t('Beanstandet')}</SoftTypography>
                                        </SoftBox>
                                        <SoftInput
                                            placeholder={t('Beanstandet')}
                                            {...register("beanstandet2", {
                                                required: false,
                                            })}

                                        />

                                    </SoftBox>
                                </Grid>
                            </Grid>




                            <SoftBox
                                display="flex"
                                flexDirection="column"
                                justifyContent="flex-end"
                                height="100%"
                            >
                                <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        {t('Description')}
                                    </SoftTypography>
                                </SoftBox>
                                {canEdit ? (
                                    <SoftEditor
                                        value={description}
                                        onChange={setDescription}
                                    />
                                ) : (
                                    <div dangerouslySetInnerHTML={{ __html: description }}></div>
                                )}
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </SoftBox>


                <SoftBox my={2}>
                    <Card>
                        <SoftBox p={3}>
                            <SoftTypography variant="h5">{t('Files')}</SoftTypography>
                        </SoftBox>

                        <SoftBox px={3} pb={3}>
                            <DropZoneTabs
                                tabs={[
                                    {
                                        name: 'ziegelbearbeitungMontageDachhaken',
                                        titel: 'Ziegelbearbeitung - Montage Dachhaken',
                                    }, {
                                        name: 'montierteDachhaken',
                                        titel: 'Montierte Dachhaken',
                                    }, {
                                        name: 'uKAllerGeneratorflachen',
                                        titel: 'UK aller Generatorflächen',
                                    }, {
                                        name: 'erdungGesUnterkonstruktion',
                                        titel: 'Erdung ges. Unterkonstruktion',
                                    }, {
                                        name: 'dCKabelverlegung',
                                        titel: 'DC Kabelverlegung',
                                    }, {
                                        name: 'abdichtungKabeleintritt',
                                        titel: 'Abdichtung Kabeleintritt',
                                    }, {
                                        name: 'zwischenraumDerModuleUndZiegel',
                                        titel: 'Zwischenraum der Module und Ziegel',
                                    }, {
                                        name: 'generatorenflachenAllerDachflachen',
                                        titel: 'Generatorenflächen aller Dachflächen',
                                    }, {
                                        name: 'typenschildDerVerbautenModule',
                                        titel: 'Typenschild der verbauten Module',
                                    }, {
                                        name: 'stringplanEntGeneratorenflache',
                                        titel: 'Stringplan ent. Generatorenfläche',
                                    }, {
                                        name: 'dCUberstannungsschutz',
                                        titel: 'DC Überstannungsschutz',
                                    }
                                ]}
                                defaultFiles={tabFiles}
                                defaultTab={'ziegelbearbeitungMontageDachhaken'}
                                callBackData={setCallBackData}
                            />
                        </SoftBox>
                    </Card>
                </SoftBox>

                <SoftBox my={2}>
                    <Card sx={{ overflow: "visible" }}>
                        <SoftBox p={3}>
                            <SoftTypography variant="h5">{t(' Skizze/Modulbelegung')}</SoftTypography>
                        </SoftBox>

                        <SignaturePad options={{ minWidth: 1, maxWidth: 1, penColor: 'rgb(0, 0, 0)' }} ref={signaturePadRef} />


                        {canEdit ?
                            <Grid container px={2}>
                                <Grid item sx={12} md={12} display="flex" justifyContent="center">
                                    <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRef.current.clear(); }}>
                                        <i className="fa fa-eraser"></i>
                                    </button>
                                </Grid>
                            </Grid>
                            : ''}
                    </Card>
                </SoftBox>


                {canEdit ? (
                    <>
                        <SoftBox>
                            <a href="#" className="btn btn-danger mx-2 d-none">
                                {t('Cancel')}
                            </a>

                            {!saving && (
                                <button id="dcAbname" type="submit" className="btn btn-primary">
                                    {t('Save')}
                                </button>
                            )}
                            {saving && (
                                <button type="button" className="btn btn-secondary" disabled>
                                    {t('wird geladen...')}
                                </button>
                            )}
                        </SoftBox>
                    </>
                ) : <></>}
            </form>

            {renderSuccessSB}
        </>
    )
}

export default DCabnahmeEdit