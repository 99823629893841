import React, { useMemo, useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { callFetch } from "helpers/callFetch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { GoogleMap, useJsApiLoader, DirectionsRenderer, useLoadScript, Marker, InfoWindow, LoadScript, DirectionsService } from '@react-google-maps/api';


import ACImg from "./icon/AC.png"
import AC_2Img from "./icon/AC_2.png"
import DCImg from "./icon/DC.png"
import DC_2Img from "./icon/DC_2.png"

function ProjectPlannedTechnicianLocation(props) {
    const { t } = useTranslation();
    const [project, setProject] = useState(null);
    const [center, setCenter] = useState('');
    const [miltarbeiter, setMiltarbeiter] = useState([]);
    //Direction
    const [response, setResponse] = useState(null);
    const [zoom, setZoom] = useState(12);
    const [directionsCallbackCount, setDirectionsCallbackCount] = useState(0);

    const libraries = ['geometry', 'drawing']
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries
    })

    const options = {
        disableDefaultUI: true,
        zoomControl: true,
        gestureHandling: 'cooperative',
    }

    useEffect(() => {

        if(props?.data?.distance > 0) {            
            setMiltarbeiter(props?.data?.technician_location);
            setProject(props?.data?.project_location);
            
            setCenter({
                lat: Number(props?.data.project_location.lat),
                lng: Number(props?.data.project_location.lng),
            });
            setDirectionsCallbackCount(0);
        }
        
    }, [props?.data?.distance]);


    const directionsCallback = (response) => {
        console.log(response)
        if (response !== null && directionsCallbackCount < 1) {
            if (response.status === 'OK') {
                setDirectionsCallbackCount(directionsCallbackCount + 1);
                setResponse(response)
            } else {
                setDirectionsCallbackCount(0);
                console.log('response: ', response)
            }
        }
    }

    if (loadError) return "Error loading maps"
    if (!isLoaded) return "Loading Maps"

    return (
        <>


            <div className="row">
                <div className={"col-lg-12"}>
                    {center ? (
                        <>
                            <GoogleMap
                                id='direction-nearestUser'
                                mapContainerStyle={{ height: '470px', width: '100%', borderRadius: '7px' }}
                                mapContainerClassName={'googleMapContainer'}
                                zoom={zoom}
                                center={center}
                                options={options}
                                onClick={(e) => {
                                    //onMapClick(e);
                                    //setActiveMarkers(null);
                                }}
                            >
                                {directionsCallbackCount < 1 && miltarbeiter?.street && project?.street ? (
                                    <DirectionsService
                                        // required
                                        options={{
                                            destination: (miltarbeiter?.street + ' ' + miltarbeiter?.nr + ', ' + miltarbeiter?.ort + ', Germany'),
                                            origin: (project?.street + ' ' + project?.nr + ', ' + project?.ort + ', Germany'),
                                            travelMode: 'DRIVING', //DRIVING, WALKING, BICYCLING, TRANSIT
                                        }}
                                        // required
                                        callback={(result) => {
                                            directionsCallback(result);
                                        }}
                                        // optional
                                        onLoad={directionsService => {
                                            //console.log('DirectionsService onLoad directionsService: ', directionsService)
                                        }}
                                        // optional
                                        onUnmount={directionsService => {
                                            //console.log('DirectionsService onUnmount directionsService: ', directionsService)
                                        }}
                                    />
                                ) : ''}

                                {
                                    response !== null && (
                                        <DirectionsRenderer
                                            // required
                                            options={{
                                                directions: response,
                                                //draggable: true,
                                            }}
                                            // optional
                                            onLoad={directionsRenderer => {
                                                //console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
                                            }}
                                            // optional
                                            onUnmount={directionsRenderer => {
                                                //console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
                                            }}
                                        />
                                    )
                                }
                            </GoogleMap>
                        </>
                    ) : 'Loading...'}
                </div>
            </div >
        </>
    );
}

export default ProjectPlannedTechnicianLocation;
