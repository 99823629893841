import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import ReclamationShow from "./ReclamationShow";
import dateFormat from "dateformat";
import SoftBadge from "components/SoftBadge";

function IndexTable(props) {
    const { t } = useTranslation();
    const [reclamations, setReclamations] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(1);
    const [id, setId] = useState(0);
    const [projectId, setProjectId] = useState(0);
    const [editId, setEditId] = useState(0);

    const tableHeadings = [
        {
            name: t('Reklamation'),
            width: '180px',
            sortable: true,
            selector: row => <NavLink to={'/reclamation/' + row.id + '/view/' + row.project_nr}>R-{row.id}</NavLink>
        },
        {
            name: t('Beschreibung'),
            width: '180px',
            sortable: true,
            selector: row => (
                <>
                    {row.title}
                </>
            )
        },
        {
            name: t('Projekt'),
            sortable: true,
            selector: row => <NavLink to={'/project-management/project/edit/' + row.project_nr} className="text-primary">{row.project_nr}</NavLink>
        },
        {
            name: t('Client Name'),
            sortable: true,
            selector: row => row?.project?.vorname + ' ' + (row?.project?.name ? row?.project?.name : '')
        },
        {
            name: t('Start Date'),
            sortable: true,
            selector: row => dateFormat(row?.start_date, "dd.mm.yyyy"),
        },
        {
            name: t('End Date'),
            sortable: true,
            selector: row => dateFormat(row?.end_date, "dd.mm.yyyy"),
        },
        {
            name: t('Status'),
            sortable: true,
            selector: row => (row?.status == 1 ? <SoftBadge variant="contained" color="success" size="xs" badgeContent={t('Erledigt')} container /> : <SoftBadge variant="contained" color="error" size="xs" badgeContent={t('Open')} container />)
        },
        {
            name: t('Department'),
            sortable: true,
            selector: row => row.department
        },
        {
            name: t('Actions'),
            cell: row => (
                <>
                    {Cookies.get('permissions').indexOf("reclamation-update") !== -1 ||
                        Cookies.get('permissions').indexOf("reclamation-delete") !== -1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu">
                                {Cookies.get('permissions').indexOf("reclamation-update") !== -1 ? (
                                    <li>
                                        <a
                                            className="dropdown-item"
                                            onClick={() => {
                                                setEditId(row.id);
                                            }}
                                        >
                                            {t('Edit')}
                                        </a>
                                    </li>
                                ) : <></>}
                                <li><hr className="dropdown-divider" /></li>
                                {Cookies.get('permissions').indexOf("reclamation-delete") !== -1 ? (
                                    <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'reclamations', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                                ) : <></>}
                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {
        callFetch("reclamations?page=" + pageNumber, "GET", []).then((res) => {
            setReclamations(res.data);
            //console.log(res.data);
        });
    }, [pageNumber, refresh, props?.refresh]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = reclamations.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === reclamations.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {reclamations.from} {t('to')} {reclamations.to} {t('of')} {reclamations.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return (
        <>
            <DataTable
                columns={tableHeadings}
                data={reclamations.data}
                noDataComponent={t('There are no records to display')}
                pagination
                highlightOnHover
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationServer
                paginationTotalRows={reclamations.total}
                onChangePage={handlePageChange}
                paginationComponent={BootyPagination}
            />
        </>
    );
}

export default IndexTable;
