import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftEditor from "components/SoftEditor";

function ComisioningModal(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [comisioning, setComisioning] = useState('0');
    const [description, setDescription] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [totalEmails, setTotalEmails] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const descriptionRef = useRef(null);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        unregister,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        if (props.projectid !== undefined) {
            //console.log(props.type);
            if (props.type == 'dc') {
                callFetch("dc_abnahmeprotokoll/" + props.projectid + "/edit", "GET", []).then((res) => {
                    setComisioning(res?.data?.dcabname?.comisioning_status);
                    setValue('description', res?.data?.dcabname?.comisioning_status_description && res?.data?.dcabname?.comisioning_status_description != 'undefined' ? res?.data?.dcabname?.comisioning_status_description : '');
                })
            } else {
                callFetch("ac_abnahmeprotokoll/" + props.projectid + "/edit", "GET", []).then((res) => {
                    setComisioning(res?.data?.dcabname?.comisioning_status);
                    setValue('description', res?.data?.dcabname?.comisioning_status_description && res?.data?.dcabname?.comisioning_status_description != 'undefined' ? res?.data?.dcabname?.comisioning_status_description : '');
                })
            }
        }

    }, [props.projectid, props.type]);

    const onSubmit = (formData) => {
        formData.acdcid = props.id;
        formData.type = props.type;
        setSaving(true);
        callFetch("comisioning-status", "POST", formData, setError).then((res) => {
           
            document.getElementById('ComisioningModalClose').click();
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    const descriptionStatus = (status) => {
        if (status == '2') {
            document.getElementById('commissioning').style.display = 'none';
            setComisioning(1);
        } else {
            document.getElementById('commissioning').style.display = 'block';
            setComisioning(2);
        }
    }



    return (
        <>




            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <input type="hidden" {...register('id')} value={props.projectid} />



                <a href="#" data-bs-toggle="modal" id="ComisioningModalOpen" data-bs-target="#ComisioningModal" className="btn btn-outline-secondary px-5 d-none">ComisioningModal</a>

                <div className="modal fade" id="ComisioningModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{t(props?.type == 'dc' ? 'Dc Commissioning Successfully Done' : 'Ac Commissioning Successfully Done')}?</h5>
                                <button
                                    type="button"
                                    className="btn-close text-dark"
                                    id="ComisioningModalClose"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="d-flex align-items-center justify-content-between mt-2" >
                                        <div className="d-flex align-items-center">
                                            <label className="mt-2" >{t('Yes')} &nbsp;&nbsp;&nbsp;</label>
                                            <div className="form-check d-inline-block">
                                                {comisioning == '1' ?
                                                    <input checked
                                                        type="radio"
                                                        {...register("commissioning")}
                                                        className="form-check-input me-md-3"
                                                        value={1}
                                                        onClick={() => descriptionStatus(2)} /> : <input
                                                        type="radio"
                                                        {...register("commissioning")}
                                                        className="form-check-input me-md-3"
                                                        value={1}
                                                        onClick={() => descriptionStatus(2)} />
                                                }
                                            </div>

                                            <label className="mt-2" >{t('No')} &nbsp;&nbsp;&nbsp;</label>
                                            <div className="form-check d-inline-block">
                                                {comisioning == '2' ?
                                                    <input checked
                                                        type="radio"
                                                        {...register("commissioning")}
                                                        className="form-check-input me-md-7"
                                                        value={2}
                                                        onClick={() => descriptionStatus(1)} /> : <input
                                                        type="radio"
                                                        {...register("commissioning")}
                                                        className="form-check-input me-md-7"
                                                        value={2}
                                                        onClick={() => descriptionStatus(1)} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="invalid-feedback">{errors.erdungsanschluss && errors.erdungsanschluss.message}</div>

                                </div>

                                {comisioning == '2' ?
                                    <div id="commissioning">
                                        <label>{t('Description')}</label>
                                        <textarea className="form-control" {...register('description')}>

                                        </textarea>
                                    </div>
                                    : <div id="commissioning" style={{ display: 'none' }}>
                                        <label>{t('Description')}</label>
                                        <textarea className="form-control" {...register('description')}>

                                        </textarea></div>}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-outline-dark btn-start mt-2"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    {t("Cancel")}
                                </button>
                                {!saving && (
                                    <button
                                        type="submit"
                                        className="btn"
                                        style={{
                                            background: '#d63384',
                                            color: '#ffffff'
                                        }}
                                    >
                                        {t('Bestätigen')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Bestätigen ...')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>


            </form>
        </>
    );
}

export default ComisioningModal;
