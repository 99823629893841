import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import SoftBadge from "components/SoftBadge";
import Tooltip from "@mui/material/Tooltip";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import Divider from "@mui/material/Divider";

function IndexTable(props) {
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);

    const tableHeadings = [
        {
            name: t('Ticket'),
            selector: row => (
                <NavLink to={'/ticket/' + row.id}>T-{row.id}</NavLink>
            ),
            sortable: true,
        },
        {
            name: t('Beschreibung'),
            width: '180px',
            sortable: true,
            selector: row => (
                <>
                    {row.title}
                </>
            )
        },
        {
            name: t('Users'),
            width: '180px',
            sortable: true,
            cell: row => (
                <>
                    <SoftBox display="flex" alignItems="center">
                        <SoftBox mt={0.5} pl={1}>
                            <SoftBox className="ticket-avator" display="flex">

                                {row?.employees && JSON.parse(row?.employees).map((employee) => (
                                    <Tooltip title={employee?.user?.name} placement="top">
                                        <SoftAvatar src={employee?.user?.photo ? process.env.REACT_APP_STORAGE_URL + employee?.user?.photo : '/assets/img/placeholder.png'} alt="" size="xs" sx={{
                                            border: ({ borders: { borderWidth }, palette: { white } }) =>
                                                `${borderWidth[2]} solid ${white.main}`,
                                            cursor: "pointer",
                                            position: "relative",
                                            ml: -1.5,

                                            "&:hover, &:focus": {
                                                zIndex: "10",
                                            },
                                        }} style={{ height: 'auto !important' }} />
                                    </Tooltip>
                                ))}
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>
                </>
            )
        },
        {
            name: t('Projekt'),
            sortable: true,
            selector: row => <NavLink to={'/project-management/project/edit/' + row.project_id} className="text-primary">{row.project_id}</NavLink>
        },
        {
            name: t('Client Name'),
            sortable: true,
            selector: row => row?.project?.vorname + ' ' + (row?.project?.name ? row?.project?.name : '')
        },
        {
            name: t('Start Date'),
            sortable: true,
            selector: row => dateFormat(row?.start_date, "dd.mm.yyyy"),
        },
        {
            name: t('End Date'),
            sortable: true,
            selector: row => dateFormat(row?.end_date, "dd.mm.yyyy"),
        },
        {
            name: t('Status'),
            sortable: true,
            selector: row => (row?.status == 1 ? <SoftBadge variant="contained" color="success" size="xs" badgeContent={t('Erledigt')} container /> : <SoftBadge variant="contained" color="error" size="xs" badgeContent={t('Open')} container />)
        },
        {
            name: t('Department'),
            sortable: true,
            selector: row => row.department
        }
    ];

    useEffect(() => {
        callFetch("ticket?page=" + pageNumber, "GET", []).then((res) => {
            setTableData(res.data);
        });
    }, [pageNumber, refresh, props?.refresh]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <DataTable
        columns={tableHeadings}
        data={tabledata?.data}
        noDataComponent={t('There are no records to display')}
        className='data-table'
        pagination
        highlightOnHover
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationServer
        paginationTotalRows={tabledata?.total}
        onChangePage={handlePageChange}
        paginationComponent={BootyPagination}
    />;
}

export default IndexTable;
