import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import SoftBadge from "components/SoftBadge";
import SoftBadgeDot from "components/SoftBadgeDot";
import { NumericFormat } from 'react-number-format';
import SoftTypography from "components/SoftTypography";
import { Grid, Menu } from "@mui/material";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { number } from 'prop-types';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';

function IndexTableProject(props) {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [deleteButton, setDeleteButton] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [searchKey, setSearchKey] = useState("")
    const [openMenu, setOpenMenu] = useState(null);
    const [assignedDateTimeUser, setAssignedDateTimeUser] = useState([]);
    const [project_Data, setProjectData] = useState([]);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(null);
    const [autocomplete, setAutocomplete] = useState({});

    const libraries = ["places"]
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries
    })

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        setDeleteButton(false);
        if (getValues('selectedrow')) {
            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                if (selectedrow_value) {
                    setDeleteButton(true);
                }
            }
        }

    }, [refresh2]);

    const verkauferFeatch = () => {
        //callFetch("projects/create?date=" + getValues('date') + '&time=' + getValues('time'), "GET", []).then((res) => {
        callFetch("projects/create?date=&time=", "GET", []).then((res) => {
            setProjectData(res.data);
        });
    }

    const deleteSelectedItem = () => {
        setDeleting(true);
        var selectedValues = [];
        for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
            if (selectedrow_value) {
                selectedValues.push(selectedrow_key);
            }
        }

        let formData = {
            ids: JSON.stringify(selectedValues),
            type: props.type,
        };

        callFetch("import/delete", "POST", formData, setError).then((res) => {
            if (res.message == 'success') {
                setDeleting(false);
                setRefresh(refresh + 1);
                setRefresh2(refresh + 1);
            }
        })
    }


    const validateDateTimeVerkaufer = (row_id = 0) => {
        reset({});

        setTimeout(() => {
            var dateTimeUserData = [];
            console.log('Line 103');
            console.log(getValues('verkaufer'));
            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('verkaufer'))) {
                callFetch("projects/create?date=" + getValues("date[" + selectedrow_key + "]") + '&time=' + getValues("time[" + selectedrow_key + "]"), "GET", []).then((res) => {
                    var available_employees = [];
                    for (let [available_employee_key, available_employee_value] of Object.entries(res?.data?.employees)) {
                        available_employees.push(available_employee_value?.user?.id);
                    }

                    var dateTimeUser = getValues("date[" + selectedrow_key + "]") + '_' + getValues("time[" + selectedrow_key + "]") + '_' + getValues("verkaufer[" + selectedrow_key + "]");
                    if (available_employees.indexOf(Number(getValues("verkaufer[" + selectedrow_key + "]"))) !== -1) {
                        if (dateTimeUserData.indexOf(dateTimeUser) !== -1) {
                            setValue("verkaufer[" + selectedrow_key + "]", '');
                        } else {
                            dateTimeUserData.push(dateTimeUser);
                        }
                    } else {
                        setValue("verkaufer[" + selectedrow_key + "]", '');
                    }

                });
            }

        }, 300)
    }

    const onSubmitSave = () => {
        setSaving(true);
        reset({});

        setTimeout(() => {
            var formData = {
                filename: props.filename,
            }
            callFetch("import/" + props.type + "/save", "POST", formData, setError).then((res) => {
                if (res.message == 'success') {
                    setRefresh(refresh + 1);
                    setRefresh2(refresh + 1);
                    document.getElementById('outputModal-button-close').click();
                    setSaving(false);
                    window.location.reload();
                }
            });
        }, 1000)
    }


    const onSubmit = (formData) => {
        onSubmitSave();
    };

    const updateFileData = (formData) => {
        formData.type = props.type;
        setLoading(true);
        callFetch("import/update", "POST", formData, setError).then((res) => {
            if (res.message == 'success') {
                setLoading(false);
                //setRefresh(refresh+1);
                // setRefresh2(refresh+1);
                //document.getElementById('outputModal-button-close').click();
                //window.location.reload();
            }
        });
    }

    const onLoad = (data, id) => {
        var update_autocomplete = autocomplete;
        update_autocomplete[id] = data;
        setAutocomplete(update_autocomplete);
    }

    const onPlaceChanged = (id) => {
        if (autocomplete !== null) {
            var place = autocomplete[id].getPlace();
            setValue('nr[' + id + ']', '');
            setValue('plz[' + id + ']', '');
            setValue('ort[' + id + ']', '');
            setValue('street[' + id + ']', '');

            if (place?.address_components) {
                for (let [key, value] of Object.entries(place?.address_components)) {
                    if (value?.types) {
                        if (value?.types.indexOf("postal_code") !== -1) {
                            setValue('plz[' + id + ']', value?.long_name);
                            updateFileData({ id: id, plz: value?.long_name });
                        } else if (value?.types.indexOf("street_number") !== -1) {
                            setValue('nr[' + id + ']', value?.long_name);
                            updateFileData({ id: id, nr: value?.long_name });
                        } else if (value?.types.indexOf("locality") !== -1) {
                            setValue('ort[' + id + ']', value?.long_name);
                            updateFileData({ id: id, ort: value?.long_name });
                        } else if (value?.types.indexOf("route") !== -1) {
                            setValue('street[' + id + ']', value?.long_name);
                            updateFileData({ id: id, street: value?.long_name });
                        }
                    }
                }
            }
            setValue('lat[' + id + ']', place.geometry.location.lat());
            setValue('lng[' + id + ']', place.geometry.location.lng());

            updateFileData({ id: id, lat: place.geometry.location.lat() });
            updateFileData({ id: id, lng: place.geometry.location.lng() });

        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }

    const tableHeadings = [
        {
            //name: t('Project Nr.'),
            name: t(''),
            width: '20px',
            cell: row => (
                <>
                    <input
                        {...register("selectedrow[" + row.id + "]", {
                            checked: true / false,
                            onChange: (e) => {
                                setRefresh2(refresh2 + 1);
                            }
                        })}
                        //checked={getValues("selectedrow["+row.id+"]") == row.id ? true : false }
                        type="checkbox" />
                    &nbsp;
                    {/*'# '+row.id*/}
                </>),
            sortable: true,
        },
        {
            name: t('Date'),
            width: '150px',
            cell: row => (<>
                <input
                    className="form-control"
                    className="form-control"
                    {...register("date[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                date: e.target.value
                            });
                            validateDateTimeVerkaufer(row.id);
                        }
                    })}
                    defaultValue={row.date}
                    required
                    type="date" id="example-date-input" />
            </>),
        },
        {
            name: t('Time'),
            width: '150px',
            cell: row => (
                <select
                    className="form-control"
                    {...register("time[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                time: e.target.value
                            });
                            validateDateTimeVerkaufer(row.id);
                        }
                    })}
                    required
                    defaultValue={row.time}
                >
                    <option value="">--</option>
                    <option value="08:00">08:00 - 10:00</option>
                    <option value="10:00">10:00 - 12:00</option>
                    <option value="12:00">12:00 - 14:00</option>
                    <option value="14:00">14:00 - 16:00</option>
                    <option value="16:00">16:00 - 18:00</option>
                    <option value="18:00">18:00 - 20:00</option>
                </select>
            ),
        },
        {
            name: t('Verkaufer'),
            width: '150px',
            cell: row => (<>
                <select
                    className="form-control"
                    {...register("verkaufer[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                verkaufer: e.target.value
                            });
                            validateDateTimeVerkaufer(row.id);
                        }
                    })}
                    required
                    defaultValue={row.verkaufer}
                >
                    <option value="">--</option>
                    {project_Data && project_Data.employees ? project_Data.employees.map((employee) => (
                        <option key={employee.user.id} value={employee.user.id}>{employee.user.name}</option>
                    )) : <></>}
                </select>
            </>),
        },
        {
            name: t('Beschreibung'),
            width: '350px',
            cell: row => (<>
                <textarea
                    defaultValue={row.description}
                    className="form-control"
                    rows="2"
                    placeholder={t('z.B. Beschreibung über den Kunden...')}
                    {...register("description[" + row.id + "]", {
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                description: e.target.value
                            });
                        }
                    })}></textarea>
            </>),
        },
        {
            name: t('Anrede'),
            width: '150px',
            cell: row => <select
                className="form-control"
                {...register("anrede[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            anrede: e.target.value
                        });
                    }
                })}
                defaultValue={row.anrede}
                required>
                <option value="">--</option>
                <option value="Herr">{t('Herr')}</option>
                <option value="Frau">{t('Frau')}</option>
            </select>,
        },
        {
            name: t('Vorname'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("vorname[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            vorname: e.target.value
                        });
                    }
                })}
                defaultValue={row.vorname}
                required
            />,
        },
        {
            name: t('Name'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("name[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            name: e.target.value
                        });
                    }
                })}
                defaultValue={row.name}
                required
            />,
        },
        {
            name: t('Straße'),
            width: '280px',
            cell: row => (
                <>
                    <div
                        className='w-100'
                    >
                        <Autocomplete
                            options={{
                                componentRestrictions: { country: "de" },
                                //fields: ["address_components", "geometry", "icon", "name"],
                                //types: ["establishment"]
                            }}
                            onLoad={(autocomplete) => {
                                onLoad(autocomplete, row.id);
                            }}
                            onPlaceChanged={() => {
                                onPlaceChanged(row.id);
                            }}
                            className='w-100'
                            onUnmount={(autocomplete) => {
                                console.log('onUnmount');

                                console.log(autocomplete)
                            }}
                        >
                            <input
                                type="text"
                                placeholder={t('')}
                                /*
                                className={!getValues("street[" + row.id + "]") ||
                                    !getValues("nr[" + row.id + "]") ||
                                    !getValues("lat[" + row.id + "]") ||
                                    !getValues("lng[" + row.id + "]") ?
                                    "form-control border-danger"
                                    : "form-control"
                                }
                                */
                                className={"form-control"}
                                {...register("full_address[" + row.id + "]", {
                                    required: false,
                                })}
                                defaultValue={row.street + ' ' + (row.nr && row.nr != 'null' ? row.nr : '')}
                            />
                        </Autocomplete>
                        <div className='d-none'>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('')}
                                {...register("street[" + row.id + "]", {
                                    required: false,
                                    onChange: (e) => {
                                        updateFileData({
                                            id: row.id,
                                            street: e.target.value
                                        });
                                    }
                                })}
                                defaultValue={row.street}
                                required
                            />

                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('')}
                                {...register("nr[" + row.id + "]", {
                                    required: false,
                                    onChange: (e) => {
                                        updateFileData({
                                            id: row.id,
                                            nr: e.target.value
                                        });
                                    }
                                })}
                                defaultValue={row.nr}
                                required
                            />

                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('')}
                                {...register("lat[" + row.id + "]", {
                                    required: false,
                                    onChange: (e) => {
                                        updateFileData({
                                            id: row.id,
                                            lat: e.target.value
                                        });
                                    }
                                })}
                                defaultValue={row.lat}
                                required
                            />
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('')}
                                {...register("lng[" + row.id + "]", {
                                    required: false,
                                    onChange: (e) => {
                                        updateFileData({
                                            id: row.id,
                                            lng: e.target.value
                                        });
                                    }
                                })}
                                defaultValue={row.lng}
                                required
                            />
                        </div>
                    </div>
                </>
            )

            ,
        },
        /*
        {
            name: t('Nr'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("nr[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            nr: e.target.value
                        });
                    }
                })}
                defaultValue={row.nr}
                required
            />,
        },
        */
        {
            name: t('PLZ'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("plz[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            plz: e.target.value
                        });
                    }
                })}
                defaultValue={row.plz}
                required
            />,
        },
        {
            name: t('Ort'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("ort[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            ort: e.target.value
                        });
                    }
                })}
                defaultValue={row.ort}
                required
            />,
        },
        {
            name: t('Telefonnummer'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("telefonnummer[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            telefonnummer: e.target.value
                        });
                    }
                })}
                defaultValue={row.telefonnummer}
                required
            />,
        },
        {
            name: t('Mobilnummer'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("mobilnummer[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            mobilnummer: e.target.value
                        });
                    }
                })}
                defaultValue={row.mobilnummer}
            />,
        },
        {
            name: t('Entscheidungstrager'),
            width: '150px',
            cell: row => (
                <>
                    <input
                        defaultValue={row.entscheidungstrager}
                        type="text"
                        placeholder="Entscheidungstrager"
                        className=" form-control"
                        {...register("entscheidungstrager[" + row.id + "]", {
                            required: false,
                            onChange: (e) => {
                                updateFileData({
                                    id: row.id,
                                    entscheidungstrager: e.target.value
                                });
                            }
                        })}
                    />
                </>
            ),
        },
        {
            name: t('Gesamtverbrauch Kwh'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('Gesamtverbrauch Kwh')}
                {...register("gesamtverbrauch_kwh[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            gesamtverbrauch_kwh: e.target.value
                        });
                    }
                })}
                defaultValue={row.gesamtverbrauch_kwh}
            />,
        },
        {
            name: t('Monatlicher Abschlag In Euro'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('Monatlicher Abschlag In Euro')}
                {...register("monatlicher[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            monatlicher: e.target.value
                        });
                    }
                })}
                defaultValue={row.monatlicher}
            />,
        },
        {
            name: t('Stromabrechung Vorhanden'),
            width: '100px',
            cell: row => <select className="form-control"
                {...register("stromabrechung_vorhanden[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            stromabrechung_vorhanden: e.target.value
                        });
                    }
                })}
                defaultValue={row.stromabrechung_vorhanden}
            >
                <option>--</option>
                <option value="Yes" >{t('Yes')}</option>
                <option value="No" >{t('No')}</option>
            </select>,
        },
        {
            name: t('Dachausrichtung'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("dachausrichtung[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            dachausrichtung: e.target.value
                        });
                    }
                })}
                defaultValue={row.dachausrichtung}

            />,
        },
        {
            name: t('Ziegelart'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('Ziegelart')}
                {...register("ziegelart[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            ziegelart: e.target.value
                        });
                    }
                })}
                defaultValue={row.ziegelart}
            />,
        },
        {
            name: t('E-Mobilitat'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('E-Mobilitat')}
                {...register("e_mobilitat[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            e_mobilitat: e.target.value
                        });
                    }
                })}
                defaultValue={row.e_mobilitat}

            />,
        },
        {
            name: t('Zeitraum (max.)'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('Zeitraum (max.)')}
                {...register("zeitraum_max[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            zeitraum_max: e.target.value
                        });
                    }
                })}
                defaultValue={row.zeitraum_max}
            />,
        },
        {
            name: t('Internet'),
            width: '100px',
            cell: row => <select
                className="form-control"
                {...register("internet[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            internet: e.target.value
                        });
                    }
                })}
                defaultValue={row.internet}
            >
                <option value="">--</option>
                <option value="Yes">{t('Yes')}</option>
                <option value="No">{t('No')}</option>
            </select>,
        },
        {
            name: t('Erfahrung Mit PV'),
            width: '100px',
            cell: row => <select
                className="form-control"
                {...register("erfahrung_mit_pv[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            erfahrung_mit_pv: e.target.value
                        });
                    }
                })}
                defaultValue={row.erfahrung_mit_pv}
            >
                <option value="">--</option>
                <option value="Yes">{t('Yes')}</option>
                <option value="No">{t('No')}</option>
            </select>,
        }
    ];

    useEffect(() => {
        verkauferFeatch();
        if (!props.filename.length) return;

        if (props?.filename) {
            callFetch("import-data/" + props?.filename + '/' + props?.type + "?page=" + pageNumber, "GET", []).then((res) => {
                setTableData(res.data);
                validateDateTimeVerkaufer();
            });
        }


    }, [pageNumber, refresh, props.filename]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />


                {/**/}
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>

                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>

                <div className='text-center'>
                    <button className='btn btn-danger mx-1' onClick={() => {
                        document.getElementById('outputModal-button-close').click();
                    }}>{t('Cancel')}</button>

                    {loading == false ? (
                        <>
                            {!saving && (
                                <button type="submit" className="btn btn-primary">
                                    {t(props.type + ' upload')}
                                </button>
                            )}
                            {saving && (
                                <button type="submit" className="btn btn-disabled" disabled>
                                    {t(props.type + ' upload ...')}
                                </button>
                            )}
                        </>
                    ) : (
                        <button type="submit" className="btn btn-disabled" disabled>
                            {t('Loading ...')}
                        </button>
                    )}

                </div>
            </>
        );
    };


    if (loadError) return "Error loading maps"
    if (!isLoaded) return;// "Loading Maps"

    return <>
        <div className='px-3'>
            <div className="row">
                <div className="col-sm-1">
                    <input type="checkbox" className='mt-2' id='select_all' onChange={(e) => {
                        if (e.target.checked) {
                            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                setValue("selectedrow[" + selectedrow_key + "]", true)
                            }
                        } else {
                            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                setValue("selectedrow[" + selectedrow_key + "]", false)
                            }
                        }
                        setRefresh2(refresh2 + 1);
                    }} />
                    <label htmlFor="select_all">{t('Select All')}</label>
                </div>
                <div className="col-sm-3">
                    {loading == false ? (
                        <input
                            type="date"
                            className='form-control form-control-sm'
                            style={{ maxWidth: '150px' }}
                            onChange={(e) => {
                                for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                    if (selectedrow_value) {
                                        setValue("date[" + selectedrow_key + "]", e.target.value);
                                        updateFileData({
                                            id: selectedrow_key,
                                            date: e.target.value
                                        });
                                    }
                                }
                            }}
                        />
                    ) : (
                        <input
                            type="date"
                            className='form-control form-control-sm'
                            style={{ maxWidth: '150px' }}
                            disabled
                        />
                    )}

                </div>
                <div className="col-sm-8">
                    {deleteButton ? (
                        <>
                            {deleting ? (
                                <button className='btn btn-danger btn-sm mb-0 ms-3 float-end' disabled>{t('Deleting ...')}</button>

                            ) : (
                                <button className='btn btn-danger btn-sm mb-0 ms-3 float-end' onClick={() => {
                                    deleteSelectedItem();
                                }}>{t('Delete')}</button>
                            )}
                        </>
                    ) : (
                        <button className='btn btn-danger btn-sm mb-0 ms-3 float-end' disabled>{t('Delete')}</button>
                    )}
                </div>
            </div>

        </div>
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <DataTable
                columns={tableHeadings}
                data={tabledata?.data}
                noDataComponent={t('There are no records to display')}
                className='data-table'
                pagination
                highlightOnHover
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationServer
                paginationTotalRows={tabledata?.total}
                onChangePage={handlePageChange}
                paginationComponent={BootyPagination}
            />
        </form>
    </>
}

export default IndexTableProject;
