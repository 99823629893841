import { Suspense, useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
import routes from "routes";

import brand from "assets/images/logo-ct.png";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";


import 'react-toastify/dist/ReactToastify.css';
import ApplicationSettings from "./pages/ApplicationSettings";
import PrivateOutlet from "./components/PrivateOutlet";
import Signin from "./pages/Signin";
import RoleIndex from './pages/user_settings/role/RoleIndex';
import RoleEdit from './pages/user_settings/role/RoleEdit';
import RoleCreate from './pages/user_settings/role/RoleCreate';

import EmployeeCreate from './pages/hr/employee/EmployeeCreate';
import EmployeeIndex from './pages/hr/employee/EmployeeIndex';
import EmployeeEdit from './pages/hr/employee/EmployeeEdit';

import Dashboard from './pages/dashboard/Index'; 

import QuationIndex from './pages/quations/Index';
import QuationCreate from './pages/quations/Create';
import QuationEdit from './pages/quations/Edit';

import OrdersIndex from './pages/orders/Index';
import OrderCreate from './pages/orders/Create';
import OrderEdit from './pages/orders/Edit';

import ProductCreate from './pages/product/Create';

import ModuleIndex from './pages/module/Index';
import ModuleCreate from './pages/module/Create';
import ModuleEdit from './pages/module/Edit';

import SpeicherIndex from './pages/speicher/Index';
import SpeicherCreate from './pages/speicher/Create';
import SpeicherEdit from './pages/speicher/Edit';

import WechselrichterIndex from './pages/wechselrichter/Index';
import WechselrichterCreate from './pages/wechselrichter/Create';
import WechselrichterEdit from './pages/wechselrichter/Edit';

import ProjectIndex from './pages/project/ProjectIndex';
import ProjectCreate from './pages/project/Create';
import ProjectEdit from './pages/project/Edit';
import ProjectShow from './pages/project/ProjectShow';

import CallcenterCreate from './pages/callcenter/Create';
import CallcenterEdit from './pages/callcenter/Edit';
import CallcenterIndex from './pages/callcenter/Index';
import ChatIndex from './pages/chat/Index';
import ChatSendMessage from './pages/chat/ChatSendMessage';
import Message from './pages/chat/Message';
import ReclamationIndex from './pages/project/reclamation/Index';
import ReclamationShow from './pages/project/reclamation/ReclamationShow';

import TicketIndex from './pages/project/ticket/Index';
import TicketShow from './pages/project/ticket/Show';

import InvoiceIndex from './pages/finance/Invoice/Index';
import InvoiceCreate from './pages/finance/Invoice/Create';
import InvoiceAdd from './pages/finance/Invoice/Add';
import InvoiceEdit from './pages/finance/Invoice/Edit';

import PaymentIndex from './pages/finance/Payment/Index';
import PaymentCreate from './pages/finance/Payment/Create';
import BookPayment from './pages/finance/Payment/Book';

import PayrollIndex from './pages/finance/Payroll/Index';
import PayrollCreate from './pages/finance/Payroll/Create';
import PayrollEdit from './pages/finance/Payroll/Edit';

import PurchaseIndex from './pages/purchase/Index';
import StornoIndex from './pages/storno/Index';
import ResellerProjectIndex from './pages/reseller/project/Index';

import Teams from './pages/teams/Index';
import TeamsCreate from './pages/teams/Create';
import TeamsEdit from './pages/teams/Edit'; 


import Calendar from './pages/calendar/Index'; 

import Settings from './pages/settings/Index';
import EmployeesDashboard from "pages/dashboard/employees-dashboard/EmployeesDashboard";
import FieldServiceIndex from "pages/filed_service/Index";
import ArtikelIndex from "pages/finance/Artikel/ArtikelIndex";
import ArtikelCreate from "pages/finance/Artikel/ArtikelCreate";
import ArtikelEdit from "pages/finance/Artikel/ArtikelEdit";
import CustomerIndex from "pages/customer/CustomerIndex";
import CustomerCreate from "pages/customer/CustomerCreate";
import CustomerDetails from "pages/customer/CustomerDetails";

import SmtpIndex from './pages/email/smtp/SmtpIndex';
import SmtpCreate from './pages/email/smtp/SmtpCreate';
import SmtpEdit from './pages/email/smtp/SmtpEdit';

import AngebotEmail from './pages/email/emails/Angebot';
import OrderEmail from './pages/email/emails/Order';
import AcPlanningEmail from './pages/email/emails/AcPlanning';
import DcPlanningEmail from './pages/email/emails/DcPlanning';
import SeventyPercentInvoiceEmail from './pages/email/emails/SeventyPercentInvoice';
import ThirtyPercentInvoiceEmail from './pages/email/emails/ThirtyPercentInvoice';
import SeventyPercentPaymentEmail from './pages/email/emails/SeventyPercentPayment';
import ThirtyPercentPaymentEmail from './pages/email/emails/ThirtyPercentPayment';

import SupportIndex from './pages/support/Index';
import SupportCreate from './pages/support/Create';
import SupportEdit from './pages/support/Edit';
import SupportView from './pages/support/Show';

import OverviewIndex from "pages/analyses/Overview/OverviewIndex";
import ProductionEvulationIndex from "pages/analyses/ProductionEvulation/ProductionEvulationIndex";
import VkbEvulationIndex from "pages/analyses/VkbEvulation/VkbEvulationIndex";

import AcDcManagerIndex from "pages/documentation/AcDcManagerIndex";
import AcDcTechnikerIndex from "pages/documentation/AcDcTechnikerIndex";
import CallCenterIndex from "pages/documentation/CallCenterIndex";
import ManagementIndex from "pages/documentation/ManagementIndex";
import IndoorServiceIndex from "pages/documentation/IndoorServiceIndex";
import SalesManagerIndex from "pages/documentation/SalesManagerIndex";
import SalesPersonIndex from "pages/documentation/SalesPersonIndex";

import AfterConnect from "pages/ConnectSocial/AfterConnect";

import Report from "pages/reports/Index";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

    const configsButton = (
        <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.5rem"
            height="3.5rem"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{ cursor: "pointer" }}
            onClick={handleConfiguratorOpen}
        >
        <Icon fontSize="default" color="inherit">
            settings
        </Icon>
        </SoftBox>
    );

    return (
        <>
        {/*<CacheProvider value={rtlCache}>*/}
            <ThemeProvider theme={theme}>
                <CssBaseline />

                {/*
                  <Sidenav
                    color={sidenavColor}
                    brand={brand}
                    brandName="Soft UI Dashboard PRO"
                    routes={routes}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                  />
                */}

                <Routes>
                    <Route path="/" element={<Signin />} />
                    <Route path="/*" element={<PrivateOutlet />}>
                        <Route path="application-settings" element={<ApplicationSettings />} />

                        <Route path="dashboard" element={<Dashboard />} /> 
                        <Route path="profile/settings" element={<Settings />} /> 

                        <Route path="hr/employees" element={<EmployeeIndex />} />
                        <Route path="hr/employees/create" element={<EmployeeCreate />} />
                        <Route path="hr/employees/:id/edit" element={<EmployeeEdit />} />
                        <Route path="hr/employees/dashboard" element={<EmployeesDashboard/>} />
                                                
                        <Route path="field-service/overview" element={<EmployeesDashboard/>} />
                        <Route path="field-service/reports" element={<Report />} />
                        <Route path="field-service/:plantype/:status" element={<FieldServiceIndex/>} />

                        <Route path="sales-distribution/quations/:status" element={<QuationIndex />} />
                        <Route path="sales-distribution/quations/create" element={<QuationCreate />} />
                        <Route path="sales-distribution/quations/:id/edit" element={<QuationEdit />} />

                        <Route path="sales-distribution/customers" element={<CustomerIndex />} />
                        <Route path="sales-distribution/customers/create" element={<CustomerCreate />} />
                        <Route path="sales-distribution/customers/:id/details" element={<CustomerDetails />} />

                        <Route path="sales-distribution/orders" element={<OrdersIndex />} />
                        <Route path="sales-distribution/order/create" element={<OrderCreate />} />
                        <Route path="sales-distribution/order/:id/edit" element={<OrderEdit />} />

                        <Route path="supply-chain/products/create" element={<ProductCreate />} />
                        <Route path="supply-chain/products/module" element={<ModuleIndex />} />
                        <Route path="supply-chain/products/module/create" element={<ModuleCreate />} />
                        <Route path="supply-chain/products/module/:id/edit" element={<ModuleEdit />} />
                        
                        <Route path="supply-chain/products/speicher" element={<SpeicherIndex />} />
                        <Route path="supply-chain/products/speicher/create" element={<SpeicherCreate />} />
                        <Route path="supply-chain/products/speicher/:id/edit" element={<SpeicherEdit />} />

                        <Route path="reclamation/index" element={<ReclamationIndex />} />
                        <Route path="reclamation/:rID/view/:id" element={<ReclamationShow />} />

                        <Route path="ticket" element={<TicketIndex />} />
                        <Route path="ticket/:id" element={<TicketShow />} />

                        <Route path="supply-chain/products/wechselrichter" element={<WechselrichterIndex />} />
                        <Route path="supply-chain/products/wechselrichter/create" element={<WechselrichterCreate />} />
                        <Route path="supply-chain/products/wechselrichter/:id/edit" element={<WechselrichterEdit />} />

                        <Route path="project-management/projects" element={<ProjectIndex />} />
                        <Route path="project-management/project/create" element={<ProjectCreate />} />
                        <Route path="project-management/project/edit/:id" element={<ProjectEdit />} />
                        <Route path="project-management/project/edit/:id/:status" element={<ProjectEdit />} />
                        <Route path="project-management/project/:id" element={<ProjectShow />} />

                        <Route path="call-center/index/:status" element={<CallcenterIndex />} />
                        <Route path="call-center/create" element={<CallcenterCreate />} />
                        <Route path="call-center/:id/edit" element={<CallcenterEdit />} />

                        <Route path="finance/invoices" element={<InvoiceIndex />} />
                        <Route path="finance/invoices/create" element={<InvoiceCreate />} />
                        <Route path="finance/invoices/add/:id" element={<InvoiceAdd />} />
                        <Route path="finance/invoices/:id/edit" element={<InvoiceEdit />} />

                        <Route path="finance/payrolls" element={<PayrollIndex />} />
                        <Route path="finance/payrolls/create" element={<PayrollCreate />} />
                        <Route path="finance/payrolls/:id/edit" element={<PayrollEdit />} />

                        <Route path="finance/artikels" element={<ArtikelIndex/>} />
                        <Route path="finance/artikels/create" element={<ArtikelCreate/>} />
                        <Route path="finance/artikels/:id/edit" element={<ArtikelEdit/>} />


                        <Route path="finance/payments" element={<PaymentIndex />} />
                        <Route path="finance/payments/book/payment" element={<BookPayment />} />
                        <Route path="finance/payments/:id/add_payment" element={<PaymentCreate />} />

                        <Route path="purchase-management/purchase" element={<PurchaseIndex />} />
                        <Route path="reseller/storno" element={<StornoIndex />} />
                        <Route path="reseller/project" element={<ResellerProjectIndex />} />

                        <Route path="settings/roles" element={<RoleIndex />} />
                        <Route path="settings/roles/create" element={<RoleCreate />} />
                        <Route path="settings/roles/:id/edit" element={<RoleEdit />} />

                        <Route path="settings/smtp" element={<SmtpIndex />} />
                        <Route path="settings/smtp/create" element={<SmtpCreate />} />
                        <Route path="settings/smtp/:id/edit" element={<SmtpEdit />} />

                        <Route path="settings/emails/angebot/:id/edit" element={<AngebotEmail />} />
                        <Route path="settings/emails/order/:id/edit" element={<OrderEmail />} />
                        <Route path="settings/emails/ac-planning/:id/edit" element={<AcPlanningEmail />} />
                        <Route path="settings/emails/dc-planning/:id/edit" element={<DcPlanningEmail />} />
                        <Route path="settings/emails/70-percent-invoice/:id/edit" element={<SeventyPercentInvoiceEmail />} />
                        <Route path="settings/emails/30-percent-invoice/:id/edit" element={<ThirtyPercentInvoiceEmail />} />
                        <Route path="settings/emails/70-percent-payment/:id/edit" element={<SeventyPercentPaymentEmail />} />
                        <Route path="settings/emails/30-percent-payment/:id/edit" element={<ThirtyPercentPaymentEmail />} />

                        <Route path="chat" element={<ChatIndex />} />
                        <Route path="chat/send-message" element={<ChatSendMessage />} />
                        <Route path="chat/message/:id" element={<Message />} />

                        <Route path="teams/:type" element={<Teams />} />
                        <Route path="teams/create" element={<TeamsCreate />} />
                        <Route path="teams/:id/edit" element={<TeamsEdit />} />

                        <Route path="support" element={<SupportIndex />} />
                        <Route path="support/create" element={<SupportCreate />} />
                        <Route path="support/:id/edit" element={<SupportEdit />} />
                        <Route path="support/:id/view" element={<SupportView />} />

                        <Route path="calendar" element={<Calendar />} /> 


                        <Route path="analyses-overview" element={<OverviewIndex />} />
                        <Route path="analyses-vkb-evaluation" element={<VkbEvulationIndex />} />
                        <Route path="analyses-production-evaluation" element={<ProductionEvulationIndex />} />

                        
                        {/*Support Videos */}
                        <Route path="acdc-manager" element={<AcDcManagerIndex />} />
                        <Route path="acdc-techniker" element={<AcDcTechnikerIndex />} />
                        <Route path="call-center" element={<CallCenterIndex />} />
                        <Route path="management" element={<ManagementIndex />} />
                        <Route path="indoor-service" element={<IndoorServiceIndex />} />
                        <Route path="sales-manager" element={<SalesManagerIndex />} />
                        <Route path="sales-person" element={<SalesPersonIndex />} />

                        <Route path="connect/success" element={<AfterConnect />} />
                        
                    </Route>
                </Routes>

               {/* <Configurator />
                {configsButton}*/}
                
            </ThemeProvider>
        {/*</CacheProvider>*/}
    </>
    );
}
