import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import flatpickr from "flatpickr";

import SoftBox from "components/SoftBox";
import Grid from "@mui/material/Grid";

// @mui material components
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import EmailForm from "../EmailForm";
import Timeline from "../Timeline";
import PDF from "./PDF";

import { NumericFormat } from 'react-number-format';
import SignaturePad from 'react-signature-pad-wrapper'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SoftSnackbar from "components/SoftSnackbar";

function getSteps() {
    return ["1. Quotation", "2. Order", "3. Digital Sign"];
}

function Order(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [showPadClient, setShowPadClient] = useState(true);
    const [showPad, setShowPad] = useState(true);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const signaturePadRef = useRef(null);
    const signaturePadRefClient = useRef(null);
    const [activeStep, setActiveStep] = useState(2);
    const steps = getSteps();
    const [data, setData] = useState([]);
    const [quationId, setQuationId] = useState(props.quationId);
    const [playAnimation, setPlayAnimation] = useState(true);

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);

    const {
        reset, resetField, register, handleSubmit, setError, setValue, getValues,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        flatpickr(".flat-pickr");
        //callFetch("project/order/" + props.id + "/edit?quationId="+quationId+"&projectid="+props.projectid, "GET", []).then((res) => {
        callFetch("project/order/" + params?.id + "/edit", "GET", []).then((res) => {
            setData(res.data);
        });
    }, [refresh]);

    useEffect(() => {
        if (!data.id) return;

        if (data?.quations?.order) {
            signaturePadRef.current.fromDataURL(data?.quations?.order?.sign_draw);
            signaturePadRefClient.current.fromDataURL(data?.quations?.order?.client_sign_draw);
        }

        setValue('i', 0);
    }, [data]);

    const hideSign = () => {
        setShowPad(true);
    }

    const hideSignClient = () => {
        setShowPadClient(true);
    }

    useEffect(() => { // useEffect hook
        if (playAnimation) {
            //document.body.classList.add('loading');
        }
        setTimeout(() => { // simulate a delay
            setPlayAnimation(false)
            //document.body.classList.remove('loading');
        }, 10000);
    }, [refresh]);

    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgSuccess
        />
    );


    const onSubmit = (formData) => {
        //setPlayAnimation(true);

        var loadingElement = document.querySelector('.res-options .btn-primary-save');
        loadingElement.classList.add("btn-secondary");
        loadingElement.innerHTML = t('wird geladen...');



        if (signaturePadRef.current.isEmpty() && signaturePadRefClient.current.isEmpty()) {
            alert('Sign please');
            return false;
        } else if (!signaturePadRef.current.isEmpty() && signaturePadRefClient.current.isEmpty()) {
            setValue('sign', signaturePadRef.current.toDataURL('image/png'));
        } else if (!signaturePadRefClient.current.isEmpty() && signaturePadRef.current.isEmpty()) {
            setValue('client_sign', signaturePadRefClient.current.toDataURL('image/png'));
        } else if (!signaturePadRef.current.isEmpty() && !signaturePadRefClient.current.isEmpty()) {
            setValue('sign', signaturePadRef.current.toDataURL('image/png'));
            setValue('client_sign', signaturePadRefClient.current.toDataURL('image/png'));
        }
        setValue('order_id', data?.quations?.order?.id);
        callFetch("save-order-sign", "POST", formData, setError).then((res) => {
            if (getValues('i') > 0) {
                setValue('i', 0);

                setTimeout(() => {
                    setSaving(false);
    
                    loadingElement.classList.remove("btn-secondary");
                    loadingElement.innerHTML = t('Save');
    
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    document.getElementById("main-content").scrollTop = 0;
                    openSuccessSB();
                }, 2000);
            } else {
                document.getElementById('saveSign').click();
                setRefresh(refresh + 1);
                //setPlayAnimation(true);
                setSaving(true);
                setValue('i', 1);
            }
        });
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-9 mt-lg-0" style={{ position: 'relative' }}>
                    {/* {playAnimation ? <div className="loader-pdf"><p>Loading...</p></div> : ''} */}

                    <SoftBox mt={1} mb={1}>
                        <Grid justifyContent="center">
                            <Grid item xs={12} lg={8}>
                                <Stepper activeStep={activeStep} alternativeLabel className="my-3">
                                    {steps.map((label) => {
                                        if (label === '1. Quotation') {
                                            return <Step key={label}>
                                                <StepLabel>1. {t('Angebote')}</StepLabel>
                                            </Step>
                                        } else if (label === '2. Order') {
                                            return <Step key={label}>
                                                <StepLabel>2. {t('Auftrag')}</StepLabel>
                                            </Step>
                                        } else {
                                            return <Step key={label}>
                                                <StepLabel>3. {t('Digital Sign')}</StepLabel>
                                            </Step>
                                        }
                                    }
                                    )}
                                </Stepper>
                            </Grid>
                        </Grid>
                    </SoftBox>

                    <div className={playAnimation ? 'card mb-4 loading' : "card mb-4"}>
                        <div className="card-body">
                            <PDF playAnimation={playAnimation} data={data} refresh={refresh + 1}></PDF>
                            <div className="row">
                                <div className="col-sm-6">
                                    {!showPad ? <div>
                                        <div className="sign-pad">
                                            <img style={{ width: '100%' }} src={data?.quations?.order ? process.env.REACT_APP_STORAGE_URL + 'signs/' + data?.quations?.order?.sign : ''} alt="" />
                                            <p style={{ borderTop: '1px solid #000', textAlign: 'center' }}>Unterschrift Verkaufspersonal</p>
                                        </div> <button type="button" className="btn btn-outline-dark" onClick={() => { hideSign(); }}>
                                            <i className="fa fa-eraser"></i>
                                        </button>
                                    </div> : ''}
                                    {showPad ? <div><div className="sign-pad">
                                        <SignaturePad options={{ minWidth: 1, maxWidth: 1, penColor: 'rgb(0, 0, 0)' }} ref={signaturePadRef} />
                                        <p style={{ borderTop: '1px solid #000', textAlign: 'center', marginTop: '18px' }}>Unterschrift Verkaufspersonal</p>
                                    </div>
                                        <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRef.current.clear(); }}>
                                            <i className="fa fa-eraser"></i>
                                        </button>   </div> : ''}

                                </div>
                                <div className="col-sm-6">
                                    {!showPadClient ? <div><div className="sign-pad">
                                        <i className="fa fa-pencil" onClick={() => hideSignClient()} style={{ position: 'relative', top: '140px' }}></i>
                                        <img style={{ width: '100%' }} src={data?.quations?.order ? process.env.REACT_APP_STORAGE_URL + 'signs/' + data?.quations?.order?.client_sign : ''} alt="" />
                                        <p style={{ borderTop: '1px solid #000', textAlign: 'center' }}>Unterschrift Auftraggeber</p>
                                    </div> <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRefClient.current.clear(); }}>
                                            <i className="fa fa-eraser"></i>
                                        </button></div> : ' '}
                                    {showPadClient ? <div><div className="sign-pad-new">
                                        <SignaturePad options={{ minWidth: 1, maxWidth: 1, penColor: 'rgb(0, 0, 0)' }} ref={signaturePadRefClient} />
                                        <p style={{ borderTop: '1px solid #000', textAlign: 'center', marginTop: '18px' }}>Unterschrift Auftraggeber</p>
                                    </div>
                                        <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRefClient.current.clear(); }}>
                                            <i className="fa fa-eraser"></i>
                                        </button>  </div> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-sm-4">
                                {!saving && (
                                    <button type="submit" id="saveSign" className="btn btn-primary">
                                        {t('Save')}
                                    </button>

                                )}
                                {saving && (
                                    <button type="button" className="btn btn-secondary" disabled>
                                        {t('wird geladen...')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-lg-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-body p-3">
                                    <div className="d-flex">
                                        <div>
                                            <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="ms-3">
                                            <div className="numbers">
                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Order Nr.')}</p>
                                                <h5 className="font-weight-bolder mb-0">
                                                    {data?.quations?.order?.id ?? '---'}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-body p-3">
                                    <div className="d-flex">
                                        <div>
                                            <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="ms-3">
                                            <div className="numbers">
                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Creator')}</p>
                                                <h5 className="font-weight-bolder mb-0">
                                                    {data?.quations?.order?.user?.name ?? '-'}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-header pb-0">
                            <h6>{t('Email')}</h6>
                        </div>
                        <div className="card-body">
                            <EmailForm id={data?.quations?.order?.id ?? 0} type={'auftrag'} pdf={true} />
                        </div>
                    </div>
                    <Timeline />
                </div>
                {renderSuccessSB}
            </div>
            <ToastContainer />
        </>
    );
}

export default Order;
