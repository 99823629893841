import { useState, useEffect, createRef } from 'react';
import moment from 'moment';
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from '@fullcalendar/interaction';
import { useTranslation } from 'react-i18next';

import SoftBox from 'components/SoftBox';
import "./styles.css";

import { PHASES_COLORS } from './utils';
import callFetch from "helpers/callFetch";
import EditPlan from "pages/dashboard/employees-dashboard/EditPlan";


const computeDurations = (mode) => {
  switch (mode) {
    case 'day':
      return {
        slotDuration: {
          hours: 1,
        },
        //slotLabelFormat: [{ month: 'long' }],
        slotLabelFormat: [{ weekday: 'long' }, { hour: 'numeric' }],
        slotMinWidth: 74,
        //duration: {hours:11},
        //scrollTime : '07:00',
        //slotMaxTime : '20:00:00'
      };
    case 'week':
      return {
        slotDuration: { days: 1 },
        slotLabelFormat: [{ month: 'long' }, /*{ day: '2-digit' },*/ { weekday: 'long' }],
        //duration: { days: 7 },
        duration: { weeks: 1 },
        slotMinWidth: 240,
      };
    case '2weeks':
      return {
        slotDuration: { days: 1 },
        slotLabelFormat: [{ month: 'long' }, { day: '2-digit' }],
        duration: { weeks: 2 },
      };
    case 'month':
      return {
        slotDuration: { days: 1 },
        slotLabelFormat: [{ day: '2-digit' }],
        duration: { months: 1 },
        slotMinWidth: 240,
      };
    case 'year':
      return {
        slotDuration: { months: 1 },
        slotLabelFormat: [{ month: 'short' }, { day: '2-digit' }],
        duration: { years: 1 },
      };
    default: return {};
  }
};


const Calendar = (props) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState('month');
  const [resources, setResources] = useState([]);
  const calendarRef = createRef();
  const { duration, slotDuration, slotLabelFormat, slotMinWidth } = computeDurations(mode);
  const [searchTerm, setSearchTerm] = useState('');
  const [planning, setPlanning] = useState([]);
  const [refresh, setRefresh] = useState(0);


  const handleEventDrop = (event) => {

    var start_date = new Date(event.event._instance.range.start);
    var end_date = new Date(event.event._instance.range.end);

    var formData = new FormData();
    formData.start_date = start_date.toISOString();
    formData.end_date = end_date.toISOString();
    formData.event_id = event.event?._def?.extendedProps?.event_id;
    formData.type = 'plan';

    callFetch("update-event-date", "POST", formData).then((res) => {

      //console.log(res.data);
      //console.log(res.message);
      //setSaving(false);
      //if (!res.ok) return;
    });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      props?.setSearch(searchTerm);
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])


  const resourceAreaHeaderContent = () => {
    return (
      <>
        <div className="input-group">
          <div className="input-group-prepend">
            <i className="fa fa-search"></i>
          </div>
          <input
            type="text"
            className="form-control form-control-sm ms-2"
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={t('Search...')}
          />
        </div>
      </>
    );
  };

  useEffect(() => {
    window.addEventListener('click', (event) => {
      var action = event.target.getAttribute("data-action");

      if (action == 'edit_plan') {
        var id = event.target.getAttribute("data-id");
        var projectnr = event.target.getAttribute("data-projectnr");
        var title = event.target.getAttribute("data-title");

        var data = {
          id: id,
          project_nr: projectnr,
          title: title,
          action: action,
        };

        setPlanning(data);
        document.getElementById('triggerEditPlanBtn').click();
      } else if (action == 'route') {
        //console.log('route');
        var id = event.target.getAttribute("data-id");

        callFetch("project-planned-technician-location/" + id, "POST", {}).then((res) => {
          if (res.message == 'success') {
            props?.setEventTechnicianLocation(res.data);
          }
        });

      }

    });
  }, [])

  return (
    <>
      <SoftBox
        style={{
          background: '#ffffff',
        }}
        className={'resourceTimelinePluginCustomCss'}
      >
        {/*<ModeSelector current={mode} onChange={setMode} />*/}
        <FullCalendar
          ref={calendarRef}
          plugins={[resourceTimelinePlugin, interactionPlugin]}
          locale={'ger'}
          timeZone="UTC"
          firstDay={1}
          scrollTime={"07:00"}
          //slotMinTime={"07:00"}
          //slotMaxTime={"21:00"}
          schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
          initialView="resourceTimeline"
          handleCustomRendering={<>Einsatzplanung</>}
          contentHeight={'545px'}
          customButtons={{
            /*
            planungTitel: {
              text: t("Einsatzplanung")
            },
            */
            day: {
              text: t("day"),
              click: function (e) {
                setMode('day');

                let fcWeekButton = document.querySelector(".fc-week-button");
                let fcMonthButton = document.querySelector(".fc-month-button");
                fcWeekButton.disabled = false;
                fcMonthButton.disabled = false;
                e.target.disabled = true;
              },
            },
            week: {
              text: t("week"),
              click: function (e) {
                setMode('week');

                let fcDayButton = document.querySelector(".fc-day-button");
                let fcMonthButton = document.querySelector(".fc-month-button");
                fcDayButton.disabled = false;
                fcMonthButton.disabled = false;
                e.target.disabled = true;
              },
            },
            month: {
              text: t("month"),
              click: function (e) {
                setMode('month');

                let fcDayButton = document.querySelector(".fc-day-button");
                let fcWeekButton = document.querySelector(".fc-week-button");
                fcDayButton.disabled = false;
                fcWeekButton.disabled = false;
                e.target.disabled = true;
              },
            },
          }}
          headerToolbar={{
            left: 'title',
            right: 'prev,today,next day,week,month',
            //center: 'title',
            //right: ''
          }}
          resourceAreaHeaderContent={resourceAreaHeaderContent}
          resourcesInitiallyExpanded={true}
          resourceOrder="from"
          slotDuration={slotDuration}
          slotLabelFormat={slotLabelFormat}
          duration={duration}
          slotMinWidth={slotMinWidth}
          //resources={resources}
          //events={getEvents(campaigns)}

          /*
          resources={[
            { "id": "c", "title": "Team A", "eventColor": "grey" },
            { "id": "d", "title": "Team B","eventColor": "grey", "children": [{ "id": "d1", "title": "Member one" }, { "id": "d2", "title": "Member two" }] },
            { "id": "e", "title": "Team C" }
          ]}
          */


          resources={props?.resources ? props?.resources : []}
          events={props?.events ? props?.events : []}

          /*
          events={[
            {"resourceId": "c","title":"event 3","start":"2023-05-30T12:00:00+00:00","end":"2023-05-30T12:00:00+00:00"},
            { "resourceId": 'd', "title": "event  3 d1", "start": "2023-05-30T12:00:00+00:00", "end": "2023-05-30T14:00:00+00:00" },
            { "resourceId": "e", "title": "event d2 3", "start": "2023-05-30T12:00:00+00:00", "end": "2023-05-30T14:00:00+00:00" },
            { "resourceId": "e", "title": "event 3", "start": "2023-05-30T12:00:00+00:00", "end": "2023-05-30T14:00:00+00:00" },
            { "resourceId": "d", "title": "event 4", "start": "2023-05-30T08:00:00+00:00", "end": "2023-05-30T09:00:00+00:00" },
            { "resourceId": "c", "title": "event 2", "start": "2023-05-30T09:00:00+00:00", "end": "2023-05-30T14:00:00+00:00" }
          ]}
          */

          //eventMouseEnter={handleMouseEnter}
          //eventMouseLeave={handleMouseLeave}


          eventMouseEnter={(e) => {
            e?.el?.parentElement.parentElement.parentElement.parentElement.style.setProperty("position", 'relative');
            e?.el?.parentElement.parentElement.parentElement.parentElement.style.setProperty("z-index", 'unset');
            e?.el?.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.classList.add("event-hover-view-table");

            if (document.querySelector(".event-hover-view")) {
              document.querySelector(".event-hover-view").remove();
            }

            var data = e?.event?._def?.extendedProps;

            e?.el?.setAttribute("data-eventid", data?.event_id);

            if (data) {
              if (data?.module_eingeben == 'no') {
                var module_titel = data?.module_short_title;
              } else {
                var module_titel = data?.custom_module_short_title;
              }

              if (data?.speicher_eingeben == 'no') {
                var speicher_titel = data?.speicher_name;
              } else {
                var speicher_titel = data?.custom_speicher_name;
              }


              var project_info = '<p>' + data?.project_info + '</P>';

              var edit_btn_attr = 'data-action="edit_plan" data-id="' + data.event_id + '" data-projectnr="' + data.project_id + '" data-title="' + data.dachmontag_elektromontage + '"';
              var route_btn_attr = 'data-action="route" data-id="' + data.event_id + '"';

              var links = '<a href="/project-management/project/edit/' + data?.project_id + '" style="cursor: pointer; width: 130px">Projekt Nr.: <span style="color: #0048B1; text-decoration: underline;">' + data?.project_id + '</span></a>' +
                '<a ' + route_btn_attr + ' style="cursor: pointer; font-size: 10px; font-weight: bold; text-decoration: underline!important; width: 80px; color: #48BB78;"><i class="fa-solid fa-location-dot"></i> Route anzeigen</a>' +
                '<a ' + edit_btn_attr + ' style="cursor: pointer; font-size: 10px; font-weight: bold; text-decoration: underline!important; width: 80px; text-align: right;"><i class="fa fa-pencil"></i> Bearbeiten</a>';
              var header_links = '<p class="m-0" style="display: flex; margin-bottom: 5px;">' + links + '</P>';

              //var header = '<div class="event-hover-view-header">' + header_links + project_info + '</div>';
              var header = '<div class="event-hover-view-header">' + header_links + '</div>';
              var body = '<div class="event-hover-view-body">' + project_info + '</div>';
              //var body = '<div class="event-hover-view-body"><p>Modul: <strong>' + data?.anzahl_module + ' Stück | ' + module_titel + '</strong></P><p>Speicher: <strong>' + speicher_titel + '</strong></P><p>Dachtyp: <strong>' + data?.dachtyp + '</strong></P></div>';

              //e?.el.insertAdjacentHTML('beforeend', '<div class="event-hover-view" style="top: 25px;">' + header + body + '</div>');
              e?.el.insertAdjacentHTML('afterend', '<div class="event-hover-view event-hover-view-'+data?.event_id+'" style="top: 0px;">' + header + body + '</div>');
              //e?.el.insertAdjacentHTML('afterend', '<div class="event-hover-view" style="top: 25px;">' + header + body + '</div>');
              //e?.el.insertAdjacentHTML('beforeend', '<div class="event-hover-view">' + header + body + '</div>');
            }
          }}


          eventMouseLeave={(e) => {
            var eventid = e?.el?.getAttribute("data-eventid");

            console.log();

            /*
            if (document.querySelector(".event-hover-view") && false) {
                document.querySelector(".event-hover-view").remove();
                e?.el?.parentElement.parentElement.parentElement.parentElement.style.setProperty("position", '');
                e?.el?.parentElement.parentElement.parentElement.parentElement.style.setProperty("z-index", '');
              }
            */

            setTimeout(()=> {
              if (document.querySelector(".event-hover-view-"+eventid)) {
                document.querySelector(".event-hover-view-"+eventid).remove();
                e?.el?.parentElement.parentElement.parentElement.parentElement.style.setProperty("position", '');
                e?.el?.parentElement.parentElement.parentElement.parentElement.style.setProperty("z-index", '');
              }
            }, 5000)
          }}

          //editable={mode !== 'year'}
          editable={true}
          aspectRatio={1.5}
          eventResourceEditable={false}
          //eventResize={handleEventChange(calendarRef, true)}
          //eventDrop={handleEventChange(calendarRef, false)}

          eventResize={(e) => {
            handleEventDrop(e);
          }}

          eventDrop={(e) => {
            handleEventDrop(e);
          }}
          eventDragStart={(e) => {
            console.log('eventDragStart')
          }}
          eventDragMinDistance={(e) => {
            console.log('eventDragMinDistance')

          }}
          eventDragScroll={(e) => {
            console.log('eventDragScroll')
          }}
        />
      </SoftBox>

      <EditPlan data={planning} setRefresh={setRefresh} />
    </>
  );
};

export default Calendar;