import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";

function RoleCreate() {
    let displayName = '';
    let params = useParams();
    const { t } = useTranslation();
    const [resData, setResData] = useState(null);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [userType, setUserType] = useState('0');

    const [isCheckAll, setIsCheckAll] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("roles/create", "GET", [], setError).then((res) => {
            setResData(res.data);
            setValue('role_for', 0);
        });
    }, [setError, setValue, params]);

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
    };

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("roles/save", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/settings/roles' /> :
        <div className="row">
            <div className="col-12">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t('Create New Role')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label>
                                        {t('Role Name')} <b>*</b>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('Role Name')}
                                        {...register("name", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                </div>
                                <div className="col-md-6 d-none">
                                    <div className="form-group">
                                        <label>
                                            {t('Role For')} <b>*</b>
                                        </label>
                                        <select
                                            className="form-control"
                                            {...register("role_for", {
                                                required: true,
                                            })}
                                            onChange={(e) => setUserType(e.target.value)}
                                            required
                                        >
                                            <option value="1">{t('General User')}</option>
                                            <option value="0">{t('System User')}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.role_for && errors.role_for.message}</div>
                                    </div>
                                </div>
                            </div>
                            {userType === '0' ? (
                                <div className="row g-3 d-none">
                                    <div className="col-md-6">
                                        <label>
                                            {t('Price')} <b>*</b>
                                        </label>
                                        <input
                                            type="text"
                                            value={0}
                                            className="form-control mb-4"
                                            placeholder={t('Price')}
                                            {...register("price", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.price && errors.price.message}</div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>
                                            {t('Validity')} <b>*</b>
                                        </label>
                                        <input
                                            type="text"
                                            value={0}
                                            className="form-control mb-4"
                                            placeholder={t('Validity')}
                                            {...register("validity", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.validity && errors.validity.message}</div>
                                    </div>
                                </div>
                            ) : ''}
                            <div className="row g-3">
                                <div className="card">
                                    <label>{t('Permissions')} <b>*</b></label>

                                    <div className="form-check">
                                        <input className="form-check-input" id="selectAll" onChange={handleSelectAll} type="checkbox" checked={isCheckAll} />
                                        <label htmlFor="selectAll" className="mb-0">Select All</label>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table align-items-center mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{t('Title')}</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{t('Read')}</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{t('Create')}</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{t('Update')}</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{t('Delete')}</th>
                                                    {/*Other users data*/}
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{t('Read Others')}</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{t('Update Others')}</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{t('Delete Others')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {resData?.permissions?
                                                    Object.keys(resData.permissions).map((permissionname, key) => (
                                                        <>
                                                    {permissionname != 'Dashboard' ? (
                                                    <tr key={'permissionname'.key}>
                                                        <td>{t(permissionname)}</td>
                                                        {resData.permissions[permissionname] && resData.permissions[permissionname].map((permission, permissionkey) => (
                                                            <td className="text-center" key={'permissions'.permissionkey}>
                                                                {
                                                                    permission.name == 'read-others-employee'  ||
                                                                    permission.name == 'update-others-employee' ||
                                                                    permission.name == 'delete-others-employee' ||
                                                                    
                                                                    permission.name == 'read-others-callcenter'  ||
                                                                    permission.name == 'update-others-callcenter' ||
                                                                    permission.name == 'delete-others-callcenter' ||

                                                                    permission.name == 'read-others-product'  ||
                                                                    permission.name == 'update-others-product' ||
                                                                    permission.name == 'delete-others-product' ||

                                                                    permission.name == 'read-others-module'  ||
                                                                    permission.name == 'update-others-module' ||
                                                                    permission.name == 'delete-others-module' ||

                                                                    permission.name == 'read-others-speicher'  ||
                                                                    permission.name == 'update-others-speicher' ||
                                                                    permission.name == 'delete-others-speicher' ||

                                                                    permission.name == 'read-others-project_call_center'  ||
                                                                    permission.name == 'update-others-project_call_center' ||
                                                                    permission.name == 'delete-others-project_call_center' ||

                                                                    permission.name == 'read-others-project_distribution'  ||
                                                                    permission.name == 'update-others-project_distribution' ||
                                                                    permission.name == 'delete-others-project_distribution' ||

                                                                    permission.name == 'read-others-project_order_processing'  ||
                                                                    permission.name == 'update-others-project_order_processing' ||
                                                                    permission.name == 'delete-others-project_order_processing' ||

                                                                    permission.name == 'read-others-project_accounting'  ||
                                                                    permission.name == 'update-others-project_accounting' ||
                                                                    permission.name == 'delete-others-project_accounting' ||

                                                                    permission.name == 'read-others-projectAssignOrderProcessing'  ||
                                                                    permission.name == 'update-others-projectAssignOrderProcessing' ||
                                                                    permission.name == 'delete-others-projectAssignOrderProcessing' ||

                                                                    permission.name == 'read-others-smtp'  ||
                                                                    permission.name == 'update-others-smtp' ||
                                                                    permission.name == 'delete-others-smtp' ||

                                                                    permission.name == 'read-others-roofplanner'  ||
                                                                    permission.name == 'update-others-roofplanner' ||
                                                                    permission.name == 'delete-others-roofplanner' ||

                                                                    permission.name == 'read-others-customer'  ||
                                                                    permission.name == 'update-others-customer' ||
                                                                    permission.name == 'delete-others-customer' ||

                                                                    permission.name == 'read-others-team'  ||
                                                                    permission.name == 'update-others-team' ||
                                                                    permission.name == 'delete-others-team' ||

                                                                    permission.name == 'read-others-dc_bnahmeprotokoll'  ||
                                                                    permission.name == 'update-others-dc_bnahmeprotokoll' ||
                                                                    permission.name == 'delete-others-dc_bnahmeprotokoll' ||


                                                                    permission.name == 'read-others-ac_abnahmeprotokoll'  ||
                                                                    permission.name == 'update-others-ac_abnahmeprotokoll' ||
                                                                    permission.name == 'delete-others-ac_abnahmeprotokoll' ||

                                                                    permission.name == 'read-others-reclamation'  ||
                                                                    permission.name == 'update-others-reclamation' ||
                                                                    permission.name == 'delete-others-reclamation' ||

                                                                    permission.name == 'read-others-support'  ||
                                                                    permission.name == 'update-others-support' ||
                                                                    permission.name == 'delete-others-support' ||

                                                                    permission.name == 'read-others-role'  ||
                                                                    permission.name == 'update-others-role' ||
                                                                    permission.name == 'delete-others-role' ? (
                                                                    <>
                                                                        
                                                                    </>
                                                                ) : <>
                                                                    <div className="form-check" data-optional={permission.display_name !== displayName ? displayName = permission.display_name : ''}>
                                                                       <input checked={isCheckAll} className="form-check-input mx-0" type="checkbox" value={permission.name} {...register("permission")} />
                                                                    </div>
                                                                </>}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                    ) : <></>}
                                                    </>
                                                )) : <></> }
                                            </tbody>
                                        </table>
                                        <table className="table align-items-center mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{t('Dashboard Permissions')}</th>
                                                </tr>
                                            </thead>
                                            {resData?.permissions?
                                                Object.keys(resData.permissions).map((permissionname, key) => (
                                                <>
                                                {permissionname == 'Dashboard' ? (
                                                    <tbody key={'permissionname'.key}>
                                                        {resData.permissions[permissionname] && resData.permissions[permissionname].map((permission, permissionkey) => (
                                                            <tr key={'permissions'.permissionkey}>
                                                                <td>
                                                                    <div className="form-check" data-optional={permission.display_name !== displayName ? displayName = permission.display_name : ''}>
                                                                        <input id={permission.display_name+permissionkey} checked={isCheckAll} className="form-check-input mx-0" type="checkbox" value={permission.name} {...register("permission")} />
                                                                        <label htmlFor={permission.display_name+permissionkey}>{permission.name }</label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                ) : <></>}
                                                </>
                                            )) : <></> }
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 my-4">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary">
                                        Save
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        Saving ...
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>;
}

export default RoleCreate;
