import { React, useMemo, useEffect, useState, useRef } from "react";

// @mui material components
import { Grid, Menu, MenuItem, Typography } from "@mui/material";

//import OperationalPlan from "./OperationalPlan";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import callFetch from "helpers/callFetch";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import dateFormat, { masks } from "dateformat";
import { NavLink } from "react-router-dom";
import FindNearestUsersByProject from "pages/FindNearest/UsersByProject";
import ProjectPlannedTechnicianLocation from "pages/FindNearest/ProjectPlannedTechnicianLocation";
import SoftSnackbar from "components/SoftSnackbar";

function OrdersToPlan(props) {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0);
    const [data, setData] = useState([]);
    const [distanceDuration, setDistanceDuration] = useState({});
    const [employees, setEmployees] = useState([]);
    const [dc_technician, setDc_technician] = useState([]);
    const [ac_technician, setAc_technician] = useState([]);
    const [totalDuedPlan, setTotalDuedPlan] = useState(0);
    const [saving, setSaving] = useState(false);
    const [refresh2, setRefresh2] = useState(0);
    const [refresh3, setRefresh3] = useState(0);
    const [planSubmitButton, setPlanSubmitButton] = useState(false);
    const [showPlanProjectRoute, setShowPlanProjectRoute] = useState(0);
    const [showPlanProjectRouteMitarbeiter, setShowPlanProjectRouteMitarbeiter] = useState(0);
    const [showPlanProjectRouteDate, setShowPlanProjectRouteDate] = useState('');
    const [showPlanProjectRouteTime, setShowPlanProjectRouteTime] = useState('');
    const [refreshNearestMitarbeiter, setRefreshNearestMitarbeiter] = useState(0);
    const [invalidScheduleProjectids, setInvalidScheduleProjectids] = useState([]);
    const [messageSb, setMessageSB] = useState(false);
    const [messageSbColor, setMessageSbColor] = useState('success');
    const [messageSbTitle, setMessageSbTitle] = useState('Something wrong!');
    const [messageSbIcon, setMessageSbIcon] = useState('check');
    const [planType, setPlanType] = useState('dcPlan');
    const [planForm, setPlanForm] = useState('Project');
    const [showmap, setShowmap] = useState('');

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    const renderMessageSB = (
        <SoftSnackbar
            color={messageSbColor}
            icon={messageSbIcon}
            title={t(messageSbTitle)}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={messageSb}
            onClose={() => setMessageSB(false)}
            close={() => setMessageSB(false)}
            bgWhite
        />
    );

    useEffect(() => {
        reset({});
        setData([]);
        //var formData = new FormData();
        //formData.planType = planType;
        callFetch("projects/orders-to-plan?planType=" + planType, "GET", []).then((res) => {
            setData(res.data.data);
            setTotalDuedPlan(res.data.to);

            for (let [key, value] of Object.entries(res.data.data)) {
                if (value.icon == 'DC') {
                    setValue("dachmontag_elektromontage[" + value.id + "]", 'Dachmontage')
                } else if (value.icon == 'AC') {
                    setValue("dachmontag_elektromontage[" + value.id + "]", 'Elektromontage')
                }
            }
        });
    }, [refresh, planType]);

    useEffect(() => {
        callFetch("assign-order-processing/create", "GET", []).then((res) => {
            if (planType == 'dcPlan') {
                setEmployees(res?.data?.dc_technician);
            } else {
                setEmployees(res?.data?.ac_technician);
            }
            //setEmployees(res?.data?.employees);
            //setDc_technician(res?.data?.dc_technician);
            //setAc_technician(res?.data?.ac_technician);
        });
    }, [planType]);

    useEffect(() => {
        setTotalDuedPlan(0);
        setPlanSubmitButton(false);
        if (getValues('project_nr')) {
            var selectedids = [];
            var plan_date = [];
            var plan_time = [];
            var dachmontag_elektromontage = [];
            var plan_form = [];
            var remining = 0;
            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('project_nr'))) {
                if (selectedrow_value) {
                    setPlanSubmitButton(true);
                    selectedids.push(selectedrow_key);
                    plan_date.push(getValues('date[' + selectedrow_key + ']'));
                    plan_time.push(getValues('time[' + selectedrow_key + ']'));
                    dachmontag_elektromontage.push(getValues('dachmontag_elektromontage[' + selectedrow_key + ']'));
                    plan_form.push(getValues('plan_form[' + selectedrow_key + ']'));
                } else {
                    remining++;
                }
            }
            setTotalDuedPlan(remining);

            var formData = new FormData();
            formData.projectIds = JSON.stringify(selectedids);
            formData.dachmontag_elektromontage = JSON.stringify(dachmontag_elektromontage);
            formData.plan_date = JSON.stringify(plan_date);
            formData.plan_time = JSON.stringify(plan_time);
            formData.plan_form = JSON.stringify(plan_form);

            callFetch("nearest-users/projects", "POST", formData, setError).then((res) => {
                if (res?.data) {
                    for (let [miltarbeiter_key, miltarbeiter_value] of Object.entries(res.data)) {
                        setValue("miltarbeiter[" + miltarbeiter_value.project_id + "]", miltarbeiter_value.id);
                    }
                }
            });
        }
    }, [refresh2]);

    useEffect(() => {
        setTotalDuedPlan(0);
        setPlanSubmitButton(false);
        if (getValues('project_nr')) {
            var remining = 0;
            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('project_nr'))) {
                if (selectedrow_value) {
                    setPlanSubmitButton(true);
                } else {
                    remining++;
                }
            }
            setTotalDuedPlan(remining);
        }
    }, [refresh3]);

    const setNearestMiltarbeiter = (id) => {
        setValue("miltarbeiter[" + showPlanProjectRoute + "]", id);
        setShowPlanProjectRouteMitarbeiter(id);
        validationSuccess();
    }

    const validationSuccess = () => {

        var assigned_technician_validation_check = [];
        var invalidScheduleProjects = [];

        for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('project_nr'))) {
            if (selectedrow_value) {
                var current_plan_technician_schedule = getValues('miltarbeiter[' + selectedrow_key + ']') + '_' + getValues('date[' + selectedrow_key + ']') + '_' + getValues('time[' + selectedrow_key + ']');

                if (assigned_technician_validation_check.indexOf(current_plan_technician_schedule) !== -1) {
                    //console.log('Invalidate: ' + selectedrow_key + ': ' + current_plan_technician_schedule);
                    invalidScheduleProjects.push(Number(selectedrow_key));
                } else {
                    //console.log('Validate: ' + selectedrow_key + ': ' + current_plan_technician_schedule);
                    assigned_technician_validation_check.push(current_plan_technician_schedule);
                }
            }
        }

        setInvalidScheduleProjectids(invalidScheduleProjects);
        //console.log(invalidScheduleProjects)

        if (invalidScheduleProjects.length) {
            return false;
        } else {
            return true;
        }
    }

    const setDirectionDistance = (data) => {
        console.log('setDirectionDistance');
        console.log(data?.project_id);

        //distanceDuration, setDistanceDuration

        if (!data.project_id) return;

        var updatedistanceDuration = distanceDuration;
        updatedistanceDuration[data.project_id] = data;
        setDistanceDuration(updatedistanceDuration);
        setRefresh3(refresh3 + 1);
    }

    const onSubmit = (data) => {

        setSaving(true);

        if (validationSuccess()) {
            //console.log('Validation success');

            var selectedids = [];
            var date = [];
            var time = [];
            var miltarbeiter = [];
            var dachmontag_elektromontage = [];
            var plan_form = [];
            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('project_nr'))) {
                if (selectedrow_value) {
                    selectedids.push(selectedrow_key);
                    date.push(getValues('date[' + selectedrow_key + ']'));
                    time.push(getValues('time[' + selectedrow_key + ']'));
                    miltarbeiter.push(getValues('miltarbeiter[' + selectedrow_key + ']'));
                    dachmontag_elektromontage.push(getValues('dachmontag_elektromontage[' + selectedrow_key + ']'));
                    plan_form.push(getValues('plan_form[' + selectedrow_key + ']'));
                }
            }

            var formData = new FormData();
            formData.project_id = JSON.stringify(selectedids);
            formData.date = JSON.stringify(date);
            formData.time = JSON.stringify(time);
            formData.miltarbeiter = JSON.stringify(miltarbeiter);
            formData.dachmontag_elektromontage = JSON.stringify(dachmontag_elektromontage);
            formData.plan_form = JSON.stringify(plan_form);

            callFetch("orders-to-plan", "POST", formData, setError).then((res) => {
                setRefresh(refresh + 1);
                props.setRefresh((Math.random() * 1000) + 1000);
                setSaving(false);
                setPlanSubmitButton(false);
            });

            props.setRefresh((Math.random() * 1000) + 1000);
            reset({});

        } else {
            setMessageSbTitle('Some plan schedule already booked');
            setMessageSbIcon('');
            setMessageSbColor('error');
            setMessageSB(true);
            setSaving(false);
        }
    }

    useEffect(() => {
        if (props?.eventTechnicianLocation?.distance > 0) {
            setShowmap('event_route');
        }
    }, [props?.eventTechnicianLocation]);

    return (
        <>

            <Grid container spacing={3} py={3}>
                <Grid item xs={6}>
                    <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <div className="card">
                            <div className="card-header px-2 pb-0 pt-2">
                                <h6 className="card-title m-0 float-start">Aufträge zu Planen ({totalDuedPlan})</h6>

                                {!saving && (
                                    <>
                                        {planSubmitButton ? (
                                            <>
                                                <button type="submit" className="btn btn-success float-end m-0">{t('Auswahl Bestätigen')}</button>

                                                <div className="form-check float-end mx-3">
                                                    <input
                                                        id="uncheckAll"
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        defaultChecked
                                                        onChange={() => {
                                                            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('project_nr'))) {
                                                                setValue("project_nr[" + selectedrow_key + "]", false);
                                                                setRefresh2(refresh2 + 1);
                                                                setShowmap('');
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor="uncheckAll">Uncheck All</label>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <a
                                                    type="submit"
                                                    className="btn btn-primary float-end m-0"
                                                    data-selecttype="select_all"
                                                    onClick={(e) => {
                                                        //if(e.target.dataset.selecttype == 'select_all'){
                                                        //e.target.attributes.getNamedItem("data-selecttype").value = 'deselect_all';
                                                        for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('project_nr'))) {
                                                            setValue("project_nr[" + selectedrow_key + "]", true)
                                                        }
                                                        //}else{
                                                        //for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('project_nr'))) {
                                                        //setValue("project_nr["+selectedrow_key+"]", false)
                                                        //}
                                                        //}
                                                        setRefresh2(refresh2 + 1);
                                                        setShowmap('');
                                                    }}
                                                >
                                                    {t('Alle Aufträge Planen')}
                                                </a>
                                            </>
                                        )}
                                    </>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled float-end m-0" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}

                                <div class="form-check float-end mx-3">
                                    <input
                                        onClick={(e) => {
                                            setPlanType('acPlan');
                                            setShowmap('');
                                            setDistanceDuration({});
                                        }}
                                        class="form-check-input"
                                        type="radio"
                                        name="chooseplan"
                                        id="acPlan"
                                    />
                                    <label class="form-check-label" for="acPlan">AC Plan</label>
                                </div>

                                <div class="form-check float-end mx-3">
                                    <input
                                        onClick={(e) => {
                                            setPlanType('dcPlan');
                                            setShowmap('');
                                            setDistanceDuration({});
                                        }}
                                        class="form-check-input"
                                        type="radio"
                                        name="chooseplan"
                                        id="dcPlan"
                                        defaultChecked
                                    />
                                    <label class="form-check-label" for="dcPlan">DC Plan</label>
                                </div>

                                <div class="form-check float-end mx-3">
                                    <input
                                        onClick={(e) => {
                                            setPlanType('stornoPlan');
                                            setShowmap('');
                                            setDistanceDuration({});
                                        }}
                                        class="form-check-input"
                                        type="radio"
                                        name="chooseplan"
                                        id="stornoPlan"
                                    />
                                    <label class="form-check-label" for="stornoPlan">Storno</label>
                                </div>
                            </div>
                            <div className="card-body p-2" style={{ height: '436px', overflowY: 'auto' }}>

                                {data.map((item, index) => (
                                    <SoftBox
                                        key={index}
                                        style={{
                                            backgroundColor: (showPlanProjectRoute == item?.id ? '#DEE2E6' : '#F8F9FA'),
                                            cursor: 'pointer'
                                        }}
                                        borderRadius="md"
                                        p={1}
                                        mb={1}

                                        className={(invalidScheduleProjectids.indexOf(item?.id) !== -1 ? 'invalid-schedule' : '')}

                                        onClick={(e) => {
                                            if (e?.target?.type != 'checkbox') {
                                                setShowPlanProjectRoute(item?.id);
                                                setShowPlanProjectRouteMitarbeiter(getValues("miltarbeiter[" + item?.id + "]"));
                                                setShowPlanProjectRouteDate(getValues("date[" + item?.id + "]"));
                                                setShowPlanProjectRouteTime(getValues("time[" + item?.id + "]"));
                                            } else {
                                                console.log(e);
                                            }

                                            setPlanForm(item?.plan_form);

                                            validationSuccess();
                                            setShowmap('');
                                        }}
                                    >

                                        <input
                                            type="hidden"
                                            {...register("plan_form[" + item.id + "]", {
                                                required: true,
                                            })}
                                            value={item?.plan_form}
                                        />
                                        <table className="table mb-0" key={index}>
                                            <tr>
                                                <td style={{ width: '50px', textAlign: 'left', verticalAlign: 'top' }}>
                                                    <div className="form-check form-check-inline m-0">
                                                        <input
                                                            className="form-check-input ms-0"
                                                            type="checkbox"
                                                            {...register("project_nr[" + item?.id + "]", {
                                                                required: false,
                                                                onChange: (e) => {
                                                                    //setRefresh2(refresh2 + 1);
                                                                    setRefresh3(refresh3 + 1);
                                                                    validationSuccess();
                                                                }
                                                            })}
                                                        />
                                                        <label className="form-check-label"></label>
                                                    </div>
                                                </td>
                                                <td style={{ verticalAlign: 'top', width: '110px', }}>
                                                    <SoftTypography style={{ fontSize: '13px' }} className="p-0" variant="button">
                                                        {t(item?.title)}.: <NavLink to={(item?.plan_form == 'Reclamation' ? '/reclamation/' + item.id + '/view/' + item.project_nr : '/project-management/project/edit/' + item.id)} className="text-primary">{item?.plan_form == 'Reclamation' ? 'R-' + item.id : item.id}</NavLink>
                                                    </SoftTypography><br />

                                                    <SoftBox
                                                        display="grid"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        color="white"
                                                        width="2.2rem"
                                                        height="2.2rem"
                                                        shadow="md"
                                                        borderRadius="lg"
                                                        variant="gradient"
                                                        style={{ backgroundColor: (item?.icon == 'AC' ? '#48BB78' : '#E53E3E') }}
                                                        mt={1}
                                                    >
                                                        <Typography style={{
                                                            fontSize: '14px'
                                                        }}>{item?.icon}</Typography>
                                                    </SoftBox>
                                                </td>
                                                <td style={{ lineHeight: '12px', paddingLeft: '15px' }}>
                                                    <SoftTypography className="p-0" style={{ fontSize: '11px' }} variant="caption"><span >{t('Name')}</span>: <strong>{item?.name + (item?.phone && item?.phone != 'null' ? ', ' + item?.phone : '')}</strong></SoftTypography><br />
                                                    <SoftTypography className="p-0" style={{ fontSize: '11px' }} variant="caption"><span >{t('Address')}</span>: <strong>{item?.street + ' ' + item?.nr + ', ' + item?.plz_ort}</strong></SoftTypography><br />


                                                    {item?.plan_form == 'Reclamation' ? (
                                                        <>
                                                            {JSON.parse(item?.products).map((product, product_index) => (
                                                                <>
                                                                    <SoftTypography key={product_index} className="p-0" style={{ fontSize: '11px' }} variant="caption">
                                                                        <span >{t(product?.id.split('-')?.[0])}</span>: <strong>{product?.quantity + ' Stck. | ' + product?.title}</strong>
                                                                    </SoftTypography>
                                                                    <br />
                                                                </>
                                                            ))}

                                                            <SoftTypography className="p-0" style={{ fontSize: '11px' }} variant="caption"><span >{t('Dachtyp')}</span>: <strong>{item?.dachtyp}</strong></SoftTypography><br /><br />

                                                        </>
                                                    ) : (
                                                        <>
                                                            <SoftTypography className="p-0" style={{ fontSize: '11px' }} variant="caption"><span >{t('Modul')}</span>: <strong>{item?.anzahl_module + ' Stck. | ' + (item?.module_eingeben == 'no' ? item?.module_short_title : item?.custom_module_short_title)}</strong></SoftTypography><br />
                                                            <SoftTypography className="p-0" style={{ fontSize: '11px' }} variant="caption"><span >{t('Speicher')}</span>: <strong>{item?.speicher_eingeben == 'no' ? item?.speicher_name : item?.custom_speicher_name}</strong></SoftTypography><br />
                                                            <SoftTypography className="p-0" style={{ fontSize: '11px' }} variant="caption"><span >{t('Dachtyp')}</span>: <strong>{item?.dachtyp}</strong></SoftTypography><br /><br />

                                                        </>
                                                    )}


                                                    <SoftTypography className="p-0" style={{ fontSize: '11px' }} variant="caption"
                                                        style={{
                                                            color: '#0048B1',
                                                            fontWeight: 'bold',
                                                            fontSize: '11px'
                                                        }}
                                                    >Wunschtermin: {/*item.assembly_date ? item.assembly_date : '--'*/}{item.assembly_date ? dateFormat(item?.assembly_date, "dd.mm.yyyy") : '--'}
                                                    </SoftTypography>
                                                </td>
                                                <td style={{ width: '155px', verticalAlign: 'top' }}>

                                                    {getValues("project_nr[" + item?.id + "]") === true ? (
                                                        <>
                                                            <div className={"p-0 "}>
                                                                <input
                                                                    type="date"
                                                                    className="form-control form-control-sm mb-1"
                                                                    placeholder={t("eg. 16-04-2022")}
                                                                    {...register("date[" + item.id + "]", {
                                                                        required: true,
                                                                        onChange: (e) => {
                                                                            setShowPlanProjectRouteDate(e.target.value);
                                                                            setRefreshNearestMitarbeiter(refreshNearestMitarbeiter + 1);
                                                                            validationSuccess();
                                                                        }
                                                                    })}
                                                                    defaultValue={item.assembly_date}
                                                                    required
                                                                />
                                                                <select
                                                                    className="form-control form-control-sm my-1"
                                                                    {...register("time[" + item.id + "]", {
                                                                        required: true,

                                                                        onChange: (e) => {
                                                                            setShowPlanProjectRouteTime(e.target.value);
                                                                            setRefreshNearestMitarbeiter(refreshNearestMitarbeiter + 1);
                                                                            validationSuccess();
                                                                        }
                                                                    })}
                                                                    required
                                                                >
                                                                    {/*
                                                                    <option value="08:00">08:00 - 10:00</option>
                                                                    <option value="10:00">10:00 - 12:00</option>
                                                                    <option value="12:00">12:00 - 14:00</option>
                                                                    <option value="14:00">14:00 - 16:00</option>
                                                                    <option value="16:00">16:00 - 18:00</option>
                                                                    <option value="18:00">18:00 - 20:00</option>
                                                                    */}
                                                                    <option value="08:00">08:00 - 13:00</option>
                                                                    <option value="14:00">14:00 - 19:00</option>
                                                                </select>

                                                                <select
                                                                    className="form-control form-control-sm my-1"
                                                                    {...register("miltarbeiter[" + item.id + "]", {
                                                                        required: true,

                                                                        onChange: (e) => {
                                                                            setShowPlanProjectRouteMitarbeiter(e.target.value);
                                                                            validationSuccess();
                                                                        }

                                                                    })}
                                                                    required
                                                                >
                                                                    <option value={''}>----</option>
                                                                    {employees?.map((technician, index) => (
                                                                        <option value={technician.id}>
                                                                            {technician.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <select
                                                                    className="form-control mb-2 d-none"
                                                                    {...register("dachmontag_elektromontage[" + item.id + "]", {
                                                                        required: true,
                                                                    })}
                                                                    required
                                                                    readOnly={true}
                                                                >
                                                                    <option value="Dachmontage">Dachmontage</option>
                                                                    <option value="Elektromontage">Elektromontage</option>
                                                                </select>

                                                                {distanceDuration[item.id] ?
                                                                    <p className="m-0" style={{ color: '#000000', fontSize: '11px' }}><i className="fa fa-car"></i> {distanceDuration[item.id]?.distance ? distanceDuration[item.id]?.distance?.text : ''} | {distanceDuration[item.id]?.duration ? distanceDuration[item.id]?.duration?.hours_minute : ''} h</p>
                                                                    : ''}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="p-0 validation_ignore">
                                                                <input
                                                                    type="date"
                                                                    className="form-control form-control-sm mb-1"
                                                                    placeholder={t("eg. 16-04-2022")}
                                                                    {...register("date[" + item.id + "]", {
                                                                        required: false,
                                                                        onChange: (e) => {
                                                                            setShowPlanProjectRouteDate(e.target.value);
                                                                            setRefreshNearestMitarbeiter(refreshNearestMitarbeiter + 1);
                                                                        }
                                                                    })}
                                                                    defaultValue={item.assembly_date}

                                                                />
                                                                <select
                                                                    className="form-control form-control-sm my-1"
                                                                    {...register("time[" + item.id + "]", {
                                                                        required: false,
                                                                        onChange: (e) => {
                                                                            setShowPlanProjectRouteTime(e.target.value);
                                                                            setRefreshNearestMitarbeiter(refreshNearestMitarbeiter + 1);
                                                                        }
                                                                    })}

                                                                >
                                                                    <option value="08:00">08:00 - 13:00</option>
                                                                    <option value="14:00">14:00 - 19:00</option>
                                                                </select>

                                                                <select
                                                                    className="form-control form-control-sm my-1"
                                                                    {...register("miltarbeiter[" + item.id + "]", {
                                                                        required: false,
                                                                        onChange: (e) => {
                                                                            setShowPlanProjectRouteMitarbeiter(e.target.value)
                                                                        }
                                                                    })}

                                                                >
                                                                    <option value={''}>----</option>
                                                                    {employees?.map((technician, index) => (
                                                                        <option value={technician.id}>
                                                                            {technician.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <select
                                                                    className="form-control mb-2 d-none"
                                                                    {...register("dachmontag_elektromontage[" + item.id + "]", {
                                                                        required: false,
                                                                    })}
                                                                    readOnly={true}
                                                                >
                                                                    <option value="Dachmontage">Dachmontage</option>
                                                                    <option value="Elektromontage">Elektromontage</option>
                                                                </select>

                                                                {distanceDuration[item.id] ?
                                                                    <p className="m-0" style={{ color: '#000000', fontSize: '11px' }}><i className="fa fa-car"></i> {distanceDuration[item.id]?.distance ? distanceDuration[item.id]?.distance?.text : ''} | {distanceDuration[item.id]?.duration ? distanceDuration[item.id]?.duration?.hours_minute : ''} h</p>
                                                                    : ''}
                                                            </div>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        </table>
                                    </SoftBox>
                                ))}
                            </div>
                        </div>
                    </form>
                </Grid >
                <Grid item xs={6}>
                    <div className="card">
                        <div className="card-body p-2">
                            {showmap == 'event_route' ? (
                                <>
                                    <ProjectPlannedTechnicianLocation data={props?.eventTechnicianLocation} />
                                </>
                            ) : (
                                <>
                                    <FindNearestUsersByProject
                                        miltarbeiter={showPlanProjectRouteMitarbeiter}
                                        date={showPlanProjectRouteDate}
                                        time={showPlanProjectRouteTime}
                                        setNearestMiltarbeiter={setNearestMiltarbeiter}
                                        projectId={showPlanProjectRoute}
                                        dachmontag_elektromontage={planType == 'dcPlan' ? 'Dachmontage' : 'Elektromontage'}
                                        detalsDisplay={false}
                                        refreshNearestMitarbeiter={refreshNearestMitarbeiter}
                                        setDirectionDistance={setDirectionDistance}
                                        planForm={planForm}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </Grid>
            </Grid >
            {renderMessageSB}
        </>
    );
}

export default OrdersToPlan;
