import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import "./style.css"
import * as XLSX from 'xlsx';
import callFetch from "helpers/callFetch";
import SoftTypography from "components/SoftTypography";
import SoftAlert from "components/SoftAlert";
import OutputModal from './OutputModal';
import Cookies from 'js-cookie';

import dataXlsxProject from "./dataXlsxProject.xlsx"
import dataXlsxEmployee from "./dataXlsxEmployee.xlsx"
import dataXlsxProjectWithSales from "./dataXlsxProjectWithSales.xlsx"

// sweetalert2 components
import Swal from "sweetalert2";

const ImportModal = (props) => {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [fileData, setFileData] = useState('');
    const [type, setType] = useState(props?.type);
    const [fileName, setFileName] = useState(t('Files Supported') + ": XLSX");
    const [success, setSuccess] = useState(null);
    const [data, setData] = useState([]);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
        reset
    } = useForm();
    const ReadExcel = (file) => {
        setFileData(file)
        setFileName(file.name);
    }


    //let timerInterval;
    const showAlert = () => {
        Swal.fire({
            title: t("Ladevorgang"),
            //html: "I will close in <b></b> milliseconds.",
            didOpen: () => {
                Swal.showLoading();
            },
        }).then((result) => {
            /* Read more about handling dismissals below */
            /*
            if (result.dismiss === Swal.DismissReason.timer) {
                console.log("I was closed by the timer");
            }
            */
        });
    }

    const onSubmit = (formData) => {
        setSaving(true);
        formData.Files = fileData;
        formData.type = type;
        callFetch("import", "POST", formData, setError).then((res) => {

            if (res.message == 'success') {
                setData(res.data);
                document.getElementById(type + 'import-button-close').click();
                document.getElementById(type + 'outputModal-show-button').click();
            }

            setRefresh(refresh + 1);
            props.refreshParent();

            setSaving(false);
            Swal.close();
        })
    };

    return (
        <>
            {Cookies.get('permissions').indexOf("Import") !== -1 ? (
                <>
                    <div className="modal fade" id={type + "importModal"} tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id={type + "importModalLabel"}>{t('Import')}</h5>
                                        <button type="button" onClick={() => { setSuccess(null); setFileName(null); setValue("Files", "") }} className="btn-close text-dark" id={type + "import-button-close"} data-bs-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <SoftTypography variant="caption" color="text" >
                                            {t('Before import your file please download')}
                                            <a
                                                className="ms-2 text-blue-700"
                                                download={type}
                                                href={
                                                    type == 'project' ? dataXlsxProject
                                                        : type == 'employee' ? dataXlsxEmployee
                                                            : type == 'projectWithSales' ? dataXlsxProjectWithSales
                                                                : ''
                                                }
                                            >XLSX</a>
                                        </SoftTypography>
                                        <hr />
                                        {
                                            success?.status === 200 && <SoftAlert icon="none" color="success" dismissible>
                                                {success?.success}
                                            </SoftAlert>
                                        }

                                        {
                                            success?.status === 400 && <SoftAlert icon="none" color="warning" dismissible>
                                                {success?.success}
                                            </SoftAlert>
                                        }

                                        <div className="drop_box">
                                            <header>
                                                <SoftTypography variant="h4" color="text" >{t('Select File here')}</SoftTypography>
                                                {/* <h4></h4> */}
                                            </header>
                                            {
                                                fileName ? <p style={{ color: "#0909f3" }}>{fileName}</p> : <p>{t('Files Supported')}: CSV</p>
                                            }


                                            <input type="file" accept=".xlsx" id={type + "fileID"} style={{ display: "none" }} {...register("Files", { require: true })} required onChange={(e) => ReadExcel(e.target.files[0])} />
                                            <label htmlFor={type + "fileID"} className="btn">{t('Choose File')}</label>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setSuccess(null);
                                                setFileName(null);
                                                setValue("Files", "")
                                            }}
                                            className="btn bg-gradient-secondary"
                                            data-bs-dismiss="modal"
                                        >{t('Close')}</button>
                                        {!saving && (
                                            <button onClick={showAlert} type="submit" className="btn btn-primary">
                                                {t('Save')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="button" className="btn btn-disabled" disabled>
                                                {t('Saving ...')}
                                            </button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <OutputModal filename={data} type={type} />
                </>
            ) : <></>}
        </>
    )
}

export default ImportModal