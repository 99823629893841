import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
function EmployeeEdit() {
    let params = useParams();
    const { t } = useTranslation();
    const [teams, setTeams] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [editDelay, setEditDelay] = useState(0);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("designations", "GET", []).then((res) => {
            setDesignations(res.data);
            setEditDelay(editDelay + 1);
        });

        callFetch("departments", "GET", []).then((res) => {
            setDepartments(res.data);
            setEditDelay(editDelay + 1);
        });
    }, [refresh]);


    useEffect(() => {
        callFetch("get-teams", "GET", []).then((res) => { 
            var teams = [];

            for (var i = 0; i < res.data.length; i++) {
                teams.push({
                    value: res.data[i].id,
                    label: res.data[i].team_name
                });
            }

            setTeams(teams);

        }); 
     
    }, [params.id]);

    useEffect(() => {
        if (editDelay > 0)
            callFetch("callcenter/" + params.id + "/edit", "GET", []).then((res) => {
                for (let [key, value] of Object.entries(res.data)) {
                    setValue(key, (value == null || value == 'null' ? "" : value )); 
                }
                setSelectedTeams(res.data.teams ? JSON.parse(res.data.teams) : null);
            });
    }, [editDelay]);

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("callcenter/" + params.id, "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/call-center/index/all' /> :
        <div className="row">
            <div className="col-12">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t('Edit Call Center')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <input type="hidden" defaultValue="PUT" {...register("_method")} />

                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label>
                                        {t('Call Center ID')} *
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        {...register("identity_number", {
                                            required: true,
                                        })}
                                        required
                                        readOnly
                                    />
                                    <div className="invalid-feedback">{errors.identity_number && errors.identity_number.message}</div>
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        {t('Call Center Name')} *
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('eg. Jhon Doe')}
                                        {...register("name", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                </div>
                                
                                <div className="col-md-6">
                                    <label>
                                        {t('Call Center Email')} *
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control mb-4"
                                        placeholder={t('eg. email@mail.com')}
                                        {...register("email", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                </div>

                                <div className="col-md-6">
                                    <label>
                                        {t('Password')} *
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control mb-4"
                                        placeholder={t('eg.')+" ********"}
                                        {...register("password")}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.password && t(errors.password.message)}</div>
                                </div>
                                
                                <div className="col-md-6">
                                    <label>
                                        {t('Mobile')}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('eg. 98765432')}
                                        {...register("mobile")} />
                                    <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('Teams')}</label>
                                        <Select
                                            placeholder={t('Select')}
                                            options={ teams ? teams :  { value: '', label: '---' } }
                                            value={selectedTeams}
                                            onChange={(e)=>{
                                                setSelectedTeams(e);
                                                setValue('teams', JSON.stringify(e));
                                            }}
                                            isMulti                                
                                        />
                                        <div className="invalid-feedback">{errors.selectedTeams && errors.selectedTeams.message}</div>
                                    </div>
                                </div>
                                
                                <div className="form-group mb-4">
                                    <label>
                                        {t('Address')}
                                    </label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        placeholder={t('eg. 1234 My street, City')}
                                        {...register("address")}></textarea>
                                    <div className="invalid-feedback">{errors.address && errors.address.message}</div>
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>;
}

export default EmployeeEdit;
