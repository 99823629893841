import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr"; 
import { NumericFormat } from 'react-number-format';

function Create() {
    let params = useParams();
    const { t } = useTranslation();  
    const [data, setData] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [total, setTotal] = useState(0);
    const [currency, setCurrency] = useState('EUR');
    const [projects, setProjects] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [receivable, setReceivable] = useState(0);
    const [salaryCategory, setSalaryCategory] = useState([
        { invoice_id: ' ', description: ' ', date: ' ', amount : ' ' }, 
    ]);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
 
    useEffect(() => {
        flatpickr(".flat-pickr");

        callFetch("payments/create-payment", "GET", []).then((res) => {
            // setData(res.data);
            // console.log(res.data); 
            setValue('payment_id', res.data.id); 
            setProjects(res.data.projects);
            // if(res.data.received_payments != null){
            //     setSalaryCategory(JSON.parse(res.data.received_payments));
            // } 
        });
    }, []);

   
    
    const handleChangeSalaryCategoryInput = (index, event) => {
        const values = [...salaryCategory];
        values[index][event.target.name] = event.target.value; 
        setSalaryCategory(values);
    }
   

    const handleAddField = () => {
        setSalaryCategory([...salaryCategory, {invoice_id: ' ', description: ' ', date: ' ', amount : ' ' }]);
    }

    const handleRemoveField = (index) => {
        const values = [...salaryCategory];
        values.splice(index, 1); 
        setSalaryCategory(values); 
    }

    useEffect(() => {
        var i;
        var total = 0;
        for(i=0; i<salaryCategory.length; i++){
            total = total + Number(salaryCategory[i]['amount']);    
        }
        setTotal(total);
    }, [salaryCategory]);

    const getInvoice = (id) => {
        callFetch("payments/get-invoices/"+id, "GET", []).then((res) => { 
            setInvoices(res.data.invoices);  
            setReceivable(res.data.total_receivable);
            setValue('receivable', res.data.total_receivable); 

            // if(res.data.payment.received_payments != null){
            //     setSalaryCategory(JSON.parse(res.data.payment.received_payments));
            // }
            
            // setValue('payment_id', res.data.payment.id);
        });
    }

 

    const onSubmit = (formData) => { 
        setSaving(true);
        formData.received_payments = JSON.stringify(salaryCategory);
        formData.total = total;
        callFetch("payments/post", "POST", formData, setError).then((res) => {
            setSaving(false); 
            console.log(res.message);
            if (!res.ok) return; 
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to={'/finance/payments'} /> :
        <div className="row">
            <div className="col-12">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t('Book Payment')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <div className="row g-3"> 
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>
                                            {t('Payment Nr')} *
                                        </label>
                                        <br />
                                        <input type="text" className="form-control" {...register("payment_id", {
                                            required: true,
                                        })} readOnly/>
                                        <div className="invalid-feedback">{errors.payment_id}</div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>
                                            {t('Project Nr')} *
                                        </label>
                                        <br />
                                        <select className="form-control"
                                            {...register("project_id", {
                                                required: true,
                                            })}
                                            required onChange={(e) => getInvoice(e.target.value)}>
                                            <option value="">--</option>
                                            {projects.map((item) => (
                                                <option value={item.id}>{item.id}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">{errors.project_id}</div>
                                    </div>
                                </div>
                                
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>
                                            {t('Currency')} *
                                        </label>
                                        <br />
                                        <select
                                            className="form-control"
                                            {...register("currency", {
                                                required: true,
                                            })}
                                            onChange={(e) => {
                                                setCurrency(e.target.value)
                                            }}
                                            required
                                        >
                                            <option value="EUR">EUR (€)</option>
                                            <option value="USD">USD ($)</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.currency && errors.currency.message}</div>
                                    </div>
                                </div>  
                            </div> 

  
                            
                            {salaryCategory.map((category, index) => 
                                <div className="row g-3" key={index}> 
                                    <div className="col-md-4">
                                        <div className="form-group">
                                        <label>
                                            {t('Invoice Nr')} *
                                        </label>
                                        <br />
                                        <select className="form-control"
                                            value={category.invoice_id}
                                            name="invoice_id"
                                            required onChange = {event => handleChangeSalaryCategoryInput(index, event)}>
                                            <option value="">--</option>
                                            {invoices && invoices.map((item) => (
                                                <option value={item.id}>{item.id}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">{errors.project_id}</div>
                                    </div>
                                </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>
                                                {t('Description')} *
                                            </label>
                                            <br />
                                            <input type="text" value={category.description}
                                                onChange = {event => handleChangeSalaryCategoryInput(index, event)} className="form-control" name="description"/> 
                                            <div className="invalid-feedback">{errors.description}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>
                                                {t('Payment Date')} *
                                            </label>
                                            <br />
                                            <input type="date" value={category.date}
                                                onChange = {event => handleChangeSalaryCategoryInput(index, event)} className="form-control" name="date"/> 
                                            <div className="invalid-feedback">{errors.date} </div>
                                        </div>
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="row">
                                            <div className="col-sm-10">
                                                <div className="form-group">
                                                    <label>
                                                        {t('Amount S')} *
                                                    </label>
                                                    <br />
                                                    <input type="text" value={category.amount}
                                                        onChange = {event => handleChangeSalaryCategoryInput(index, event)} className="form-control" name="amount"/> 
                                                    <div className="invalid-feedback">{errors.amount && errors.currency.amount} </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2"><i className="fa-solid fa-circle-xmark text-danger" style={{marginTop: '43px'}} onClick={() => handleRemoveField(index)}></i></div> 
                                        </div>
                                    </div>  
                                </div>  
                            )}

                            <div className="row g-3">
                                <div className="col-sm-1"><button type="button" className="btn btn-outline-secondary mt-3" onClick={() => handleAddField()}>+{t('Add')}</button></div>
                                <div className="col-sm-9"></div>
                                <div className="col-sm-2"><p><label>{t('Total S')}: </label> <NumericFormat 
                                                        value={total-receivable}
                                                        displayType="text" 
                                                        thousandSeparator={"."} 
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                    /></p></div>
                            </div>

                             
                                
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>  
            </div>
        </div>
}

export default Create;
