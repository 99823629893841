import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch";
import flatpickr from "flatpickr";
import Select from 'react-select';
import SoftSnackbar from "components/SoftSnackbar";
import Timeline from "./Timeline";
import Cookies from 'js-cookie';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';

import ProjectStatus from '../ProjectStatus';
import Swal from 'sweetalert2';

function CallCenter(props) {
    let params = useParams();
    const { t } = useTranslation();
    const submitBtn = useRef();
    const [data, setData] = useState([]);
    const [zipcode, setZipcode] = useState([]);
    const [selectedPlz, setSelectedPlz] = useState({ value: '', label: '---' });
    const [city, setCity] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [files, setFiles] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [refesh3, setRefesh3] = useState(0);
    const [projectId, setProjectId] = useState(0);
    const [create, setCreate] = useState(true);
    const [formdata, setFormdata] = useState({});
    const [successSB, setSuccessSB] = useState(false);
    const [autocomplete, setAutocomplete] = useState(null);
    const [readOnly, setReadOnly] = useState(true);


    const libraries = ["places"]
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries
    })

    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={() => setSuccessSB(false)}
            close={() => setSuccessSB(false)}
            bgSuccess
        />
    );
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const onLoad = (autocomplete) => {
        console.log('autocomplete: ', autocomplete);
        setAutocomplete(autocomplete);
    }

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            console.log('onPlaceChanged')
            console.log(autocomplete)

            var place = autocomplete.getPlace();
            //console.log('address_components');
            setValue('plz', '');
            setValue('nr', '');
            setValue('ort', '');
            setValue('street', '');

            if (place?.address_components) {
                for (let [key, value] of Object.entries(place?.address_components)) {
                    if (value?.types) {
                        if (value?.types.indexOf("postal_code") !== -1) {
                            setValue('plz', value?.long_name);
                        } else if (value?.types.indexOf("street_number") !== -1) {
                            setValue('nr', value?.long_name);
                        } else if (value?.types.indexOf("locality") !== -1) {
                            setValue('ort', value?.long_name);
                        } else if (value?.types.indexOf("route") !== -1) {
                            setValue('street', value?.long_name);
                        }
                    }
                }
            }
            setValue('lat', place.geometry.location.lat());
            setValue('lng', place.geometry.location.lng());
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }


    const storno = (e) => {
        if (!data?.data?.id) return;
        e.preventDefault();

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary me-2',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        return swalWithBootstrapButtons.fire({
            text: t("Are you sure?"),
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('Yes, do it!'),
            cancelButtonText: t('Cancel')
        }).then((result) => {
            if (result.isConfirmed) {

                var formData = new FormData();
                formData.id = data?.data?.id;
                formData.stornieren = (data?.data?.stornieren == 0 ? 1 : data?.data?.stornieren == 1 ? 2 : 0);

                callFetch("project/stornieren", "POST", formData, {}).then((res) => {
                    // setSaving(false);
                    setRefesh3(refesh3 + 1);
                    // if (!res.ok) return;
                });

            }
        });
    }


    //plz and ort load
    useEffect(() => {
        /*
        callFetch("address/zipcode", "GET", []).then((res) => {
            if (res.message == 'success') {
                setZipcode(res.data);
            }
        });
        */
    }, [refresh]);


    //Edit Data load
    useEffect(() => {
        flatpickr(".flat-pickr");

        if (Cookies.get('permissions').indexOf("project_call_center-update") !== -1) {
            setReadOnly(false);
        } else {
            setReadOnly(true);
        }


        if (props?.update && props.update == true && params?.id) {
            setCreate(false);

            //callFetch("projects/" + params.id + "/edit?date=" + getValues('date') + '&time=' + getValues('time'), "GET", []).then((res) => {
            callFetch("projects/" + params.id + "/edit", "GET", []).then((res) => {
                setData(res);
                for (let [key, value] of Object.entries(res.data)) {
                    if (key == 'date') {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    } else if (key == 'plz') {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    } else {
                        setValue(key, (value == null || value == 'null' ? "" : value));
                    }
                }
                setValue('full_address', res?.data?.street + ' ' + res?.data?.nr);
            });
        }
    }, [params?.id, refesh3]);

    //verkaufer id set
    useEffect(() => {
        setValue('verkaufer', data?.data?.verkaufer);
    }, [data]);

    //zipcode fetch
    useEffect(() => {
        for (let [zipcode_key, zipcode_value] of Object.entries(zipcode)) {
            if (zipcode_value.label == getValues('plz')) {
                setSelectedPlz(zipcode_value)
            }
        }
    }, [params.id, zipcode, refresh, getValues('plz')]);

    //verkaufer fetch base on date time
    const verkauferFeatch = () => {
        if (params?.id && props?.update && props.update == true) {
            callFetch("projects/" + params?.id + "/edit?date=" + getValues('date') + '&time=' + getValues('time'), "GET", []).then((res) => {
                setData({
                    employees: res.employees
                })
            });
        } else {
            callFetch("projects/create?date=" + getValues('date') + '&time=' + getValues('time'), "GET", []).then((res) => {
                setData(res.data);
            });
        }
    }

    //Form Submit
    const onSubmit = (formData) => {
        setSaving(true);
        var loadingElement = document.querySelector('.res-options .btn-primary-save');
        loadingElement.classList.add("btn-secondary");
        loadingElement.innerHTML = t('wird geladen...');

        callFetch(create == true ? "projects" : "projects/" + params?.id, "POST", formData, setError).then((res) => {
            setRefresh2(refresh2 + 1);

            if (res.project_id) {
                setProjectId(res.project_id);
            }

            setTimeout(() => {
                setSaving(false);

                loadingElement.classList.remove("btn-secondary");
                loadingElement.innerHTML = t('Save');

                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                document.getElementById("main-content").scrollTop = 0;

                setSuccessSB(true);

                if (!res.ok) return;
                setSubmitSuccess(true);
            }, 2000);
        });
    };

    useEffect(() => {
        if (params?.status == 'new') {
            setSuccessSB(true);
        }
    }, [params?.status]);

    if (loadError) return "Error loading maps"
    if (!isLoaded) return;// "Loading Maps"


    return submitSuccess && create ? <Navigate to={'/project-management/project/edit/' + projectId + '/new'} /> :
        <>

            <div className="row mb-5">
                <div className="col-lg-9 mt-lg-0">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="emb-4">
                                <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                    {create == false ? (
                                        <input type="hidden" defaultValue="PUT" {...register("_method")} />
                                    ) : <></>}
                                    <div className="row g-3">
                                        <div className="col-md-4">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label htmlFor="example-date-input" className="form-control-label">{t('Date')}</label>

                                                        <input className="form-control"
                                                            {...register("date", {
                                                                required: true,
                                                                disabled: readOnly,
                                                                onChange: (e) => {
                                                                    verkauferFeatch();
                                                                }
                                                            })}
                                                            required
                                                            type="date" id="example-date-input" />
                                                    </div>

                                                    <div className="form-group">
                                                        <label>
                                                            {t('Time')} *
                                                        </label>
                                                        <br />
                                                        <select
                                                            className="form-control"
                                                            {...register("time", {
                                                                required: true,
                                                                disabled: readOnly,
                                                                onChange: (e) => {
                                                                    verkauferFeatch();
                                                                }
                                                            })}
                                                            required
                                                        >
                                                            <option value="08:00">08:00 - 10:00</option>
                                                            <option value="10:00">10:00 - 12:00</option>
                                                            <option value="12:00">12:00 - 14:00</option>
                                                            <option value="14:00">14:00 - 16:00</option>
                                                            <option value="16:00">16:00 - 18:00</option>
                                                            <option value="18:00">18:00 - 20:00</option>
                                                        </select>
                                                        <div className="invalid-feedback">{errors.quotation_id && errors.quotation_id.message}</div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label>
                                                            {t('Verkaufer')} *
                                                        </label>
                                                        <br />
                                                        <select
                                                            className="form-control"
                                                            {...register("verkaufer", {
                                                                required: true,
                                                                disabled: readOnly,
                                                            })}
                                                            required
                                                        >
                                                            <option value="">--</option>
                                                            {data && data.employees ? data.employees.map((employee) => (
                                                                <option key={employee.user.id} value={employee.user.id}>{employee.user.name}</option>
                                                            )) : <></>}
                                                        </select>
                                                        <div className="invalid-feedback">{errors.quotation_id && errors.quotation_id.message}</div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label>
                                                            {t('Beschreibung')}
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            rows="5"
                                                            placeholder={t('eg. 1234 My street, City')}
                                                            {...register("description", {
                                                                disabled: readOnly,
                                                            })}></textarea>
                                                        <div className="invalid-feedback">{errors.address && errors.address.message}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label>{t('Anrede')} *</label>
                                                                <select
                                                                    className="form-control"
                                                                    {...register("anrede", {
                                                                        required: true,
                                                                        disabled: readOnly,
                                                                    })}
                                                                    required>
                                                                    <option value="">--</option>
                                                                    <option value="Herr">{t('Herr')}</option>
                                                                    <option value="Frau">{t('Frau')}</option>
                                                                </select>
                                                                <div className="invalid-feedback">{errors.anrede && errors.anrede.message}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <div className="form-group">
                                                                <label>{t('Vorname')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("vorname", {
                                                                        required: true,
                                                                        disabled: readOnly,
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.vorname && errors.vorname.message}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <div className="form-group">
                                                                <label>{t('Name')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("name", {
                                                                        required: true,
                                                                        disabled: readOnly,
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-9">
                                                            <Autocomplete
                                                                options={{
                                                                    componentRestrictions: { country: "de" },
                                                                    //fields: ["address_components", "geometry", "icon", "name"],
                                                                    //types: ["establishment"]
                                                                }}
                                                                onLoad={onLoad}
                                                                onPlaceChanged={onPlaceChanged}
                                                            >
                                                                <div className="form-group">
                                                                    <label>{t('Straße')} *</label>
                                                                    <input
                                                                        type="text"
                                                                        placeholder={t('')}
                                                                        className="form-control"
                                                                        //defaultValue={getValues('street') ? getValues('street') + ' ' + getValues('nr') : ''}
                                                                        {...register("full_address", {
                                                                            required: false,
                                                                            disabled: readOnly,
                                                                        })}

                                                                    />
                                                                    <div className="invalid-feedback d-block">{errors.street ? errors.street.message : (errors.nr ? t('Please add a street nr.') : '')}</div>
                                                                </div>
                                                            </Autocomplete>
                                                            <div className="form-group d-none">
                                                                <label>{t('Straße')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("street", {
                                                                        required: true,
                                                                        disabled: readOnly,
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.street && errors.street.message}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>{t('Nr')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("nr", {
                                                                        required: false,
                                                                        disabled: readOnly,
                                                                    })}
                                                                    readOnly
                                                                />
                                                                <div className="invalid-feedback">{errors.nr && errors.nr.message}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('PLZ')} *</label>

                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("plz", {
                                                                        required: false,
                                                                        disabled: readOnly,
                                                                    })}
                                                                    readOnly
                                                                />
                                                                <div className="invalid-feedback">{errors.plz && errors.plz.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Ort')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("ort", {
                                                                        required: false,
                                                                        disabled: readOnly,
                                                                    })}
                                                                    readOnly
                                                                />
                                                                <div className="invalid-feedback">{errors.ort && errors.ort.message}</div>
                                                            </div>
                                                        </div>


                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Firma')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("firma", {
                                                                        disabled: readOnly,
                                                                    })} />
                                                                <div className="invalid-feedback">{errors.firma && errors.firma.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('E-mail Adresse')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("email", {
                                                                        required: false,
                                                                        disabled: readOnly,
                                                                        onChange: (e) => {
                                                                            //setCustomEmail(e.target.value);
                                                                        }
                                                                    })}
                                                                />
                                                                <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                                            </div>
                                                        </div>

                                                        {Cookies.get('permissions').indexOf("Can See Contact Info") !== -1 ? (
                                                            <>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t('Telefonnummer')}</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={t('')}
                                                                            {...register("telefonnummer", {
                                                                                required: false,
                                                                                disabled: readOnly,
                                                                            })}
                                                                        />
                                                                        <div className="invalid-feedback">{errors.telefonnummer && errors.telefonnummer.message}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{t('Mobilnummer')}</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={t('')}
                                                                            {...register("mobilnummer", {
                                                                                required: false,
                                                                                disabled: readOnly,
                                                                            })}
                                                                        />
                                                                        <div className="invalid-feedback">{errors.mobilnummer && errors.mobilnummer.message}</div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : ''}

                                                    </div>
                                                </div>
                                            </div>
                             
                             
                                            <div className="card mt-3">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Entscheidungstrager')}</label>
                                                                <input
                                                                    type="text"
                                                                    placeholder="Entscheidungstrager"
                                                                    className=" form-control"
                                                                    {...register("entscheidungstrager", {
                                                                        required: false,
                                                                        disabled: readOnly,
                                                                    })}
                                                                />
                                                                <div className="invalid-feedback">{errors.entscheidungstrager && errors.entscheidungstrager.message}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Erfahrung Mit PV')}</label>
                                                                <select
                                                                    className="form-control"
                                                                    {...register("erfahrung_mit_pv", {
                                                                        disabled: readOnly,
                                                                    })}
                                                                >
                                                                    <option value="">--</option>
                                                                    <option value="Yes">{t('Yes')}</option>
                                                                    <option value="No">{t('No')}</option>
                                                                </select>
                                                                <div className="invalid-feedback">{errors.erfahrung_mit_pv && errors.erfahrung_mit_pv.message}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Gesamtverbrauch Kwh')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('Gesamtverbrauch Kwh')}
                                                                    {...register("gesamtverbrauch_kwh", {
                                                                        disabled: readOnly,
                                                                    })}

                                                                />
                                                                <div className="invalid-feedback">{errors.gesamtverbrauch_kwh && errors.gesamtverbrauch_kwh.message}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Monatlicher Abschlag In Euro')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('Monatlicher Abschlag In Euro')}
                                                                    {...register("monatlicher", {
                                                                        disabled: readOnly,
                                                                    })}

                                                                />
                                                                <div className="invalid-feedback">{errors.monatlicher && errors.monatlicher.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Stromabrechung Vorhanden')}</label>
                                                                <select className="form-control"
                                                                    {...register("stromabrechung_vorhanden", {
                                                                        disabled: readOnly,
                                                                    })}
                                                                >
                                                                    <option>--</option>
                                                                    <option value="Yes" >{t('Yes')}</option>
                                                                    <option value="No" >{t('No')}</option>
                                                                </select>
                                                                <div className="invalid-feedback">{errors.stromabrechung_vorhanden && errors.stromabrechung_vorhanden.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Dachausrichtung')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("dachausrichtung", {
                                                                        disabled: readOnly,
                                                                    })}

                                                                />
                                                                <div className="invalid-feedback">{errors.dachausrichtung && errors.dachausrichtung.message}</div>
                                                            </div>
                                                        </div>


                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Dacheindeckung')}</label>
                                                                <select
                                                                    className="form-control"
                                                                    {...register("ziegelart", {
                                                                        required: false,
                                                                        disabled: readOnly,
                                                                    })}
                                                                >
                                                                    <option value="">--</option>
                                                                    <option value="Sonstiges">{t('Sonstiges')}</option>
                                                                    <option value="Ziegel">{t('Ziegel')}</option>
                                                                    <option value="Blech">{t('Blech')}</option>
                                                                    <option value="Kies">{t('Kies')}</option>
                                                                    <option value="Schiefer">{t('Schiefer')}</option>
                                                                    <option value="Schindel">{t('Schindel')}</option>
                                                                    <option value="Betumen">{t('Betumen')}</option>
                                                                    <option value="Gründach">{t('Gründach')}</option>
                                                                </select>
                                                                <div className="invalid-feedback">{errors.dacheindeckung && errors.dacheindeckung.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('E-Mobilitat')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('E-Mobilitat')}
                                                                    {...register("e_mobilitat", {
                                                                        disabled: readOnly,
                                                                    })}

                                                                />
                                                                <div className="invalid-feedback">{errors.e_mobilitat && errors.e_mobilitat.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Zeitraum (max.)')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('Zeitraum (max.)')}
                                                                    {...register("zeitraum_max", {
                                                                        disabled: readOnly,
                                                                    })}

                                                                />
                                                                <div className="invalid-feedback">{errors.zeitraum_max && errors.zeitraum_max.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Internet')}</label>
                                                                <select
                                                                    className="form-control"
                                                                    {...register("internet", {
                                                                        disabled: readOnly,
                                                                    })}
                                                                >
                                                                    <option value="">--</option>
                                                                    <option value="Yes">{t('Yes')}</option>
                                                                    <option value="No">{t('No')}</option>
                                                                </select>
                                                                <div className="invalid-feedback">{errors.internet && errors.internet.message}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {Cookies.get('permissions').indexOf("project_call_center-update") !== -1 ? (
                                        <div className="row g-3">
                                            <div className="col-12 my-4">
                                                {!saving && (
                                                    <button type="submit" className="btn btn-primary" id="callSubmitBtn">
                                                        {t('Save')}
                                                    </button>
                                                )}
                                                {saving && (
                                                    <button type="button" className="btn btn-secondary" disabled>
                                                        {t('wird geladen...')}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    ) : <></>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-body p-3">
                                    <div className="d-flex mb-3">
                                        <div>
                                            <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="ms-3">
                                            <div className="numbers">
                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Project Nr')}:</p>
                                                <h5 className="font-weight-bolder mb-0">
                                                    {params?.id ? params?.id : '-'}
                                                </h5>
                                            </div>
                                        </div>

                                        {data?.data?.id && data?.data?.stornieren != 0 ? (
                                            <div className="text-end ms-3 mt-2">
                                                <button
                                                    className={"btn mb-0 " + (data?.data?.stornieren == 1 ? "btn-danger" : data?.data?.stornieren == 2 ? "btn-success" : "")}
                                                    onClick={(e) => {
                                                        storno(e);
                                                    }}
                                                >{t(data?.data?.stornieren == 1 ? "Projekt Storniert" : data?.data?.stornieren == 2 ? "Storno aufgehoben" : "Stornieren")}</button>
                                            </div>
                                        ) : ''}
                                    </div>
                                </div>
                            </div>

                            <div className="card mb-3">
                                <div className="card-header pb-1">
                                    <h6>{t('Status')}</h6>
                                </div>
                                <div className="card-body pt-1">
                                    {params?.id ? <ProjectStatus projectid={params?.id} status={data?.data?.status} /> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Timeline refresh={refresh2} />
                </div>
                {renderSuccessSB}
            </div>

            <button className="d-none" id="callcenterRefesh3" onClick={() => { setRefesh3(refesh3 + 1); }}>refesh3</button>
        </>;
}

export default CallCenter;