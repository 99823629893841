import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import callFetch from "../../../helpers/callFetch";
import Cookies from "js-cookie";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import dateFormat, { masks } from "dateformat";
import Timeline from "../reclamation/Timeline";
import CheckList from "./CheckList";
import Edit from "./Edit";

function Show() {
  let params = useParams();
  const { t } = useTranslation();
  const [attachments, setAttachment] = useState([]);
  const [items, setItems] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [userrec, setUserRec] = useState([]);
  const [projectrec, setProjectRec] = useState([]);
  const [checkLists, setCheckLists] = useState([]);
  const [saving, setSaving] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const hideReclamationModal = () => {
    var manageCheckListModal = document.getElementById("createCheckListModalOpen");
    manageCheckListModal.click();
  };

  useEffect(() => {

    if (!params.id) return;

    callFetch("ticket/" + params.id, "GET", []).then((res) => {
      setData(res.data);
      if (res?.data?.attachments) {
        setAttachment(JSON.parse(res?.data?.attachments));
      }
    }
    );
  }, [params.id, refresh]);

  const statusUpdate = () => {

    var formData = {}
    formData.status = (data?.status ? 0 : 1);
    formData._method = 'PUT';

    setSaving(true);
    callFetch("ticket/" + params.id, "POST", formData, setError).then((res) => {
      setSaving(false);
      setRefresh(refresh + 1);
      if (!res.ok) return;
    });

  }

  return (
    <>
      <Grid container >
        <Grid item xs={12} lg={9}>
          <Card>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={3} lg={3}>
                {" "}
              </Grid>
              <Grid item xs={12} lg={12}>
                <SoftBox px={2}>
                  <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SoftBox>
                      <SoftBox mb={1}>
                        <SoftTypography variant="h6" fontWeight="bold">
                          {data?.title}
                        </SoftTypography>
                      </SoftBox>
                      <SoftTypography
                        component="p"
                        variant="button"
                        fontWeight="medium"
                        color="black"
                      >
                        Priorität ist{" "}
                        {data?.priority == 'Urgend' ?
                          <span style={{ color: "#E53E3E" }}>{t(data?.priority)}</span>
                          : data?.priority == 'Normal' ?
                            <span style={{ color: "#CB0C9F" }}>{t(data?.priority)}</span>
                            : data?.priority == 'Not Important' ?
                              <span style={{ color: "#82D616" }}>{t(data?.priority)}</span>
                              : ''}
                        {" "}
                        - Reklamation erstellt von{" "}
                        <span style={{ color: "#0048B1" }}>
                          {data?.user?.name}
                        </span>
                        {/* <span sx={{ fontWeight: "bold" }}>241342</span> from
                            <span sx={{ fontWeight: "bold" }}>23.02.2021</span> */}
                      </SoftTypography>
                    </SoftBox>
                    <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModalEdit" class="btn btn-outline-secondary px-5">
                      Bearbeiten
                    </a>
                  </SoftBox>
                  <div
                    style={{
                      backgroundColor: "#eee",
                      width: "100%",
                      height: "1px",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                  ></div>
                </SoftBox>

                <Grid container justifyContent="center">
                  <Grid item xs={12} lg={8}>
                    <div className="mt-0">
                      {data?.status == 1 ? (
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => {
                            statusUpdate();
                          }}
                          style={{
                            marginLeft: "15px",
                            backgroundColor: "#48BB78",
                            boxShadow: "none",
                          }}
                        >
                          <i className="fa fa-check"></i> &nbsp;&nbsp; Erledigt
                        </button>

                      ) : (<>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            statusUpdate();
                          }}
                          style={{
                            marginLeft: "15px",
                            boxShadow: "none",
                          }}
                        >
                          <i className="fa fa-check"></i> &nbsp;&nbsp; {t('Open')}
                        </button>
                      </>)}


                      <NavLink
                        to="/reclamation/index"
                        className="btn btn-primary ms-3"
                      >
                        Reklamation Generieren
                      </NavLink>
                    </div>


                    <div className="mt-5" style={{ marginLeft: "15px", paddingRight: "15px" }}>
                      <SoftTypography
                        variant="button"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Beschreibung
                      </SoftTypography>{" "}

                      <br />
                      <SoftTypography
                        variant="p"
                        className="text-justify"
                        color="text"
                        style={{ textAlign: "justify" }}
                      >
                        {data && data.description}
                      </SoftTypography>
                    </div>
                    <div className="mt-5" style={{ marginLeft: "15px", paddingRight: "15px" }}>
                      <a
                        href="#"
                        onClick={hideReclamationModal}
                        id="checkListModalOpen"
                        style={{ fontSize: "12px" }}

                      >
                        <i
                          class="fa-solid fa-plus plus-icon"
                          style={{
                            backgroundColor: "#0048B1",
                            color: "#fff",
                            borderRadius: "100%",
                            fontWeight: "900",
                            width: "15px",
                            height: "15px",
                            textAlign: "center",
                            paddingTop: "2px",
                          }}
                        ></i>{" "}
                        <span style={{ color: "#0048B1", fontWeight: "600" }}>
                          Checklisten Eintrag
                        </span>{" "}
                      </a>


                      <div className="row mt-2">
                        <div className="col-sm-12">
                          {data?.checklists && data?.checklists.map((checklist) => (
                            <div class="form-check">
                              <input class="form-check-input" {...register("is_product_related_complain")} type="checkbox" id={"customCheck-" + checklist.id} />
                              <SoftTypography
                                variant="p"
                                className="text-justify"
                                color="text"
                                style={{ textAlign: "justify" }}
                              >
                                {checklist.title}
                              </SoftTypography>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>


                    <SoftBox p={3} className="order-processing mt-3">
                      <a
                        href="#"
                        style={{ fontSize: "12px" }}

                      >
                        <span style={{ color: "#0048B1", fontWeight: "600" }}>
                          {t('Attachements')}
                        </span>{" "}
                      </a>
                      <Grid container spacing={3}>
                        {attachments.map(function (data, i) {
                          return (
                            <Grid item key={i} className="text-center">
                              <div>
                                <a target="_blank" download href={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + data?.attachment}>
                                  <img src={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + data?.attachment} />
                                </a>
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} lg={4} style={{ paddingRight: "15px" }}>
                    <SoftBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor="grey-100"
                      borderRadius="lg"
                      p={3}
                      mt={2}
                      mb={3}
                    >
                      <SoftBox
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        lineHeight={1}
                      >
                        <SoftBox mb={2}>
                          <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            textTransform="capitalize"
                          >
                            Ticket Nr.: T-{data && data.id}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox mb={1} lineHeight={0}>
                          <SoftTypography variant="caption" color="text">
                            Projekt {' '}
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                              textTransform="capitalize"
                            >
                              <NavLink to={'/project-management/project/edit/' + data?.project_id} className="text-primary">{data?.project_id}</NavLink>
                            </SoftTypography>{" "}
                            vom{" "}
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                              textTransform="capitalize"
                            >
                              23.02.2021
                            </SoftTypography>
                            &nbsp;&nbsp;&nbsp;
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox mb={1} lineHeight={0}>
                          <SoftTypography variant="caption" color="text">
                            Kunde:&nbsp;&nbsp;&nbsp;
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                            >
                              Andrse Khan
                            </SoftTypography>
                          </SoftTypography>
                        </SoftBox>
                        <SoftTypography variant="caption" color="text" mt={2}>
                          Start Datum:&nbsp;&nbsp;&nbsp;
                          <SoftTypography variant="caption" fontWeight="medium">
                            {dateFormat(data && data.start_date, "dd.mm.yyyy")}
                          </SoftTypography>
                        </SoftTypography>
                        <SoftTypography variant="caption" color="text">
                          End Datum:&nbsp;&nbsp;&nbsp;
                          <SoftTypography variant="caption" fontWeight="medium">
                            {dateFormat(data && data.end_date, "dd.mm.yyyy")}
                          </SoftTypography>
                        </SoftTypography>

                        <SoftBox mb={1} mt={3}>
                          <SoftTypography
                            variant="button"
                            fontWeight="medium"
                            textTransform="capitalize"
                          >
                            Status Information
                          </SoftTypography>
                        </SoftBox>

                        <SoftBox mb={1} lineHeight={0}>
                          <SoftTypography variant="caption" color="text">
                            <SoftTypography
                              variant="caption"
                              fontWeight="medium"
                              textTransform="capitalize"
                            >
                              Erteilt von Cengiz T.{" "}
                            </SoftTypography>
                          </SoftTypography>
                        </SoftBox>

                        <Timeline refresh={refresh} projectid={data?.project_id} />
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={3} lg={2}>
          {" "}
        </Grid>
      </Grid>


      {/* checklist modal */}
      <button type="button" class="btn bg-gradient-primary d-none" data-bs-toggle="modal" id="createCheckListModalOpen" data-bs-target="#checklistModal"></button>
      <button type="button" class="btn bg-gradient-primary d-none" data-bs-toggle="modal" id="editReclamationModalOpen" data-bs-target="#exampleModalEdit"></button>
      <CheckList refresh={setRefresh} checkLists={data?.checklists} id={params.id} />
      <Edit refresh={setRefresh} id={params.id} />


    </>
  );
}

export default Show;
