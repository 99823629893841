import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams, NavLink } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';

// @mui core components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import EmailForm from "./EmailForm";
import Timeline from "./Timeline";
import Select from 'react-select';

import ProjectDistribution from './Distribution/Index';
import Overview from '../Overview';
import SoftSnackbar from "components/SoftSnackbar";

import Cookies from 'js-cookie';

function Distribution(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [speichers, setSpeichers] = useState([]);
    const [modules, setModules] = useState([]);
    const [wechselrichters, setWechselrichters] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [redirectRoute, setRedirectRoute] = useState("/sales-distribution/quations");
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [projectdistribution_id, setProjectdistribution_id] = useState(0);
    const [editDelay, setEditDelay] = useState(0);
    const [data, setData] = useState(false);
    const [zusatzvereinbarungen, setZusatzvereinbarungen] = useState(null);
    const [meter_location_floor, setMeter_location_floor] = useState(null);
    const [house_connection_location, setHouse_connection_location] = useState(null);
    const [storage_wrspace, setStorage_wrspace] = useState(null);
    const [show, setShow] = useState(0);
    const [radio, setRadio] = useState(true)
    const [speicherEingeben, setSpeicherEingeben] = useState('no');
    const [moduleEingeben, setModuleEingeben] = useState('no');
    const [wechselrichterEingeben, setWechselrichter] = useState('no');
    const [customEmail, setCustomEmail] = useState('');
    const [readOnly, setReadOnly] = useState(true);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (radio) {
            document.getElementById('nav-home-tab').click()
        }
    }, [radio])

    useEffect(() => {

        if (Cookies.get('permissions').indexOf("project_distribution-update") !== -1) {
            setReadOnly(false);
        } else {
            setReadOnly(true);
        }
        setValue('project_id', params.id);

        callFetch("speicher/all", "GET", []).then((res) => {
            setSpeichers(res.data);
            setEditDelay(editDelay + 1);
        });

        callFetch("wechselrichter/all", "GET", []).then((res) => {
            setWechselrichters(res.data);
            setEditDelay(editDelay + 1);
        });

        callFetch("module/all", "GET", []).then((res) => {
            setModules(res.data);
            setEditDelay(editDelay + 1);
        });
    }, [refresh]);

    useEffect(() => {
        if (editDelay > 0)
            callFetch("projectdistribution/" + params.id + "/edit?project_id=" + params.id, "GET", []).then((res) => {
                setData(res?.data);
                setValue('status', res?.data?.status);
                setValue('project_id', res?.data?.id);

                //get data for Update quation
                if (res?.data?.quations) {
                    setSpeicherEingeben(res?.data?.quations?.speicher_eingeben);
                    setWechselrichter(res?.data?.quations?.wechselrichter_eingeben);
                    setModuleEingeben(res?.data?.quations?.module_eingeben);
                    setCustomEmail(res?.data?.quations?.email);

                    for (let [key, value] of Object.entries(res?.data?.quations)) {
                        if (key == 'netto_brotto') {
                            setShow(Number(value));
                        } else if (key == 'module_description') {
                            if (value != "undefined") {
                                for (let [description_key, description_value] of Object.entries(JSON.parse(value))) {
                                    setValue('module_description[' + description_key + ']', description_value);
                                }
                            }
                        } else if (key != 'user') {
                            setValue(key, (value == 'null' ? '' : value));
                        }
                    }
                    setZusatzvereinbarungen(getValues('zusatzvereinbarungen') ? JSON.parse(getValues('zusatzvereinbarungen')) : null);

                    setMeter_location_floor(getValues('meter_location_floor') ? JSON.parse(getValues('meter_location_floor')) : null);
                    setStorage_wrspace(getValues('storage_wrspace') ? JSON.parse(getValues('storage_wrspace')) : null);
                    setHouse_connection_location(getValues('house_connection_location') ? JSON.parse(getValues('house_connection_location')) : null);

                } else {
                    //get data for create quation

                    setValue('speicher_eingeben', speicherEingeben);
                    setValue('wechselrichter_eingeben', wechselrichterEingeben);
                    setValue('module_eingeben', moduleEingeben);

                    setValue('wirtschaftlichkeitsberechnung', 'Nein');
                    setValue('angebot_per_post_senden', 'Nein');

                    //By default Email Address
                    setValue('email', 'service@bayerische-landeswerke.de');
                    setCustomEmail('service@bayerische-landeswerke.de');

                    //By default Netto
                    setShow(1);
                    setValue('netto_brotto', 1)


                    for (let [key, value] of Object.entries(res.data)) {
                        if (['user', 'quations', 'id', 'user_id'].indexOf(key) == -1) {
                            if (key == 'nr') {
                                setValue('hausnummer', (value == null || value == 'null' ? "" : value));
                            } else if (key == 'ziegelart') {
                                setValue('dacheindeckung', (value == null || value == 'null' ? "" : value));
                            } else {
                                setValue(key, (value == null || value == 'null' ? "" : value));
                            }
                        }
                    }
                }
            });
    }, [editDelay]);

    useEffect(() => {
        setRefresh2(refresh2 + props.refresh);
    }, [props.refresh]);

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={() => closeSuccessSB(false)}
            close={() => closeSuccessSB(false)}
            bgSuccess
        />
    );

    const onSubmit = (formData) => {
        formData.module_description = JSON.stringify(formData.module_description);
        formData.speicher_without_energiespeichersystem = (formData.speicher_without_energiespeichersystem == true ? 1 : 0);

        setSaving(true);

        var loadingElement = document.querySelector('.res-options .btn-primary-save');
        loadingElement.classList.add("btn-secondary");
        loadingElement.innerHTML = t('wird geladen...');

        callFetch((data?.quations?.id ? "projectdistribution/" + params.id : 'projectdistribution'), "POST", formData, setError).then((res) => {
            setRefresh2(refresh2 + 1);
            setEditDelay(editDelay + 1);

            setTimeout(() => {
                setSaving(false);

                loadingElement.classList.remove("btn-secondary");
                loadingElement.innerHTML = t('Save');

                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                document.getElementById("main-content").scrollTop = 0;
                openSuccessSB();

                //if (!res.ok) return;
                //setSubmitSuccess(true);
            }, 2000);
        });
    };

    const onChangeHandeler = (e) => {
        const name = e.target.name;
        if (name == 'eigenverbrauch' || name == 'stromkosten') {
            kwh_preis_calculate();
        }

        if (name == 'angebot_per_post_senden') {
            if (getValues('angebot_per_post_senden') == 'Ja') {
                setValue('email', 'service@bayerische-landeswerke.de');
            } else {
                setValue('email', customEmail);
            }
        }

        if (name == 'gesami_netto') {
            const gesami_netto = getValues('gesami_netto');
            const gesami_brutto = gesami_netto * 1.19;
            setValue('gesami_brutto', gesami_brutto.toFixed(2))
        }
    }

    const kwh_preis_calculate = () => {
        const stromkosten = getValues('stromkosten');
        const eigenverbrauch = getValues('eigenverbrauch');
        const value = stromkosten / eigenverbrauch;
        setValue('kwh_preis', value.toFixed(2))
    }

    const calCulateGesDachflache = () => {

        var module_eingeben = getValues('module_eingeben');
        var anzahl_module = getValues('anzahl_module');
        if (module_eingeben == 'no') {
            for (let [key, value] of Object.entries(modules)) {
                if (value?.id == getValues('modul')) {
                    var moduleDescription = JSON.parse(value.description);
                    var width = moduleDescription?.Modulabmessung_width ? moduleDescription?.Modulabmessung_width : 0;
                    var height = moduleDescription?.Modulabmessung_height ? moduleDescription?.Modulabmessung_height : 0;
                }
            }
        } else {
            var width = getValues('module_description[Modulabmessung_width]');
            var height = getValues('module_description[Modulabmessung_height]');
        }

        var ges_dachflache = (width ? (width / 1000) : 0) * (height ? (height / 1000) : 0) * (anzahl_module ? anzahl_module : 0);
        setValue('ges_dachflache', ges_dachflache.toFixed(2));
    }

    const calCulateAnlagenProduktion = () => {

        var module_eingeben = getValues('module_eingeben');
        var sonnenstunden = getValues('sonnenstunden');
        var anzahl_module = getValues('anzahl_module');
        var own_consumption = getValues('own_consumption');

        if (module_eingeben == 'no') {
            for (let [key, value] of Object.entries(modules)) {
                if (value?.id == getValues('modul')) {
                    var module_watt = value?.watt;
                }
            }
        } else {
            var module_watt = getValues('module_watt');
        }

        var total_plant_production = (sonnenstunden ? sonnenstunden : 0) * ((anzahl_module ? anzahl_module : 0) * (module_watt ? module_watt : 0));
        var total_plant_production = (total_plant_production / 1000);

        var feed_in = total_plant_production - (own_consumption ? own_consumption : 0)
        setValue('total_plant_production', ((total_plant_production).toFixed(2)));
        setValue('feed_in', ((feed_in).toFixed(2)));
    }

    return (
        <>
            <div className="row mb-5">
                <div className="col-lg-9 mt-lg-0">

                    <ProjectDistribution />
                    <Overview project_id={params?.id} title={t('Quotation')} ></Overview>

                    <div className="row mt-3">
                        <div className="col-md-12">
                            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" id="editdst">

                                <input
                                    type="hidden"
                                    className="form-control"
                                    placeholder={t('')}
                                    {...register("project_id")}
                                />

                                {data?.quations?.id ?
                                    <input type="hidden" defaultValue="PUT" {...register("_method")} />
                                    : ''}

                                <Card id="Kontaktinformationen" sx={{ overflow: "visible" }} className="mt-3">
                                    <SoftBox p={3} pb={0}>
                                        <SoftTypography variant="h5">{t('Kontaktinformationen')}</SoftTypography>
                                        <SoftBox>
                                            <div className="form-check mt-3">
                                                <input onClick={() => setRadio(!radio)} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked={radio && true} />
                                                <label onClick={() => setRadio(!radio)} className="form-check-label" for="flexRadioDefault3">
                                                    {t('Invoice Address Same')}
                                                </label>
                                            </div>
                                            <nav>
                                                <div className="nav nav-tabs ms-3" id="nav-tab" role="tablist">
                                                    <button className={`nav-link active`} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true"><SoftTypography variant="button" >Kontaktinformationen</SoftTypography></button>
                                                    <button className={`nav-link ${radio ? 'disabled' : ''}`} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false"><SoftTypography variant="button" >{t('Invoice Addresss')}</SoftTypography></button>
                                                </div>
                                            </nav>
                                        </SoftBox>
                                    </SoftBox>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <SoftBox component="form" pb={3} px={3}>
                                                <div className="row g-3">
                                                    <div className="col-md-8">
                                                        <label>{t('Straße')} *</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={t('')}
                                                            {...register("street", {
                                                                required: true,
                                                                disabled: readOnly
                                                            })}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">{errors.street && errors.street.message}</div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <label>{t('Hausnummer')} *</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={t('')}
                                                            {...register("hausnummer", {
                                                                required: true,
                                                                disabled: readOnly
                                                            })}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">{errors.hausnummer && errors.hausnummer.message}</div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label>{t('PLZ')} *</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={t('')}
                                                            {...register("plz", {
                                                                required: true,
                                                                disabled: readOnly
                                                            })}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">{errors.plz && errors.plz.message}</div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label>{t('Ort')} *</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={t('')}
                                                            {...register("ort", {
                                                                required: true,
                                                                disabled: readOnly
                                                            })}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">{errors.ort && errors.ort.message}</div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label>{t('Telefonnummer')} *</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={t('')}
                                                            {...register("telefonnummer", {
                                                                required: true,
                                                                disabled: readOnly
                                                            })}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">{errors.telefonnummer && errors.telefonnummer.message}</div>
                                                    </div>
                                                </div>
                                            </SoftBox>
                                        </div>
                                        {
                                            radio !== true && <div className={`tab-pane fade ${radio === true && 'd-none'}`} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                                <SoftBox component="form" pb={3} px={3}>
                                                    <div className="row g-3">
                                                        <div className="col-md-12">
                                                            <label>{t('Straße')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("invoice_street", {
                                                                    required: true,
                                                                    disabled: readOnly
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.street && errors.street.message}</div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label>{t('Hausnummer')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("invoice_hausnummer", {
                                                                    required: true,
                                                                    disabled: readOnly
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.invoice_hausnummer && errors.invoice_hausnummer.message}</div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label>{t('PLZ')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("invoice_plz", {
                                                                    required: true,
                                                                    disabled: readOnly
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.invoice_plz && errors.invoice_plz.message}</div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label>{t('Ort')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("invoice_ort", {
                                                                    required: true,
                                                                    disabled: readOnly
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.invoice_ort && errors.invoice_ort.message}</div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label>{t('Telefonnummer')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("invoice_telefonnummer", {
                                                                    required: true,
                                                                    disabled: readOnly
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.invoice_telefonnummer && errors.invoice_telefonnummer.message}</div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label>{t('Mobilnummer')}</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("invoice_mobilnummer", {
                                                                    required: true,
                                                                    disabled: readOnly
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.invoice_mobilnummer && errors.invoice_mobilnummer.message}</div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label>{t('E-mail Adresse')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("invoice_email", {
                                                                    required: true,
                                                                    disabled: readOnly
                                                                })}
                                                            />
                                                            <div className="invalid-feedback">{errors.invoice_email && errors.invoice_email.message}</div>
                                                        </div>
                                                    </div>
                                                </SoftBox>
                                            </div>
                                        }
                                    </div>
                                </Card>

                                <Card id="eckdaten-pv" sx={{ overflow: "visible" }} className="mt-3">
                                    <SoftBox p={3}>
                                        <SoftTypography variant="h5">{t('Eckdaten PV')}</SoftTypography>
                                    </SoftBox>
                                    <SoftBox component="form" pb={3} px={3}>
                                        <div className="row g-3">
                                            <div className="col-md-6">
                                                <label>{t('Stromverbrauch in kwh')} *</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("eigenverbrauch", {
                                                        required: true,
                                                        disabled: readOnly,
                                                        onChange: (e) => onChangeHandeler(e)
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.eigenverbrauch && errors.eigenverbrauch.message}</div>
                                            </div>

                                            <div className="col-md-6">
                                                <label>{t('Stromkosten im Jahr(€)')} *</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("stromkosten", {
                                                        required: true,
                                                        disabled: readOnly,
                                                        onChange: (e) => onChangeHandeler(e)
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.stromkosten && errors.stromkosten.message}</div>
                                            </div>

                                            <div className="col-md-6">
                                                <label>{t('Kwh Preis(€)')} *</label>
                                                <input
                                                    step={0.001}
                                                    type="number"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("kwh_preis", {
                                                        required: true,
                                                        disabled: readOnly
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.kwh_preis && errors.kwh_preis.message}</div>
                                            </div>

                                            <div className="col-md-12">
                                                <label>{t('Speicher')} *</label>
                                                <div className="col-6 col-md-2 d-flex align-items-center justify-content-between" >
                                                    <div
                                                        onClick={
                                                            () => {
                                                                setSpeicherEingeben('no');
                                                                setValue('speicher_eingeben', 'no');
                                                            }
                                                        }
                                                        className="form-check"
                                                    >
                                                        <input className="form-check-input" type="radio" id="speicher_eingeben_no" checked={speicherEingeben === 'no' && true} />
                                                        <label className="form-check-label" for="speicher_eingeben_no">{t('No')}</label>
                                                    </div>

                                                    <div
                                                        onClick={
                                                            () => {
                                                                setSpeicherEingeben('yes');
                                                                setValue('speicher_eingeben', 'yes')
                                                            }
                                                        }
                                                        className="form-check"
                                                    >
                                                        <input className="form-check-input" type="radio" id="speicher_eingeben_yes" checked={speicherEingeben === 'yes' && true} />
                                                        <label className="form-check-label" for="speicher_eingeben_yes">{t('Yes')}</label>
                                                    </div>
                                                </div>

                                                {speicherEingeben === 'no' ? (
                                                    <div className="form-group">
                                                        <label>{t('Speicher')} </label>
                                                        <select
                                                            className="form-control"
                                                            {...register("speicher", {
                                                                required: false,
                                                                disabled: readOnly
                                                            })}>
                                                            <option value="">--</option>
                                                            {speichers && speichers.map((speicher) => (
                                                                <option key={speicher.id} value={speicher.id}>{speicher.name}</option>
                                                            ))}
                                                        </select>
                                                        <div className="invalid-feedback">{errors.speicher && errors.speicher.message}</div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="form-group">
                                                            <label>{t('Speicher Titel')} </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("speicher_titel", {
                                                                    required: false,
                                                                    disabled: readOnly
                                                                })}
                                                                required
                                                            />
                                                            <div className="invalid-feedback">{errors.speicher_titel && errors.speicher_titel.message}</div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label>{t('Speicher Description')}</label>
                                                            <textarea
                                                                className="form-control"
                                                                rows={4}
                                                                {...register('speicher_description', {
                                                                    required: false,
                                                                    disabled: readOnly
                                                                })}
                                                            ></textarea>
                                                        </div>

                                                        <div className="form-check">
                                                            <label>{t('Ohne Energiespeicher')}</label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox" value={""}
                                                                {...register("speicher_without_energiespeichersystem", {
                                                                    disabled: readOnly
                                                                })}
                                                            />
                                                        </div>
                                                        <div className="invalid-feedback">{errors.speicher_without_energiespeichersystem && errors.speicher_without_energiespeichersystem.message}</div>
                                                    </>
                                                )}
                                            </div>

                                            <div className="col-md-12">
                                                <label>{t('Wechselrichter')}</label>
                                                <div className="col-6 col-md-2 d-flex align-items-center justify-content-between" >
                                                    <div
                                                        onClick={
                                                            () => {
                                                                setWechselrichter('no');
                                                                setValue('wechselrichter_eingeben', 'no');
                                                            }
                                                        }
                                                        className="form-check"
                                                    >
                                                        <input className="form-check-input" type="radio" id="wechselrichter_eingeben_no" checked={wechselrichterEingeben === 'no' && true} />
                                                        <label className="form-check-label" for="wechselrichter_eingeben_no">{t('No')}</label>
                                                    </div>

                                                    <div
                                                        onClick={
                                                            () => {
                                                                setWechselrichter('yes');
                                                                setValue('wechselrichter_eingeben', 'yes')
                                                            }
                                                        }
                                                        className="form-check"
                                                    >
                                                        <input className="form-check-input" type="radio" id="wechselrichter_eingeben_yes" checked={wechselrichterEingeben === 'yes' && true} />
                                                        <label className="form-check-label" for="wechselrichter_eingeben_yes">{t('Yes')}</label>
                                                    </div>
                                                </div>

                                                {wechselrichterEingeben === 'no' ? (
                                                    <div className="form-group">
                                                        <label>{t('Wechselrichter (Kein Pflichtfeld)')}</label>
                                                        <select
                                                            className="form-control"
                                                            {...register("wechselrichter_id", {
                                                                required: false,
                                                                disabled: readOnly
                                                            })}>
                                                            <option value="">--</option>
                                                            {wechselrichters && wechselrichters.map((wechselrichter) => (
                                                                <option key={wechselrichter.id} value={wechselrichter.id}>{wechselrichter.name}</option>
                                                            ))}
                                                        </select>
                                                        <div className="invalid-feedback">{errors.wechselrichter_id && errors.wechselrichter_id.message}</div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="form-group">
                                                            <label>{t('Wechselrichter Titel')}</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("wechselrichter_titel", {
                                                                    required: false,
                                                                    disabled: readOnly
                                                                })}

                                                            />
                                                            <div className="invalid-feedback">{errors.wechselrichter_titel && errors.wechselrichter_titel.message}</div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label>{t('Wechselrichter Description')}</label>
                                                            <textarea
                                                                className="form-control"
                                                                rows={4}
                                                                {...register('wechselrichter_description', {
                                                                    required: false,
                                                                    disabled: readOnly
                                                                })}
                                                            ></textarea>
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                            <div className="col-md-12">
                                                <label>{t('Modul')} *</label>
                                                <div className="col-6 col-md-2 d-flex align-items-center justify-content-between" >
                                                    <div
                                                        onClick={
                                                            () => {
                                                                setModuleEingeben('no');
                                                                setValue('module_eingeben', 'no');
                                                                calCulateGesDachflache();
                                                                calCulateAnlagenProduktion();
                                                            }
                                                        }
                                                        className="form-check"
                                                    >
                                                        <input className="form-check-input" type="radio" id="moduleEingeben_no" checked={moduleEingeben === 'no' && true} />
                                                        <label className="form-check-label" for="moduleEingeben_no">{t('No')}</label>
                                                    </div>

                                                    <div
                                                        onClick={
                                                            () => {
                                                                setModuleEingeben('yes');
                                                                setValue('module_eingeben', 'yes');
                                                                calCulateGesDachflache();
                                                                calCulateAnlagenProduktion();
                                                            }
                                                        }
                                                        className="form-check"
                                                    >
                                                        <input className="form-check-input" type="radio" id="moduleEingeben_yes" checked={moduleEingeben === 'yes' && true} />
                                                        <label className="form-check-label" for="moduleEingeben_yes">{t('Yes')}</label>
                                                    </div>
                                                </div>

                                                {moduleEingeben === 'no' ? (
                                                    <div className="form-group">
                                                        <label>{t('Modul')} *</label>
                                                        <select
                                                            className="form-control"
                                                            {...register("modul", {
                                                                required: true,
                                                                disabled: readOnly,
                                                                onChange: (e) => {
                                                                    calCulateGesDachflache();
                                                                    calCulateAnlagenProduktion();
                                                                }
                                                            })}
                                                            required
                                                        >
                                                            <option value="">--</option>
                                                            {modules && modules.map((modul) => (
                                                                <option key={modul.id} value={modul.id}>{modul.name}</option>
                                                            ))}
                                                        </select>
                                                        <div className="invalid-feedback">{errors.modul && errors.modul.message}</div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="row g-3">
                                                            <div className="col-md-4">
                                                                <label>{t('Title')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_name", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>{t('Short Title')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_short_title", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.short_itle && errors.short_itle.message}</div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>{t('Watt')} *</label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_watt", {
                                                                        required: true,
                                                                        disabled: readOnly,
                                                                        onChange: (e) => {
                                                                            calCulateAnlagenProduktion();
                                                                        }
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.watt && errors.watt.message}</div>
                                                            </div>

                                                            {/*
                                                        <div className="col-md-12">
                                                            <label>{t('Description')}</label>
                                                            <textarea
                                                                className="form-control"
                                                                rows="4"
                                                                placeholder={t('')}
                                                                {...register("description")}></textarea>
                                                            <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                                        </div>
                                                    */}

                                                            <div className="col-md-12"></div>

                                                            <div className="col-md-4">
                                                                <label>{t('Markenhersteller')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[Markenhersteller]", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>{t('Typ')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[Typ]", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>{t('Zelltyp')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[Zelltyp]", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>{t('Leistungstoleranz')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[Leistungstoleranz]", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label>{t('Modulabmessung') + ' (' + t('Width') + ')'}</label>
                                                                        <input
                                                                            type="number"
                                                                            step={0.01}
                                                                            className="form-control mb-4"
                                                                            placeholder={t('')}
                                                                            {...register("module_description[Modulabmessung_width]", {
                                                                                required: true,
                                                                                disabled: readOnly,
                                                                                onChange: (e) => {
                                                                                    calCulateGesDachflache();
                                                                                }
                                                                            })}
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label>{t('Modulabmessung') + ' (' + t('Height') + ')'}</label>
                                                                        <input
                                                                            type="number"
                                                                            step={0.01}
                                                                            className="form-control mb-4"
                                                                            placeholder={t('')}
                                                                            {...register("module_description[Modulabmessung_height]", {
                                                                                required: true,
                                                                                disabled: readOnly,
                                                                                onChange: (e) => {
                                                                                    calCulateGesDachflache();
                                                                                }
                                                                            })}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>{t('Gewicht')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[Gewicht]", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>{t('Modulwirkungsgrad')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[Modulwirkungsgrad]", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>{t('Max. Druckbelastung')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[MaxDruckbelastung]", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>{t('Leistungsgarantie')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[Leistungsgarantie]", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>{t('Produktgarantie')}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-4"
                                                                    placeholder={t('')}
                                                                    {...register("module_description[Produktgarantie]", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                            <div className="col-md-6">
                                                <label>{t('Anzahl Module')} *</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("anzahl_module", {
                                                        required: true,
                                                        disabled: readOnly,
                                                        onChange: (e) => {
                                                            calCulateGesDachflache();
                                                            calCulateAnlagenProduktion();
                                                        }
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.anzahl_module && errors.anzahl_module.message}</div>
                                            </div>

                                            <div className="col-md-6">
                                                <label>{t('Anzahl der zu belegenden Dächer')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("anzahl_der_dacher", {
                                                        required: true,
                                                        disabled: readOnly
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.anzahl_der_dacher && errors.anzahl_der_dacher.message}</div>
                                            </div>

                                            <div className="col-md-6">
                                                <label>{t('Gesamt Bebauungsflache in qm')} *</label>
                                                <input
                                                    type="number"
                                                    step={0.001}
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("ges_dachflache", {
                                                        required: true,
                                                        disabled: readOnly
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.ges_dachflache && errors.ges_dachflache.message}</div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Dachtyp')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("dachtyp", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })}
                                                        required>
                                                        <option value="">--</option>
                                                        <option value="Sonstiges">{t('Sonstiges')}</option>
                                                        <option value="Satteldach">{t('Satteldach')}</option>
                                                        <option value="Pultdach">{t('Pultdach')}</option>
                                                        <option value="Flachdach">{t('Flachdach')}</option>
                                                        <option value="Walmdach">{t('Walmdach')}</option>
                                                        <option value="2/ Hausdach + Garagendach">{t('2/ Hausdach + Garagendach')}</option>
                                                        <option value="Fasade">{t('Fasade')}</option>
                                                    </select>
                                                    <div className="invalid-feedback">{errors.dachtyp && errors.dachtyp.message}</div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Dachausrichtung')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("dachausrichtung", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })}
                                                        required>
                                                        <option value="">--</option>
                                                        <option value="Süd">{t('Süd')}</option>
                                                        <option value="S-O u. S-W">{t('S-O u. S-W')}</option>
                                                        <option value="Süd West">{t('Süd West')}</option>
                                                        <option value="West Süd">{t('West Süd')}</option>
                                                        <option value="West">{t('West')}</option>
                                                        <option value="WNW">{t('WNW')}</option>
                                                        <option value="SSO">{t('SSO')}</option>
                                                        <option value="Süd Ost">{t('Süd Ost')}</option>
                                                        <option value="OSO">{t('OSO')}</option>
                                                        <option value="Ost">{t('Ost')}</option>
                                                        <option value="ONO">{t('ONO')}</option>
                                                        <option value="Nord">{t('Nord')}</option>
                                                        <option value="Nord-Ost">{t('Nord-Ost')}</option>
                                                        <option value="Nord-West">{t('Nord-West')}</option>
                                                        <option value="Ost-West">{t('Ost-West')}</option>
                                                        <option value="Süd u. Nord">{t('Süd u. Nord')}</option>
                                                        <option value="West u. Ost">{t('West u. Ost')}</option>
                                                        <option value="Süd & Ost & West">{t('Süd & Ost & West')}</option>
                                                    </select>
                                                    <div className="invalid-feedback">{errors.dachausrichtung && errors.dachausrichtung.message}</div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Dacheindeckung')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("dacheindeckung", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })}
                                                        required>
                                                        <option value="">--</option>
                                                        <option value="Sonstiges">{t('Sonstiges')}</option>
                                                        <option value="Ziegel">{t('Ziegel')}</option>
                                                        <option value="Blech">{t('Blech')}</option>
                                                        <option value="Kies">{t('Kies')}</option>
                                                        <option value="Schiefer">{t('Schiefer')}</option>
                                                        <option value="Schindel">{t('Schindel')}</option>
                                                        <option value="Betumen">{t('Betumen')}</option>
                                                        <option value="Gründach">{t('Gründach')}</option>
                                                    </select>
                                                    <div className="invalid-feedback">{errors.dacheindeckung && errors.dacheindeckung.message}</div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Zusatzvereinbarungen')}</label>
                                                    <Select
                                                        placeholder={t('Select')}
                                                        options={[
                                                            { value: 'Zwischenzähler', label: 'Zwischenzähler' },
                                                            { value: 'Power to Heat', label: 'Power to Heat' },
                                                            { value: 'inkl. Technikpaket', label: 'inkl. Technikpaket' },
                                                            { value: 'inkl. Garantieverlängerungen auf 20 Jahre SENEC', label: 'inkl. Garantieverlängerungen auf 20 Jahre SENEC' },
                                                            { value: 'Zählerzusammenlegung', label: 'Zählerzusammenlegung' },
                                                            { value: 'inkl. Aufständerung Ost/West', label: 'inkl. Aufständerung Ost/West' },
                                                            { value: 'inkl. Aufständerung Süd', label: 'inkl. Aufständerung Süd' },
                                                            { value: 'Steuerberatergutschein SENEC', label: 'Steuerberatergutschein SENEC' },
                                                            { value: 'inkl. Kreuzverbund', label: 'inkl. Kreuzverbund' },
                                                            { value: 'inkl. Schlüsselfertiger Preis / All in one Paket', label: 'inkl. Schlüsselfertiger Preis / All in one Paket' },
                                                            { value: 'inkl. Blechziegel', label: 'inkl. Blechziegel' },
                                                            { value: 'TIGO Optimierer', label: 'TIGO Optimierer' },
                                                            { value: 'inkl. Notstrombox SENEC', label: 'inkl. Notstrombox SENEC' },
                                                            { value: 'inkl. Notstrombox E3DC', label: 'inkl. Notstrombox E3DC' },
                                                            { value: 'inkl. Wallbox 22kw', label: 'inkl. Wallbox 22kw' },
                                                            { value: 'inkl. Wallbox 11kw', label: 'inkl. Wallbox 11kw' },
                                                            { value: 'Taubenschutz', label: 'Taubenschutz' },
                                                            { value: 'Schneefanggitter', label: 'Schneefanggitter' }
                                                        ]}
                                                        value={zusatzvereinbarungen}
                                                        onChange={(e) => {
                                                            setZusatzvereinbarungen(e);
                                                            setValue('zusatzvereinbarungen', JSON.stringify(e));
                                                        }}
                                                        isMulti
                                                        isDisabled={readOnly}
                                                    />
                                                    <div className="invalid-feedback">{errors.zusatzvereinbarungen && errors.zusatzvereinbarungen.message}</div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Wirtschaftlichkeitsberechnung')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("wirtschaftlichkeitsberechnung", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })}
                                                        required>
                                                        <option value="">--</option>
                                                        <option value="Ja">{t('Ja')}</option>
                                                        <option value="Nein">{t('Nein')}</option>
                                                    </select>
                                                    <div className="invalid-feedback">{errors.wirtschaftlichkeitsberechnung && errors.wirtschaftlichkeitsberechnung.message}</div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Angebot per Post senden')}</label>
                                                    <select required
                                                        className="form-control"
                                                        {...register("angebot_per_post_senden", {
                                                            required: true,
                                                            disabled: readOnly,
                                                            onChange: (e) => onChangeHandeler(e)
                                                        })}>
                                                        <option value="">--</option>
                                                        <option value="Ja">{t('Ja')}</option>
                                                        <option value="Nein">{t('Nein')}</option>
                                                    </select>
                                                    <div className="invalid-feedback">{errors.angebot_per_post_senden && errors.angebot_per_post_senden.message}</div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Anzahl der Leistungsoptimierer')}</label>
                                                    {/*
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        {...register("anzahl_der_leistungsoptimierer", {
                                                            required: false,
                                                        })}
                                                    />
                                                    */}
                                                    <select
                                                        className="form-control"
                                                        {...register("anzahl_der_leistungsoptimierer", {
                                                            required: false,
                                                            disabled: readOnly
                                                        })}
                                                    >
                                                        {[...Array(31)].map((x, i) =>
                                                            <option value={i}>{i}</option>
                                                        )}
                                                    </select>
                                                    <div className="invalid-feedback">{errors.anzahl_der_leistungsoptimierer && errors.anzahl_der_leistungsoptimierer.message}</div>
                                                </div>
                                            </div>

                                            {readOnly == false ? (
                                                <>


                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-6 col-md-2 d-flex align-items-center justify-content-between" >
                                                                <div onClick={() => {
                                                                    setShow(1);
                                                                    setValue('netto_brotto', 1)
                                                                }
                                                                } className="form-check">
                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={show === 1 && true} />
                                                                    <label className="form-check-label" for="flexRadioDefault2">
                                                                        Netto
                                                                    </label>
                                                                </div>

                                                                <div onClick={() => {
                                                                    setShow(0);
                                                                    setValue('netto_brotto', 0)
                                                                }} className="form-check">
                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={show === 0 && true} />
                                                                    <label className="form-check-label" for="flexRadioDefault1">
                                                                        Brutto
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    {show === 0 && <>
                                                        <div className="col-md-6">
                                                            <label>{t('Gesamt Netto')} *</label>
                                                            <div className="input-group mb-3">
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("gesami_netto", {
                                                                        required: true,
                                                                        disabled: readOnly,
                                                                        onChange: (e) => onChangeHandeler(e)
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="input-group-append ms-2">
                                                                    <span className="input-group-text px-5">€</span>
                                                                </div>
                                                            </div>
                                                            <div className="invalid-feedback">{errors.gesami_netto && errors.gesami_netto.message}</div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <label>{t('Gesamt Brutto')} *</label>
                                                            <div className="input-group mb-3">
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("gesami_brutto", {
                                                                        required: true,
                                                                        disabled: readOnly
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="input-group-append ms-2">
                                                                    <span className="input-group-text px-5">€</span>
                                                                </div>
                                                            </div>
                                                            <div className="invalid-feedback">{errors.gesami_brutto && errors.gesami_brutto.message}</div>
                                                        </div>
                                                    </>
                                                    }
                                                    {
                                                        show === 1 && <div className="col-md-6">
                                                            <label>{t('Gesamt Netto')} *</label>
                                                            <div className="input-group mb-3">
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("gesami_netto", {
                                                                        required: true,
                                                                        disabled: readOnly,
                                                                        onChange: (e) => onChangeHandeler(e)
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="input-group-append ms-2">
                                                                    <span className="input-group-text px-5">€</span>
                                                                </div>
                                                            </div>
                                                            <div className="invalid-feedback">{errors.gesami_netto && errors.gesami_netto.message}</div>
                                                        </div>
                                                    }

                                                </>
                                            ) : ''}
                                        </div>
                                    </SoftBox>
                                </Card>

                                <Card id="questions" sx={{ overflow: "visible" }} className="mt-3">
                                    <SoftBox p={3}>
                                        <SoftTypography variant="h5">{t('Datenerfassung')}</SoftTypography>
                                    </SoftBox>
                                    <SoftBox component="form" pb={3} px={3}>
                                        <div className="row g-3">
                                            <div className="col-md-6">
                                                <label>{t('Neigung')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("neigung", {
                                                        required: true,
                                                        disabled: readOnly
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.total_plant_production && errors.total_plant_production.message}</div>
                                            </div>

                                            <div className="col-md-6">
                                                <label>{t('Sonnenstunden')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("sonnenstunden", {
                                                        required: true,
                                                        disabled: readOnly,
                                                        onChange: (e) => {
                                                            calCulateAnlagenProduktion();
                                                        }
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.sonnenstunden && errors.sonnenstunden.message}</div>
                                            </div>
                                            <div className="col-md-6">
                                                <label>{t('Anlagenproduktion ges. in Kw/h')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("total_plant_production", {
                                                        required: true,
                                                        disabled: readOnly
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.total_plant_production && errors.total_plant_production.message}</div>
                                            </div>

                                            <div className="col-md-6">
                                                <label>{t('Eigenverbrauch in Kw/h')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("own_consumption", {
                                                        required: true,
                                                        disabled: readOnly,
                                                        onChange: (e) => {
                                                            calCulateAnlagenProduktion();
                                                        }
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.own_consumption && errors.own_consumption.message}</div>
                                            </div>


                                            <div className="col-md-6">
                                                <label>{t('Einspeisung in Kw/h')} *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('')}
                                                    {...register("feed_in", {
                                                        required: true,
                                                        disabled: readOnly
                                                    })}
                                                    required
                                                />
                                                <div className="invalid-feedback">{errors.feed_in && errors.feed_in.message}</div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Garantieerweiterung auf 20 Jahre')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("warranty_extension", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })} required>
                                                        <option value="">--</option>
                                                        <option value="JA">JA</option>
                                                        <option value="NEIN (NUR BEI SENEC)">NEIN (NUR BEI SENEC)</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Zählerkastenumbau notwendig')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("meter_box_conversion", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })} required>
                                                        <option value="">--</option>
                                                        <option value="JA">JA</option>
                                                        <option value="NEIN">NEIN</option>
                                                        <option value="nicht sicher">nicht sicher</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Kabelführung')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("cable_routing", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })} required>
                                                        <option value="">--</option>
                                                        <option value="Leerrohr">Leerrohr</option>
                                                        <option value="Schacht/Kamin">Schacht/Kamin</option>
                                                        <option value="Fassade">Fassade</option>
                                                        <option value="Sonstige">Sonstige</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Ersatzziegel vorhanden')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("spare_bricks", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })} required>
                                                        <option value="">--</option>
                                                        <option value="JA">JA</option>
                                                        <option value="NEIN">NEIN</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Alle möglichen Fördermittel beantragen ')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("apply_for_all_possible_grants", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })} required>
                                                        <option value="">--</option>
                                                        <option value="JA">JA</option>
                                                        <option value="NEIN">NEIN</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Wallbox')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("wallbox_senec", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })} required>
                                                        <option value="">--</option>
                                                        <option value="JA">JA</option>
                                                        <option value="NEIN">NEIN</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Besitzt der Kunde eine Wärmepumpe: ')}</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("have_heat_pump", {
                                                            required: false,
                                                            disabled: readOnly
                                                        })}>
                                                        <option value="">--</option>
                                                        <option value="JA">JA</option>
                                                        <option value="NEIN">NEIN</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>{t('Sondervereinbarung')}</label>
                                                    <textarea className="form-control" rows={4} {...register('remark_financial_advisers', {
                                                        required: false,
                                                        disabled: readOnly
                                                    })}></textarea>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>

                                            <h6>Bemerkung Montage : (Kabelweg, Zählerschrank, Grabungsarbeiten, Zwischenzähler, WR / Batterie Platz)</h6>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Zählerplatz frei:')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("counter_space_free", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })} required>
                                                        <option value="">--</option>
                                                        <option value="JA">JA</option>
                                                        <option value="NEIN">NEIN</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Zähleranzahl')} *</label>
                                                    <input type="text" className="form-control" {...register('number_of_counters', {
                                                        required: true,
                                                        disabled: readOnly
                                                    })} required />
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Zählerzusammenfassung')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("counter_summary", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })} required>
                                                        <option value="">--</option>
                                                        <option value="JA">JA</option>
                                                        <option value="NEIN">NEIN</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('wenn ja, welche Zähler, Zählernummer:')}</label>
                                                    <input type="text" className="form-control" {...register('meter_number', {
                                                        required: false,
                                                        disabled: readOnly
                                                    })} />
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>{t('Welche Zähler werden ausgebaut?, Zählernummer:')} *</label>
                                                    <input type="text" className="form-control" {...register('remove_meter_number', {
                                                        required: true,
                                                        disabled: readOnly
                                                    })} required />
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>



                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>{t('Internetanschluss(wenn nein ist der Kunde verpflichtet einen zu machen):')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("internet_connection", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })} required>
                                                        <option value="">--</option>
                                                        <option value="Yes">{t('Yes')}</option>
                                                        <option value="No">{t('No')}</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Bestandsanlage(wenn ja mit Inbetriebnahmedatum, Anlagengröße und Anlagennummer):')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("existing_system", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })} required>
                                                        <option value="">--</option>
                                                        <option value="Yes">{t('Yes')}</option>
                                                        <option value="No">{t('No')}</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Inb. Datum, kWp und Anlagennr.')}: *</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        {...register('inb_datum_kwp_und_anlagennr', {
                                                            required: true,
                                                            disabled: readOnly
                                                        })}
                                                        required
                                                    />
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Potenzialausgleich vorhanden:')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("equipotential_bonding_available", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })} required>
                                                        <option value="">--</option>
                                                        <option value="JA">JA</option>
                                                        <option value="NEIN">NEIN</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>


                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Laufmeter PV-Module zu Wechselrichter (Berechnung Kabellänge):')} *</label>
                                                    <input type="text" className="form-control" {...register('pv_modules_wr', {
                                                        required: true,
                                                        disabled: readOnly
                                                    })} required />
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Laufmeter Wechselrichter/Speicher zu Zählerschrank:')} *</label>
                                                    <input type="text" className="form-control" {...register('inverter_storage_to_meter_cabinet', {
                                                        required: true,
                                                        disabled: readOnly
                                                    })} required />
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Zählerort(Stockwerk)')}:</label>
                                                    <Select
                                                        placeholder={t('Select')}
                                                        options={[
                                                            { value: 'KG', label: 'KG' },
                                                            { value: 'EG', label: 'EG' },
                                                            { value: 'OG', label: 'OG' },
                                                        ]}
                                                        onChange={(e) => {
                                                            setValue('meter_location_floor', JSON.stringify(e));
                                                            setMeter_location_floor(e);
                                                        }}
                                                        value={meter_location_floor}
                                                        isMulti
                                                        isDisabled={readOnly}
                                                    />
                                                    <div className="invalid-feedback">{errors.house_connection_location && errors.house_connection_location.message}</div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Hausanschluss(HA) ORT')}</label>
                                                    <Select
                                                        placeholder={t('Select')}
                                                        options={[
                                                            { value: 'KG', label: 'KG' },
                                                            { value: 'EG', label: 'EG' },
                                                            { value: 'OG', label: 'OG' },
                                                        ]}
                                                        onChange={(e) => {
                                                            setValue('house_connection_location', JSON.stringify(e));
                                                            setHouse_connection_location(e);
                                                        }}
                                                        isMulti
                                                        value={house_connection_location}
                                                        isDisabled={readOnly}
                                                    />
                                                    <div className="invalid-feedback">{errors.house_connection_location && errors.house_connection_location.message}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Speicher/WR Platz')}</label>
                                                    <Select
                                                        placeholder={t('Select')}
                                                        options={[
                                                            { value: 'KG', label: 'KG' },
                                                            { value: 'EG', label: 'EG' },
                                                            { value: 'OG', label: 'OG' },
                                                        ]}
                                                        onChange={(e) => {
                                                            setValue('storage_wrspace', JSON.stringify(e));
                                                            setStorage_wrspace(e);
                                                        }}
                                                        isMulti
                                                        value={storage_wrspace}
                                                        isDisabled={readOnly}
                                                    />
                                                    <div className="invalid-feedback">{errors.storage_wrspace && errors.storage_wrspace.message}</div>
                                                </div>
                                            </div>




                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Gerüst Notwendig')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("gerust_notwendig", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })} required>
                                                        <option value="">--</option>
                                                        <option value="JA">JA</option>
                                                        <option value="NEIN">NEIN</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Freileitung Isolierung')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("frealeatung_isolierung", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })} required>
                                                        <option value="">--</option>
                                                        <option value="JA">JA</option>
                                                        <option value="NEIN">NEIN</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Grabungsarbeiten')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("grabungsarbeiten", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })} required>
                                                        <option value="">--</option>
                                                        <option value="Yes">{t('Yes')}</option>
                                                        <option value="No">{t('No')}</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Dämmung vorhanden')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("dammung_vorhanden", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })} required>
                                                        <option value="">--</option>
                                                        <option value="Yes">{t('Yes')}</option>
                                                        <option value="No">{t('No')}</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Sturmhacken vorhanden')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("sturmhacken_vorhanden", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })} required>
                                                        <option value="">--</option>
                                                        <option value="Yes">{t('Yes')}</option>
                                                        <option value="No">{t('No')}</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Satelitenschüssel verschieben')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("satelitenschussel_verschieben", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })} required>
                                                        <option value="">--</option>
                                                        <option value="Yes">{t('Yes')}</option>
                                                        <option value="No">{t('No')}</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{t('Abstand Dachsparren')} *</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("abstand_dachsparren", {
                                                            required: true,
                                                            disabled: readOnly
                                                        })} required>
                                                        <option value="">--</option>
                                                        <option value="unter 60 cm">{t('unter 60 cm')}</option>
                                                        <option value="61-90 cm">{t('61-90 cm')}</option>
                                                        <option value="91-120 cm">{t('61-90 cm')}</option>
                                                    </select>
                                                    <div className="invalid-feedback"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </SoftBox>
                                </Card>

                                <div className="row g-3">
                                    <div className="col-12 my-4">
                                        {Cookies.get('permissions').indexOf("project_distribution-update") !== -1 ? (
                                            <>
                                                {!saving && (
                                                    <button type="submit" className="btn btn-primary" id="editdstBtn">
                                                        {t('Save')}
                                                    </button>
                                                )}
                                                {saving && (
                                                    <button type="button" className="btn btn-secondary" disabled>
                                                        {t('wird geladen...')}
                                                    </button>
                                                )}
                                            </>
                                        ) : <></>}

                                        {data?.quations?.id ? (
                                            <>
                                                {Cookies.get('permissions').indexOf("project_distribution-update") !== -1 ? (
                                                    <>
                                                        <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'quation/' + data?.quations?.id} className="btn btn-outline-dark ms-2" >{t('Quoation PDF')}</a>
                                                    </>
                                                ) : <></>}

                                                <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL + 'quation-new/' + data?.quations?.id} className="btn btn-outline-dark ms-2" >{t('New Quoation PDF')}</a>
                                            </>
                                        ) : <></>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-body p-3">
                                    <div className="d-flex">
                                        <div>
                                            <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="ms-3">
                                            <div className="numbers">
                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Quotation Nr.')}</p>
                                                <h5 className="font-weight-bolder mb-0">
                                                    {data?.quations?.id ? data?.quations?.id : '-'}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-body p-3">
                                    <div className="d-flex">
                                        <div>
                                            <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="ms-3">
                                            <div className="numbers">
                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Creator')}</p>
                                                <h5 className="font-weight-bolder mb-0">
                                                    {data?.quations?.user?.name ? data?.quations?.user?.name : '-'}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-header pb-0">
                            <h6>{t('Email')}</h6>
                        </div>
                        <div className="card-body">
                            <EmailForm id={data?.quations?.id} type={'angebot'} pdf={true} />
                        </div>
                    </div>
                    <Timeline refresh={refresh2} />
                </div>
                {renderSuccessSB}
            </div>
        </>
    );
}

export default Distribution;