import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, Navigate, useParams } from "react-router-dom";

import CallCenter from "./create/CallCenter";
import Distribution from "./create/Distribution";
import OrderProcessing from "./create/OrderProcessing";
import Order from "./create/Distribution/Order";
import OrderSign from "./create/Distribution/OrderSign";
import Invoices from "./create/Distribution/Invoices";
import Files from "./create/Files";
import { callFetch } from "../../helpers/callFetch";

import NavMenu from "./Layouts/NavMenu";
import Cookies from 'js-cookie';
import ProjectStatus from "./OrderProccessing/ProjectStatus";
import RoofPlanner from "./RoofPlanner";
import RoofPlannerV2 from "./RoofPlannerV2";

import CorrectionRequest from "./create/CorrectionRequest";
//import FertigmeldungVersenden from "./FertigmeldungVersenden";

function Edit() {
    let params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('callcenter');
    const [project, setProjects] = useState('callcenter');
    const [refresh, setRefresh] = useState(1);

    const activeDataTab = (data) => {
        setActiveTab(data);
    }

    useEffect(() => {
        if(Cookies.get('permissions').indexOf("project_call_center-read") !== -1){
            setActiveTab('callcenter');
        }else if(Cookies.get('permissions').indexOf("project_distribution-read") !== -1){
            setActiveTab('distribution');
        }else if(Cookies.get('permissions').indexOf("order_project-read") !== -1){
            setActiveTab('auftrag');
        }else if(Cookies.get('permissions').indexOf("project_order_processing-read") !== -1){
            setActiveTab('order_processing');
            document.getElementById('order_processing').click();
        }else if(Cookies.get('permissions').indexOf("project_files-read") !== -1){
            setActiveTab('files');
        }else if(Cookies.get('permissions').indexOf("project_notes-read") !== -1){
            setActiveTab('notes');
        }else if(Cookies.get('permissions').indexOf("project_accounting-read") !== -1){
            setActiveTab('accounting');
        }
        
        /*
        callFetch("projects/" + params.id + "/edit", "GET", []).then((res) => {
            if(res.message == 'success'){
                setProjects(res);
            }else{
                console.log('notfound');
                return  navigate('/not-found');
            }
        });
        */
    }, [params.id]);

    return(
        <>
            <div className="container-fluid" style={{paddingLeft: '0px', paddingRight: '0px'}}> 
                <div className="mb-3">
                    <NavMenu activeTab={activeDataTab} currentActiveTab={activeTab} />
                </div>
                
                {activeTab == 'callcenter' ? (
                    <CallCenter refresh={refresh} update={true}/>
                ) : activeTab == 'distribution' ? (
                    <Distribution refresh={refresh} />
                ) : activeTab == 'auftrag' ? (
                    <Order refresh={refresh} />
                ) : activeTab == 'roof-planner' ? (
                    /*<RoofPlanner refresh={refresh} projectid={params.id} />*/
                    <RoofPlannerV2 refresh={refresh} />
                ) : activeTab == 'order_processing' ? (
                    <OrderProcessing refresh={refresh} />
                ): activeTab == 'digitalsign' ? (
                    <OrderSign refresh={refresh} />
                ) : activeTab == 'files' ? (
                    <Files refresh={refresh} projectid={params.id} />
                ) : activeTab == 'accounting' ? (
                    <Invoices refresh={refresh} />
                ) : activeTab == 'notes' ? (
                    <ProjectStatus refresh={refresh} projectid={params.id} />
                ) : activeTab == 'fertigmeldung_versenden' ? (
                    {/*
                    <FertigmeldungVersenden refresh={refresh} />
                    */}
                )   : <></>}
            </div>

            <CorrectionRequest related={activeTab} refresh={refresh} setRefresh={setRefresh}/>
        </>
    )
}

export default Edit;
