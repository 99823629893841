import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';

function EmailForm(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [totalEmails, setTotalEmails] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        unregister,
        formState: { errors },
    } = useForm();

    const onSubmit = (formData) => {
        setSaving(true);

        if(props?.pdf){
            callFetch( props.type == 'angebot' ? "quation/"+props.id+'?pdf_generate'
                        : props.type == 'auftrag' ? "order/"+props.id+'?pdf_generate'
                        : '',
                    "GET", []).then((res) =>{
                        if(res?.message == 'success'){
                            formData.pdf = true;
                            setSaving(true);
                            callFetch("sendemail", "POST", formData, setError).then((res) => {
                                setSaving(false);
                                if (!res.ok) return;
                                setSubmitSuccess(true);
                            });
                        }else{
                            setSaving(false);
                            console.log('Something wrong!')
                        }
                setSubmitSuccess(true);      
            })
        }else{
            formData.pdf = false;
            callFetch("sendemail", "POST", formData, setError).then((res) => {
                setSaving(false);
                if (!res.ok) return;
                setSubmitSuccess(true);
            });
        }
    };

    const addEmail = () => {
        const total = totalEmails+1;
        setTotalEmails(total); 
    }
    const removeEmail = () => {
        const i = totalEmails-1;
        const total = i;
        unregister("email["+i+"]");
        setTotalEmails(total);
    }

    useEffect(() => {
        setValue('type_id', props.id);
        setValue('type', props.type);
    }, [props]);

    return(
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <input type="hidden" {...register("type_id")} />
                <div className="form-group">
                    <label>{t('Angebot/Auftrag')} *</label>
                    <select
                        className="form-control"
                        {...register("type", {
                            required: true,
                        })}
                        required>
                        <option value="">{t('Select')}</option>
                        <option value="angebot">{t('Angebot PDF')}</option>
                        <option value="auftrag">{t('Auftrag PDF')}</option>
                    </select>
                    <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                </div>                

                {[...Array(totalEmails)].map((x, i) =>
                    <div className="form-group" key={i}>
                        <label>{t('Email')} *</label>
                        <div className="input-group mb-3">
                            <input type="email" {...register("email["+i+"]")} className="form-control" placeholder={t('eg.')+' email@mail.com'}/>
                            <div className="input-group-append">
                                {totalEmails == i+1 ? (
                                    <span onClick={()=>{removeEmail()}} style={{cursor: "pointer"}} className="input-group-text border-0">X</span>
                                ) : <></>}
                            </div>
                        </div>
                    </div>
                )}
                
                <div className="form-group mb-3">
                    <a onClick={()=>{addEmail()}}><i className="fa-solid fa-circle-plus"></i> Hinzufügen</a>
                </div>
                
                <div className="row g-3">                     
                    <div className="col-12">
                        {!saving && (
                            <button type="submit" className="btn btn-primary mb-0">
                                {t('Send')}
                            </button>
                        )}
                        {saving && (
                            <button type="submit" className="btn btn-disabled mb-0" disabled>
                                {t('Sending ...')}
                            </button>
                        )}
                    </div>
                </div>
            </form>
        </>
    );
}

export default EmailForm;
