import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import { NumericFormat } from 'react-number-format';
import EmailForm from "./EmailFormInvoice";
import Invoice from "./Invoice";
import SoftAlert from "components/SoftAlert";
import Overview from "pages/project/Overview";
import Timeline from "../Timeline";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Invoices(props) {
    let params = useParams();
    const { t } = useTranslation();  
    const [saving, setSaving] = useState(false);
    const [update, setUpdate] = useState(0);
    const [adding, setAdding] = useState(false);
    const [data, setData] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [loadInv, setLoadInv] = useState(0);
    const [receivedError, setReceivedError] = useState(null);

    useEffect(() => {
        if(!params.id) return;

        callFetch("project/invoice/create/"+params.id, "GET", []).then((res) => {
            if(res?.data?.quations?.order?.id){
                setData(res?.data);
            }else{
                //console.log('Make order first')
                toast(t("Fill Out Order"));
                document.getElementById('auftrag').click();
                return;
            }
                    
        });
    }, []);

    useEffect(() => {
        if(!data.id) return;

        callFetch("get-invoices/" + data?.quations?.order?.id, "GET", []).then((res) => {
            setInvoices(res.invoices);
            setSaving(false);
        });
    }, [data, loadInv, update]);

    const addInvoice = () => {
        setAdding(true);
        callFetch("add-invoice/"+data?.quations?.order?.id, "GET", []).then((res) => {
            if(res.error){
                if(res.error == '100'){
                    setReceivedError(t('Inovice has created for receivable amount'));
                    setAdding(false);
                }else{
                    setReceivedError('No invoice created yet for this order. Save this and try to add another invoice.');
                    setAdding(false);
                }
            }else{
                setLoadInv(loadInv + 1);
                setAdding(false);
            } 
            
        });
    }

    return(
        <div className="row">
            <div className="col-sm-9">
                <Overview project_id={params?.id} title={t("Invoice")} />
                <div className="mb-4 mt-3"> 
                    <div className="">
                        {Array.isArray(invoices) && !invoices.length ? <Invoice projectid={params?.id} quationId={data?.quations?.id} orderId={data?.quations?.order?.id} isUpdate={update} /> : ''} 
                        {invoices && invoices.map((inv) => 
                            <Invoice projectid={params?.id} id={inv.id} quationId={data?.quations?.id} orderId={data?.quations?.order?.id} isUpdate={update}/>
                        )} 
                        <div className="row mt-1"> 
                                {receivedError && <SoftAlert color="error" dismissible> {receivedError} </SoftAlert> }
                                <div className="col-md-12"> 
                                    {!saving && (
                                        <button type="button" className="btn btn-primary" onClick={() => {setUpdate(update + 1); setSaving(true); setLoadInv(loadInv+1) }}>
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )} 
                                    {!adding && (
                                        <button type="button" className="btn btn-primary" onClick={() => addInvoice()} style={{marginLeft: '5px'}}>
                                            {t('Add Invoice')}
                                        </button>
                                    )}
                                    {adding && (
                                        <button type="button" className="btn btn-disabled" disabled style={{marginLeft: '5px'}}>
                                            {t('Creating...')}
                                        </button>
                                    )} 
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-3"> 
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('Email')}</h6>
                        </div>
                        <div className="card-body">
                            <EmailForm id={params.id} pdf={true} invoices={invoices}/>
                        </div>
                    </div> 

                    <Timeline refresh={loadInv}/>
            </div>
        </div>
    )
}

export default Invoices;
