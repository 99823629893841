import React from "react";
import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import IndexTable from "./IndexTable";
import callFetch from "../../../helpers/callFetch";
import Cookies from 'js-cookie';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";
import SoftEditor from "components/SoftEditor";
import SoftDropzone from "components/SoftDropzone";
import { ToastContainer, toast } from 'react-toastify';
import SoftButton from "components/SoftButton";

function Edit(props) {
    const { t } = useTranslation();
    const [items, setItems] = useState([{ id: 0, artikel_title: '', quantity: 0 }]);
    const [refresh, setRefresh] = useState(0);
    const [data, setData] = useState([]);
    const [saving, setSaving] = useState(false);
    const [reclamation, setReclamation] = useState('Allgemeine');
    const [editData, setEditData] = useState([]);
    const [projects, setProjects] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [artikels, setArtikels] = useState([]);
    const [zuweisenAnPerson, setZuweisenAnPerson] = useState(false);
    const [projectId, setProjectId] = useState(0);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const notify = () => toast("Success");
    const user = JSON.parse(Cookies.get('user'));
    const submitBtn = useRef();
    const [attachments, setAttachment] = useState([]);
    const [newDropzone, setNewDropzone] = useState(true);
    const [editForm, setEditForm] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    const handleProject = (event) => {
        setData([])
        if (event.target.value) {
            setProjectId(event.target.value);
        }
    }

    useEffect(() => {
        if (!projectId) return;

        callFetch("project/timelines/" + projectId, "GET", []).then((res) => {
            setData(res.data);
        });
    }, [projectId]);

    useEffect(() => {
        setValue('project_status', editData?.project_status)
    }, [editData, data]);

    const removeAttachment = (id) => {
        const beforeRemove = attachments;
        const afterRemove = beforeRemove.filter(value => {
            return value.id != id;
        });

        setAttachment(afterRemove);
    }

    const handleCheckboxChange = (event) => {
        if (event.target.checked === false) {
            setReclamation('Projektbezogene');
        } else {
            setReclamation('Allgemeine');
        }
    };

    const handleCheckboxChangeProjekt = (event) => {
        if (event.target.checked === false) {
            setReclamation('Allgemeine');
        } else {
            setReclamation('Projektbezogene');
        }
    };


    const handeleAttachment = (newData) => {
        let data = attachments;
        data[attachments.length] = newData;
        setAttachment(data);
    }


    const onSubmit = (formData) => {

        if (!props.id) return;
        setSaving(true);

        formData.attachments = JSON.stringify(attachments);

        callFetch("ticket/" + props.id, "POST", formData, setError).then((res) => {
            setSaving(false);
            setRefresh(refresh + 1);
            props.refresh((refresh+1) * Math.floor(Math.random() * 100));
            document.getElementById('EditTicketModalClose').click();

            /*
           
            
            setAttachment([]);
            setValue('attachments', '');
            if (!res.ok) return;
            setSubmitSuccess(true);
            clearForm();
            notify();
            */
        });
    };

    useEffect(() => {
        
        if(!editData?.id) return;

        for (let [key, value] of Object.entries(editData)) {
            if (key == 'attachments') {
                if (value) {
                    setAttachment(JSON.parse(value));
                }
            } else if (key == 'type') {
                if (value == 'Allgemeine') {
                    setReclamation('Allgemeine');
                } else {
                    setReclamation('Projektbezogene');
                }
            }else {
                setValue(key, (value == null || value == 'null' ? "" : value));
            }

            //setZuweisenAnPerson(e.target.checked);
        }

    }, [editData, projects, projectId]);

    useEffect(() => {
        callFetch("get-artikels-projects-employees", "GET", []).then((res) => {
            setProjects(res.projects);
            setEmployees(res.employees);
        });

        callFetch("ticket/" + props.id + "/edit", "GET", []).then((res) => {

            setZuweisenAnPerson(res?.data?.zuweisen_an_person == 1 ? true : false);
            setProjectId(res?.data?.project_id);
            setEditData(res.data);
            
            /*            
            setValue('title', res?.data?.title);
            if (res?.data?.type == 'Allgemeine') {
                setReclamation('Allgemeine');
            } else {
                setReclamation('Projektbezogene');
            }
            // setProjects(res.projects);
            // setEmployees(res.employees);
            // setArtikels(res.artikels); 
            */
        });
    }, []);

    const dropZoneInitialize = (name = 'dachansicht') => {

        return (
            <SoftDropzone
                key={'dfdf'}
                options={{
                    dictDefaultMessage: t('Drop files here to upload'),
                    //addRemoveLinks: true,
                    acceptedFiles: ".jpeg,.jpg,.png,.pdf",
                    action: process.env.REACT_APP_API_URL + 'attachment',
                    headers: {
                        "Accept": "application/json",
                        "Authorization": "Bearer " + Cookies.get('token')
                    },
                    processing: function () {
                        document.body.classList.add('loading');
                    },
                    success: (file, response) => {
                        document.body.classList.remove('loading');
                        if (response.message == 'success') {
                            handeleAttachment(response.data);
                        }
                    },
                    maxfilesexceeded: function (file) {
                        this.removeAllFiles();
                        this.addFile(file);
                    },
                    error: function (file, response) {
                        document.body.classList.remove('loading');
                    }
                }}
            />
        )
    }

    return (
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <div class="modal fade" id="exampleModalEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <button type="button" id="EditTicketModalClose" data-bs-dismiss="modal" aria-label="Close" className="close btn btn-icon-only btn-rounded btn-outline-dark mb-0 me-3 btn-sm d-flex align-items-center justify-content-center" style={{ fontSize: '12px', position: 'absolute', right: '0px' }}><i class="fa-sharp fa-solid fa-times icon icon-shape icon-sm  text-center d-flex align-items-center justify-content-center"></i></button>

                                <input type="hidden" defaultValue="PUT" {...register("_method")} />

                                <SoftBox p={2} lineHeight={1}>
                                    <SoftTypography variant="h6" fontWeight="medium">
                                        Edit Ticket
                                    </SoftTypography>

                                    <div className="row">
                                        <div className="col-sm-3 mt-3">
                                            <div className="form-check form-switch">
                                                <input  {...register("type")} value="Allgemeine" className="form-check-input" onChange={handleCheckboxChange} checked={reclamation === 'Allgemeine' ? 'checked' : ''} type="checkbox" />
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">Allgemein</SoftTypography>
                                            </div>
                                        </div>

                                        <div className="col-sm-3 mt-3">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" {...register("type")} value="Projektbezogene" onChange={handleCheckboxChangeProjekt} checked={reclamation === 'Projektbezogene' ? 'checked' : ''} type="checkbox" />
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">Projektbezogen</SoftTypography>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="form-group ">
                                                <select
                                                    className="form-control"
                                                    {...register("project_id", {
                                                        required: true,
                                                    })}
                                                    onChange={handleProject}>
                                                    <option value="">{t('Select Projekt')}</option>
                                                    {projects && projects.map(project => (
                                                        <option value={project.id}>{project.id}</option>
                                                    ))}
                                                </select>
                                                <div className="invalid-feedback">{errors.project_id && errors.project_id.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="form-group">
                                                <label>Title</label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Enter title"
                                                    {...register("title", { required: true, })}
                                                />
                                                <div className="invalid-feedback">{errors.title && errors.title.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="form-group">
                                                <label>Status</label>
                                                <select
                                                    className="form-control"
                                                    {...register("project_status", {
                                                        required: true,
                                                    })}
                                                    required>
                                                    <option value="">{t('Project Status')}</option>
                                                    {
                                                        data.project && <option value="Appointment">Appointment</option>
                                                    }
                                                    {data.quations && data.quations.map((quation) => (
                                                        <option value="Quotation">Quotation</option>
                                                    ))}

                                                    {data.orders && data.orders.map((order) => (
                                                        <option value="Order">Order</option>
                                                    ))}

                                                    {data.invoices && data.invoices.map((invoice) => (
                                                        <option value="Invoice">Invoice</option>
                                                    ))}

                                                    {data.assign_orders && data.assign_orders.map((assign_order) => (
                                                        <option value={t(assign_order.dachmontag_elektromontage)}>{t(assign_order.dachmontag_elektromontage)}</option>
                                                    ))}
                                                </select>
                                                <div className="invalid-feedback">{errors.project_status && errors.project_status.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="form-group">
                                                <label>Abteilung</label>
                                                <select
                                                    className="form-control"
                                                    {...register("department")}
                                                >
                                                    <option value={''}>{t('Select')}</option>
                                                    <option value={'Dachmontage'}>Dachmontage</option>
                                                    <option value={'Elektromontage'}>Elektromontage</option>
                                                    <option value={'Einkauf'}>Einkauf</option>
                                                    <option value={'Buchhaltung'}>Buchhaltung</option>
                                                    <option value={'Vertrieb'}>Vertrieb</option>
                                                    <option value={'Fertigmeldung'}>Fertigmeldung</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.department && errors.department.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="form-group">
                                                <label>Prioritat</label>
                                                <select
                                                    className="form-control"
                                                    {...register("priority", {
                                                        required: true,
                                                    })}
                                                    required>
                                                    <option value="">{t('Select')}</option>
                                                    <option value="Urgend">{t('Urgend')}</option>
                                                    <option value="Normal">{t('Normal')}</option>
                                                    <option value="Not Important">{t('Not Important')}</option>
                                                </select>
                                                <div className="invalid-feedback">{errors.priority && errors.priority.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="form-group">
                                                <label>Start Date</label>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    {...register("start_date", { required: true, })}
                                                    defaultValue={startDate}
                                                />
                                                <div className="invalid-feedback">{errors.start_date && errors.start_date.message}</div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 mt-3">
                                            <div className="form-group">
                                                <label>End Date</label>
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    {...register("end_date", { required: true, })}
                                                    defaultValue={endDate}
                                                />
                                                <div className="invalid-feedback">{errors.end_date && errors.end_date.message}</div>
                                            </div>
                                        </div>

                                        {reclamation === 'Allgemeine' ? (
                                            <div className="col-sm-12 mt-3">
                                                <div className="form-group">
                                                    <label>Partner-/ Lieferanten Nr.</label>
                                                    <select
                                                        className="form-control"
                                                        {...register("partner", {
                                                            required: true,
                                                        })}
                                                        required>
                                                        <option value="">{t('Select')}</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                    </select>
                                                    <div className="invalid-feedback">{errors.partner && errors.partner.message}</div>
                                                </div>
                                            </div>
                                        ) : ''}

                                        <div className="col-sm-12 mt-3">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    {...register("zuweisen_an_person", {
                                                        onChange: (e) => {
                                                            setZuweisenAnPerson(e.target.checked);
                                                        }
                                                    })}
                                                    type="checkbox"
                                                    id="zuweisen_an_person"
                                                />
                                                <label class="custom-control-label m-0 mt-2" htmlFor="zuweisen_an_person">Zuweisen an Person</label>
                                            </div>
                                        </div>

                                        {zuweisenAnPerson ? (
                                            <div className="col-sm-12 mt-3">
                                                <div className="form-group">
                                                    <SoftSelect
                                                        options={employees}
                                                        getOptionLabel={(employees) => employees?.user?.name}
                                                        getOptionValue={(employees) => employees?.user?.id}
                                                        isMulti
                                                        defaultValue={editData?.employees ? JSON.parse(editData?.employees) : []}
                                                        onChange={(e) => {
                                                            var employees = [];
                                                            for (let [employee_key, employee] of Object.entries(e)) {
                                                                employees.push(employee?.user?.id)
                                                            }
                                                            setValue('employees', JSON.stringify(e));
                                                            // setValue('employees', employees.toString());
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ) : ''}


                                        <div className="col-sm-12 mt-3">
                                            <div className="form-group">
                                                <label>{t('Description')}</label>
                                                <textarea  {...register("description", { required: true, })} className="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <SoftBox>
                                        <SoftBox
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="flex-end"
                                            height="100%"
                                        >
                                            <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                                <SoftTypography mb={2} component="label" variant="caption" fontWeight="bold">
                                                    Attachments
                                                </SoftTypography>

                                                <div className="row mb-2">
                                                    <div className="col-lg-12 mt-lg-0">
                                                        <div className="emb-4">
                                                            {editForm == false ? (
                                                                <>
                                                                    <SoftBox lineHeight={1}>
                                                                        <SoftBox>
                                                                            <SoftBox
                                                                                display="flex"
                                                                                flexDirection="column"
                                                                                justifyContent="flex-end"
                                                                                height="100%"
                                                                            >
                                                                                {dropZoneInitialize()}
                                                                            </SoftBox>
                                                                        </SoftBox>
                                                                    </SoftBox>
                                                                </>
                                                            ) : <></>}
                                                        </div>
                                                        <br />
                                                    </div>
                                                </div>
                                            </SoftBox>

                                        </SoftBox>
                                    </SoftBox>

                                    <SoftBox p={3} className="order-processing">
                                        <Grid container spacing={3}>
                                            {attachments.map(function (data, i) {
                                                return (
                                                    <Grid item key={i} className="text-center">
                                                        <div>
                                                            <a target="_blank" download href={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + data?.attachment}>
                                                                <img src={process.env.REACT_APP_BACKEND_URL + 'storage/attachments/' + data?.attachment} />
                                                            </a>
                                                        </div>
                                                        <a
                                                            onClick={() => {
                                                                removeAttachment(data?.id);
                                                            }}
                                                        >{t('Remove')}</a>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </SoftBox>

                                    <SoftBox display="flex" justifyContent="flex-end" mt={3}>
                                        <SoftBox p={2} display="flex" justifyContent="flex-end">
                                            <button
                                                type="button"
                                                className="btn btn-danger mx-2"
                                                onClick={() => {
                                                    document.getElementById('EditTicketModalClose').click();
                                                }}
                                            >
                                                {t('Cancel')}
                                            </button>

                                            {!saving && (
                                                <button type="submit" className="btn btn-primary">
                                                    {t('Save')}
                                                </button>
                                            )}
                                            {saving && (
                                                <button type="button" className="btn btn-disabled" disabled>
                                                    {t('Saving ...')}
                                                </button>
                                            )}
                                        </SoftBox>
                                    </SoftBox>
                                </SoftBox>

                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </>
    );
}

export default Edit;
