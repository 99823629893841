import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import SoftBadge from "components/SoftBadge";
import SoftBadgeDot from "components/SoftBadgeDot";
import { NumericFormat } from 'react-number-format';
import SoftTypography from "components/SoftTypography";
import { Grid, Menu } from "@mui/material";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
//import Timeline from './Timeline';

function IndexTableEmployee(props) {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [deleteButton, setDeleteButton] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [searchKey, setSearchKey] = useState("")
    const [openMenu, setOpenMenu] = useState(null);
    const [data, setData] = useState([]);
    const [project_Data, setProjectData] = useState([]);
    const [proID, setProID] = useState(0);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(null);

    const [roles, setRoles] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [departments, setDepartments] = useState([]);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        callFetch("employees/create", "GET", []).then((res) => {
            setValue('employee_identity_number', res.data.id);
            setRoles(res.data.roles);
        });

        callFetch("designations", "GET", []).then((res) => {
            setDesignations(res.data);
        });

        callFetch("departments", "GET", []).then((res) => {
            setDepartments(res.data);
        });
    }, [refresh]);

    useEffect(() => {
        setDeleteButton(false);
        if (getValues('selectedrow')) {
            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                if (selectedrow_value) {
                    setDeleteButton(true);
                }
            }
        }

    }, [refresh2]);

    const verkauferFeatch = () => {
        if (props.update && props.update == true) {
            /*
            callFetch("projects/" + props.projectid + "/edit?date="+getValues('date')+'&time='+getValues('time'), "GET", []).then((res) => {
                setProjectData({
                    employees: res.employees
                })                
            });
            */
        } else {
            callFetch("projects/create?date=" + getValues('date') + '&time=' + getValues('time'), "GET", []).then((res) => {
                setProjectData(res.data);
            });
        }
    }

    const deleteSelectedItem = () => {
        setDeleting(true);
        var selectedValues = [];
        for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
            if (selectedrow_value) {
                selectedValues.push(selectedrow_key);
            }
        }

        let formData = {
            ids: JSON.stringify(selectedValues),
            type: props.type,
        };

        callFetch("import/delete", "POST", formData, setError).then((res) => {
            if (res.message == 'success') {
                setDeleting(false);
                setRefresh(refresh + 1);
                setRefresh2(refresh + 1);
            }
        })
    }

    const onSubmitSave = () => {
        setSaving(true);
        reset({});

        setTimeout(() => {
            var formData = {
                filename: props.filename,
            }
            callFetch("import/" + props.type + "/save", "POST", formData, setError).then((res) => {
                if (res.message == 'success') {
                    setRefresh(refresh + 1);
                    setRefresh2(refresh + 1);
                    document.getElementById('outputModal-button-close').click();
                    setSaving(false);
                    window.location.reload();
                }
            });
        }, 1000)
    }


    const onSubmit = (formData) => {
        onSubmitSave();
    };

    const updateFileData = (formData) => {
        formData.type = props.type;
        setLoading(true);
        callFetch("import/update", "POST", formData, setError).then((res) => {
            if (res.message == 'success') {
                setLoading(false);
                //setRefresh(refresh+1);
                // setRefresh2(refresh+1);
                //document.getElementById('outputModal-button-close').click();
                //window.location.reload();
            }
        });
    }

    const tableHeadings = [
        {
            //name: t('Project Nr.'),
            name: t(''),
            width: '20px',
            cell: row => (
                <>
                    <input
                        {...register("selectedrow[" + row.id + "]", {
                            checked: true / false,
                            onChange: (e) => {
                                setRefresh2(refresh2 + 1);
                            }
                        })}
                        //checked={getValues("selectedrow["+row.id+"]") == row.id ? true : false }
                        type="checkbox" />
                    &nbsp;
                    {/*'# '+row.id*/}
                </>),
            sortable: true,
        },
        {
            name: t('Employee Name'),
            width: '150px',
            cell: row => (<>
                <input
                    className="form-control"
                    className="form-control"
                    {...register("name[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                name: e.target.value
                            });
                            //verkauferFeatch();
                        }
                    })}
                    defaultValue={row.name}
                    required
                    type="text" />
            </>),
        },
        {
            name: t('Anrede'),
            width: '150px',
            cell: row => <select
                className="form-control"
                {...register("gender[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            anrede: e.target.value
                        });
                    }
                })}
                defaultValue={row.gender}
                required>
                <option value="">--</option>
                <option value="Male">{t('Male')}</option>
                <option value="Female">{t('Female')}</option>
                <option value="Other">{t('Other')}</option>
            </select>,
        },
        {
            name: t('Employee Email'),
            //width: '150px',
            cell: row => (<>
                <input
                    className="form-control"
                    className="form-control"
                    {...register("email[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                email: e.target.value
                            });
                            //verkauferFeatch();
                        }
                    })}
                    defaultValue={row.email}
                    required
                    type="email" />
            </>),
        },
        {
            name: t('Mobile'),
            width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("mobile[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            mobile: e.target.value
                        });
                    }
                })}
                defaultValue={row.mobile}
                required
            />,
        },
        {
            name: t('Password'),
            width: '150px',
            cell: row => <input
                type="password"
                className="form-control"
                placeholder={t('')}
                {...register("password[" + row.id + "]", {
                    required: true,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            password: e.target.value
                        });
                    }
                })}
                defaultValue={row.password}
                required
            />,
        },
        {
            name: t('Role'),
            width: '150px',
            cell: row =>
                <select
                    className="form-control"
                    {...register("role[" + row.id + "]", {
                        required: true,
                        onChange: (e) => {
                            updateFileData({
                                id: row.id,
                                role: e.target.value
                            });
                        }
                    })}
                    defaultValue={row.role}
                    required>
                    <option value="">--</option>
                    {roles && roles.map((role) => (
                        <option key={role.id} value={role.id}>{role.name}</option>
                    ))}
                </select>,
        },
        {
            name: t('Department'),
            width: '150px',
            cell: row => <select
                className="form-control"
                {...register("department_id[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            department_id: e.target.value
                        });
                    }
                })}
                defaultValue={row.department_id}
            >
                <option value="">--</option>
                {departments && departments.map((department) => (
                    <option key={department.id} value={department.id}>{department.name}</option>
                ))}
            </select>,
        },
        {
            name: t('Joining Date'),
            width: '150px',
            cell: row => <input
                type="date"
                className="form-control"
                placeholder={t('')}
                {...register("joining_date[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            joining_date: e.target.value
                        });
                    }
                })}
                defaultValue={row.joining_date}
            />,
        },
        {
            name: t('Address'),
            //width: '150px',
            cell: row => <input
                type="text"
                className="form-control"
                placeholder={t('')}
                {...register("address[" + row.id + "]", {
                    required: false,
                    onChange: (e) => {
                        updateFileData({
                            id: row.id,
                            address: e.target.value
                        });
                    }
                })}
                defaultValue={row.address}
            />,
        }
    ];

    useEffect(() => {
        if (props?.filename) {
            verkauferFeatch();
            callFetch("import-data/" + props?.filename + '/' + props?.type + "?page=" + pageNumber, "GET", []).then((res) => {
                setTableData(res.data);
                // console.log(res.data);
            });
        }

    }, [pageNumber, refresh, props.filename]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />


                {/**/}
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>

                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>

                <div className='text-center'>
                    <button className='btn btn-danger mx-1' onClick={() => {
                        document.getElementById('outputModal-button-close').click();
                    }}>{t('Cancel')}</button>

                    {loading == false ? (
                        <>
                            {!saving && (
                                <button type="submit" className="btn btn-primary">
                                    {t(props.type + ' upload')}
                                </button>
                            )}
                            {saving && (
                                <button type="submit" className="btn btn-disabled" disabled>
                                    {t(props.type + ' upload ...')}
                                </button>
                            )}
                        </>
                    ) : (
                        <button type="submit" className="btn btn-disabled" disabled>
                            {t('Loading ...')}
                        </button>
                    )}

                </div>
            </>
        );
    };

    return <>
        <div className='px-3'>
            <div className="row">
                <div className="col-sm-1">
                    <input type="checkbox" className='mt-2' id='select_all' onChange={(e) => {
                        if (e.target.checked) {
                            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                setValue("selectedrow[" + selectedrow_key + "]", true)
                            }
                        } else {
                            for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                setValue("selectedrow[" + selectedrow_key + "]", false)
                            }
                        }
                        setRefresh2(refresh2 + 1);
                    }} />
                    <label htmlFor="select_all">{t('Select All')}</label>
                </div>
                <div className="col-sm-3">
                    {loading == false ? (
                        <input
                            type="date"
                            className='form-control form-control-sm'
                            style={{ maxWidth: '150px' }}
                            onChange={(e) => {
                                for (let [selectedrow_key, selectedrow_value] of Object.entries(getValues('selectedrow'))) {
                                    if (selectedrow_value) {
                                        setValue("date[" + selectedrow_key + "]", e.target.value);
                                        updateFileData({
                                            id: selectedrow_key,
                                            date: e.target.value
                                        });
                                    }
                                }
                            }}
                        />
                    ) : (
                        <input
                            type="date"
                            className='form-control form-control-sm'
                            style={{ maxWidth: '150px' }}
                            disabled
                        />
                    )}

                </div>
                <div className="col-sm-8">
                    {deleteButton ? (
                        <>
                            {deleting ? (
                                <button className='btn btn-danger btn-sm mb-0 ms-3 float-end' disabled>{t('Deleting ...')}</button>

                            ) : (
                                <button className='btn btn-danger btn-sm mb-0 ms-3 float-end' onClick={() => {
                                    deleteSelectedItem();
                                }}>{t('Delete')}</button>
                            )}
                        </>
                    ) : (
                        <button className='btn btn-danger btn-sm mb-0 ms-3 float-end' disabled>{t('Delete')}</button>
                    )}
                </div>
            </div>

        </div>
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <DataTable
                columns={tableHeadings}
                data={tabledata?.data}
                noDataComponent={t('There are no records to display')}
                className='data-table'
                pagination
                highlightOnHover
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationServer
                paginationTotalRows={tabledata?.total}
                onChangePage={handlePageChange}
                paginationComponent={BootyPagination}
            />
        </form>
    </>
}

export default IndexTableEmployee;
