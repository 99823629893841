import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr"; 
import { NumericFormat } from 'react-number-format';
import SoftButton from "components/SoftButton";

function Create() {
    let params = useParams();
    const { t } = useTranslation();  
    const [data, setData] = useState([]);
    const [saving, setSaving] = useState(false);
    const [sending, setSending] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [total, setTotal] = useState(0);
    const [currency, setCurrency] = useState('EUR');
    const [projects, setProjects] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [receivable, setReceivable] = useState(0);
    const [approve, setApprove] = useState(0);
    const [refresh, setRefresh] = useState(0);

    const [salaryCategory, setSalaryCategory] = useState([
        { invoice_id: ' ', description: ' ', date: ' ', amount : ' ' }, 
    ]);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
 
    useEffect(() => {
        flatpickr(".flat-pickr");

        callFetch("payments/create/"+params.id, "GET", []).then((res) => {
            setApprove(res?.data?.project?.ac_approve);
            setInvoices(res.invoices);  
            setProjects(res.projects);
            //console.log(res.data);
            setReceivable(res.data.amount);
            setValue('receivable', res.data.amount); 
            setValue('payment_id', params.id);  
            setValue('project_id', res.data.project_id);  
            setValue('currency', res.data.currency);

            if(res.data.received_payments != null){
                setSalaryCategory(JSON.parse(res.data.received_payments));
            } 
        });
    }, [refresh]);

   
    
    const handleChangeSalaryCategoryInput = (index, event) => {
        const values = [...salaryCategory];
        values[index][event.target.name] = event.target.value; 
        setSalaryCategory(values);
    }
   

    const handleAddField = () => {
        setSalaryCategory([...salaryCategory, {invoice_id: ' ', description: ' ', date: ' ', amount : ' ' }]);
    }

    const handleRemoveField = (index) => {
        const values = [...salaryCategory];
        values.splice(index, 1); 
        setSalaryCategory(values); 
    }

    useEffect(() => {
        var i;
        var total = 0;
        for(i=0; i<salaryCategory.length; i++){
            total = total + Number(salaryCategory[i]['amount']);    
        }
        setTotal(total);
    }, [salaryCategory]);

    const getInvoice = (id) => {
        callFetch("payments/get-invoices/"+id, "GET", []).then((res) => { 
            setInvoices(res.data.invoices);  
            setReceivable(res.data.total_receivable);
            setValue('receivable', res.data.total_receivable); 
        });
    } 

    const sendPaymentEmail = (invoice_id) => {
        setSending(true);
        callFetch("sendemail", "POST", {
            pdf: false,
            type: 'payment',
            type_id: invoice_id,
        }, setError).then((res) => {
            setSending(false);            
            console.log(res);
        });
    }

    const ACApprove = () => {
        callFetch("approve/ac-project", "POST", {
            id: getValues('project_id'),
            ac_approve: (approve ? 0 : 1),
        }, setError).then((res) => {
            setRefresh(refresh+1);
        });
    };


    const onSubmit = (formData) => { 
        setSaving(true);
        formData.received_payments = JSON.stringify(salaryCategory);
        formData.total = total;
        callFetch("payments", "POST", formData, setError).then((res) => {
            setSaving(false); 
            //console.log(res.data);
            if (!res.ok) return; 
            setSubmitSuccess(false);
        });
    };

    return submitSuccess ? <Navigate to={'/finance/payments'} /> :
        <div className="row">
            <div className="col-12">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t('Add Payment')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <div className="row g-3"> 
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>
                                            {t('Payment Nr')} *
                                        </label>
                                        <br />
                                        <input type="text" className="form-control" {...register("payment_id", {
                                            required: true,
                                        })} readOnly/>
                                        <div className="invalid-feedback">{errors.payment_id}</div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>
                                            {t('Project Nr')} *
                                        </label>
                                        <br />
                                        <input type="text" className="form-control" {...register("project_id", {
                                            required: true,
                                        })} readOnly/>
                                        <div className="invalid-feedback">{errors.project_id}</div>
                                    </div>
                                </div>
                                
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>
                                            {t('Currency')} *
                                        </label>
                                        <br />
                                        <select
                                            className="form-control"
                                            {...register("currency", {
                                                required: true,
                                            })}
                                            onChange={(e) => {
                                                setCurrency(e.target.value)
                                            }}
                                            required
                                        >
                                            <option value="EUR">EUR (€)</option>
                                            <option value="USD">USD ($)</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.currency && errors.currency.message}</div>
                                    </div>
                                </div>  
                            </div>
                            
                            {salaryCategory.map((category, index) => 
                                <div className="row g-3" key={index}> 
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>
                                                {t('Invoice Nr')} *
                                            </label>
                                            <br />
                                            <select className="form-control"
                                                value={category.invoice_id}
                                                name="invoice_id"
                                                required onChange = {event => {
                                                    handleChangeSalaryCategoryInput(index, event);
                                                    
                                                    for (let [key, value] of Object.entries(invoices)) {
                                                        if(value.id == event.target.value){
                                                            //console.log(value);
                                                            //console.log(JSON.parse(value?.product_details)?.amount);
                                                            const values = [...salaryCategory];
                                                            values[index]['amount'] = JSON.parse(value?.product_details)?.amount.toFixed(2); 
                                                            setSalaryCategory(values);
                                                        }

                                                    }
                                                }}>
                                                <option value="">--</option>
                                                {invoices && invoices.map((item) => (
                                                    <option value={item.id}>{item.id}</option>
                                                ))}
                                            </select>
                                            <div className="invalid-feedback">{errors.project_id}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>
                                                {t('Description')} *
                                            </label>
                                            <br />
                                            <input type="text" value={category.description}
                                                onChange = {event => handleChangeSalaryCategoryInput(index, event)} className="form-control" name="description"/> 
                                            <div className="invalid-feedback">{errors.description}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>
                                                {t('Payment Date')} *
                                            </label>
                                            <br />
                                            <input type="date" value={category.date}
                                                onChange = {event => handleChangeSalaryCategoryInput(index, event)} className="form-control" name="date"/> 
                                            <div className="invalid-feedback">{errors.date} </div>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>
                                                {t('Amount S')} *
                                            </label>
                                            <br />
                                            <input type="number" step={0.01} value={category.amount}
                                                onChange = {(event) => {
                                                    handleChangeSalaryCategoryInput(index, event);

                                                }} className="form-control" name="amount"/> 
                                            <div className="invalid-feedback">{errors.amount && errors.currency.amount} </div>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="form-group text-center" style={{paddingTop: '35px'}}>
                                            <i className="fa-solid fa-circle-xmark text-danger" style={{width: '20%'}} onClick={() => handleRemoveField(index)} ></i>
                                            {!sending && (
                                                <a className="btn btn-sm btn-primary mb-0" style={{width: '80%'}} onClick={() => sendPaymentEmail(category?.invoice_id)} >{t('Send Email')}</a>
                                            )}
                                            {sending && (
                                                <a className="btn btn-sm btn-primary mb-0" style={{width: '80%'}} >{t('Sending...')}</a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="row g-3">
                                <div className="col-sm-1"><button type="button" className="btn btn-outline-secondary mt-3" onClick={() => handleAddField()}>+{t('Add')}</button></div>
                                <div className="col-sm-9"></div>
                                <div className="col-sm-2"><p><label>{t('Total S')}: </label> <NumericFormat 
                                                        value={total-receivable}
                                                        displayType="text" 
                                                        thousandSeparator={"."} 
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                    /></p></div>
                            </div>

                             
                                
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary mb-0">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled mb-0" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}

                                    <SoftButton
                                        onClick={() => {
                                            ACApprove();
                                        } }
                                        className="ms-2"
                                        variant="outlined"
                                        style={{
                                        backgroundColor: '#48BB78',
                                        textTransform: "capitalize",
                                        }}
                                    >{t(approve ? t('AC Approved') : t('AC Approve'))}</SoftButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>  
            </div>
        </div>
}

export default Create;
