import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import { Grid, Menu } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Timeline from '../../project/Timeline';
import ProjectStatus from '../../project/ProjectStatus';
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";


import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";
import { useForm } from "react-hook-form";
import LoadingCard from "components/LoadingCard";

function ProjectTable(props) {
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    var typingTimer;

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey, setSearchKey] = useState("")
    const [openMenu, setOpenMenu] = useState(null);
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState({
        verkaufer: 0,
        created_at: 'All',
    });
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(null);
    const projectData = (id) => {
        setData([])
        if (id) {
            callFetch("project/timelines/" + id, "GET", []).then((res) => {
                setData(res.data);
            });
        }
    }

    const cancelProject = (cancel, id) => {
        callFetch("project/cencel", "POST", {
            cancel: cancel,
            id: id,
        }, []).then((res) => {
            setRefresh(refresh + 1)
        });
        setRefresh(refresh + 1)
    }


    useEffect(() => {
        if (!props?.filterData?.created_at) return;

        if (props?.filterData?.created_at != filterData?.created_at) {
            var data = filterData;
            data.created_at = props?.filterData?.created_at;
            setFilterData(data);
            setRefresh(refresh + 1)
        }
    }, [props?.filterData?.created_at]);

    const renderMenu = () => (
        data?.project && <Menu
            anchorEl={openMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            keepMounted
        >
            <CloseIcon
                onClick={handleCloseMenu}
                style={{
                    width: "17px",
                    height: "25px",
                    cursor: "pointer",
                    zIndex: "1",
                }}
            />
            <Grid mt={0} item xs={12} lg={12} pl={8}>
                <Timeline datas={data} title="Project Status" />
            </Grid>
        </Menu>
    );

    const tableHeadings = [
        {
            name: t('Project Number'),
            width: '170px',
            selector: row => <NavLink to={'/project-management/project/edit/' + row.id} className="text-primary">{row.id}</NavLink>,
            sortable: true,
        },
        {
            name: t('Customer'),
            selector: row => row?.vorname + ' ' + (row?.name == null ? '' : row?.name),
        },
        {
            name: t('VKB Name'),
            selector: row => <NavLink to={'/project-management/project/edit/' + row.id}>{row?.salse_person?.name}</NavLink>,
        },
        {
            name: t('Erstellt am'),
            width: '130px',
            selector: row => dateFormat(row?.created_at, "dd.mm.yyyy"),
        },
        {
            name: t('Ereignisdatum'),
            width: '130px',
            selector: row => dateFormat(row?.ereignisdatum, "dd.mm.yyyy"),
        },
        {
            name: t('Status'),
            width: '250px',
            cell: row => (
                <>
                    <ProjectStatus projectid={row?.id} status={row?.status ? row?.status : row?.termine_qoute?.status} />
                </>
            ),
        },
        {
            name: t('Storno'),
            selector: row => ((
                <>
                    {row.cancel == 1 ? (
                        <StatusCell icon="replay" color="dark" status={t("Yes")} />
                    ) : ''}

                </>
            )),
        },
        {
            name: t('Bewertele Termine'),
            selector: row => ((
                <>
                    {row?.status || row?.termine_qoute?.status ? (
                        t("Yes")
                    ) : t("No")}
                </>
            )),
        },
        {
            name: t('Bezahlt €'),
            selector: row => ((
                <>
                    {['1-Verkauft', '2-Kontte nicht verkauft werden', '3-Angebot', '4-Überleger'].indexOf(row?.status ? row?.status : row?.termine_qoute?.status) !== -1 ? (
                        <StatusCell icon="done" color="success" status={t("Yes")} />
                    ) : <StatusCell icon="close" color="error" status={t("No")} />}
                </>
            )),
        },
        {
            name: t('Neuar Termin'),
            selector: row => ((
                <>
                    {['5-Kunde war nicht da'].indexOf(row?.status ? row?.status : row?.termine_qoute?.status) !== -1 ? (
                        t("Yes")
                    ) : t("No")}
                </>
            )),
        },
        {
            name: t('Bemerkung'),
            width: '150px',
            selector: row => ((
                <>
                    {row?.status_description && (
                        <Tooltip title={row?.status_description} placement="left" className=''>
                            <SoftTypography variant="p">
                                {row?.status_description}
                            </SoftTypography>
                        </Tooltip>
                    )}
                </>
            )),
        },
    ];

    useEffect(() => {
        setLoading(true);
        callFetch("projects?search=" + searchKey + "&page=" + pageNumber + '&created_at=' + filterData?.created_at, "GET", []).then((res) => {
            setTableData(res.data);
            setLoading(false);
        });
    }, [searchKey, pageNumber, refresh]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />

                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                {renderMenu()}
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>

                        {tabledata?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                            return (
                                <li key={page.label} className={className + ' ' + (parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                    <button
                                        className="page-link"
                                        onClick={page.label != '...' ? handlePageNumber : ''}
                                        value={page.label}
                                        disabled={page.label == '...' ? true : false}
                                    >
                                        {page.label}
                                    </button>
                                </li>
                            );
                        })}

                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <>
        <div className="card mb-4">
            <div className="card-header pb-0">
                <h6>{t('All Projects')}</h6>
            </div>
            <div className="card-body px-0 pt-0 pb-2">
                <DataTable
                    columns={tableHeadings}
                    data={tabledata?.data}
                    noDataComponent={loading ? t('Loading...') : t('There are no records to display')}
                    className='data-table'
                    pagination
                    highlightOnHover
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    paginationServer
                    paginationTotalRows={tabledata?.total}
                    onChangePage={handlePageChange}
                    paginationComponent={BootyPagination}
                    subHeader
                    subHeaderComponent={
                        <input
                            type="text"
                            placeholder={t("Search...")}
                            className=' form-control w-sm-50 w-md-25 w-lg-15'
                            defaultValue={searchKey}
                            /*
                            onChange={(e)=> {
                                setSearchKey(e.target.value);
                            }}
                            */
                            onKeyUp={(e) => {
                                clearTimeout(typingTimer);
                                typingTimer = setTimeout(() => {
                                    setTableData([]);
                                    setSearchKey(e.target.value);
                                }, 1500);
                            }}
                            onKeyDown={(e) => {
                                clearTimeout(typingTimer);
                            }}
                        />
                    }
                />
            </div>
        </div>
    </>
}

export default ProjectTable;
